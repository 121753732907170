import type { FC, ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { experimentalStyled, Theme } from '@material-ui/core/styles';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import DashboardRightDrawer from './DashboardRightDrawer';
import useAuth from 'src/hooks/useAuth';
import { Box, Container, Stack } from '@material-ui/core';
import TabBar from '../tab-bar/TabBar';
import UpdatedFooter from './updatedFooter';
import CustomLiveChat from '../widgets/CustomLiveChat';
import useScrollToTop from '../../utils/useScrollToTop';
import { getPlatformId } from 'src/constants';
import PartnersSlider from '../PartnersSlider';
import { useQuery } from '@apollo/client';
import { ALL_PARTNERS } from '../../graphql/queries';
import { useTranslation } from 'react-i18next';
import PLATFORM_BY_ID from '../../graphql/queries/getplatformById';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MobileMenu from '../MobileMenu/MobileMenu';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const Layout = experimentalStyled('div')(() => ({
  minHeight: '100dvh',
  maxHeight: '100dvh',
  minWidth: '100dvw',
  maxWidth: '100dvw',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
}));

const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  eight: '100%',
  overflow: 'hidden',
  width: '100%',
  flex: 1,
}));

const DashboardLayoutWrapper = experimentalStyled('div')(() => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  flexDirection: 'column',
}));

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  maxHeight: '100dvh',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  WebkitOverflowScrolling: 'touch',
  overflow: 'hidden',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  const { platformData } = useAuth();
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);
  const [openSideBarState, setOpenSideBarState] = useState(false);
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const platformId = getPlatformId();

  const { data: partners } = useQuery(ALL_PARTNERS, {
    variables: {
      filter: {
        ...(platformId && { platform: platformId }),
      },
    },
  });

  const { data: platform } = useQuery(PLATFORM_BY_ID, {
    variables: {
      platformId,
    },
  });

  const [rightDrawerOpen, setRightDrawerOpen] = useState(false);

  useScrollToTop([pathname]);

  useEffect(() => {
    setIsSidebarMobileOpen(false);
    setRightDrawerOpen(false);
  }, [pathname]);

  useEffect(() => {
    if (rightDrawerOpen) setIsSidebarMobileOpen(false);
  }, [rightDrawerOpen]);

  return (
    <Layout id="layout">
      <Box
        sx={{
          width: '100%',
          fontSize: { sm: '12px', xs: '10px' },
          fontWeight: 600,
          textAlign: 'center',
          lineHeight: 2,
          background: process.env.REACT_APP_PRIMARY_COLOR,
          color: 'white',
          fontFamily: 'Inter',
        }}
      >
        {`${platformData?.title || ''}  ${t('versionText')} `}
        <a style={{ color: '#ffff' }} href="https://tournated.featurebase.app" target="_blank" rel="noopener noreferrer">
          {t('here')}
        </a>
        . &nbsp;
        <a style={{ color: '#ffff' }} href="https://tournated.gitbook.io/tournated" target="_blank" rel="noopener noreferrer">
          {t('Documentation')}
        </a>
      </Box>
      <DashboardLayoutRoot>
        {!isTablet && <DashboardSidebar />}
        <DashboardLayoutWrapper>
          <DashboardNavbar
            onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
            onProfileSidebarOpen={(): void => setOpenSideBarState(!openSideBarState)}
            onMobileClose={(): void => setOpenSideBarState(false)}
            setRightDrawerOpen={setRightDrawerOpen}
            openMobile={openSideBarState}
          />
          <DashboardLayoutContainer>
            <DashboardLayoutContent>
              <Box sx={{ position: 'relative', flex: '1', overflow: 'hidden' }}>
                <Stack id="main" sx={{ height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
                  <Box sx={{ flex: 1 }}>
                    <Outlet />
                  </Box>
                  <Container maxWidth={false} sx={{ maxWidth: '1920px' }}>
                    <PartnersSlider
                      partners={partners?.partners}
                      title={platform?.organizationPlatformByFields.platform.title ? `${platform?.organizationPlatformByFields.platform.title}  ${t('Partners')}` : t('partners')}
                    />
                  </Container>
                  <UpdatedFooter />
                </Stack>
                <CustomLiveChat />
                {isTablet && <MobileMenu open={isSidebarMobileOpen} />}
              </Box>
              <div style={{ display: isSidebarMobileOpen ? 'none' : 'block' }} id="aboveTabBarContainer" />
              {isTablet && <TabBar setSidebarOpen={setIsSidebarMobileOpen} sidebarOpen={isSidebarMobileOpen} />}
            </DashboardLayoutContent>
          </DashboardLayoutContainer>
        </DashboardLayoutWrapper>
        <DashboardRightDrawer open={rightDrawerOpen} setOpen={setRightDrawerOpen} />
      </DashboardLayoutRoot>
    </Layout>
  );
};

export default DashboardLayout;
