import { Box, Skeleton, Theme } from '@material-ui/core';
import { ComponentProps } from 'react';
import { SxProps } from '@material-ui/system';

type Props = { skeleton?: boolean; sx?: SxProps<Theme> } & ComponentProps<typeof Box>;

const CustomPaperCard = ({ children, skeleton, sx, ...props }: Props) => {
  return skeleton ? (
    <Skeleton
      variant="rectangular"
      animation="wave"
      sx={{
        borderRadius: '8px',
        height: sx ? ('height' in sx ? sx?.height : 'auto') : 'auto',
        width: sx ? ('width' in sx ? sx?.width : 'auto') : 'auto',
        bgcolor: sx ? ('bgcolor' in sx ? sx?.bgcolor : 'auto') : 'auto',
      }}
    />
  ) : (
    <Box
      sx={{
        position: 'relative',
        border: '1px solid #EDF1F6',
        background: '#FFF',
        overflow: 'hidden',
        borderRadius: '8px',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default CustomPaperCard;
