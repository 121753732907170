import { Box, Divider, makeStyles, Typography } from '@material-ui/core';
import { LoginSocialIcons } from 'src/constants';
import { LoginJWT } from 'src/components/authentication/login';
import useAuth from 'src/hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { Modals } from './AuthModal';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: '15px 20px 25px 20px',
    maxWidth: '461px',
    borderRadius: '12px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1,
    zIndex: 9999,
    border: 0,
    background: '#FFF',
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  paperStyle: {
    width: '100%',
    // height: 379,
    background: '#FFF',
    boxShadow: 'none',
    position: 'relative',
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    // border:"0px 1px 0px 0px solid "
  },
  socialContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
  },
  loginActionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    width: '100%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const LoginContent = ({ setModal }: { handleClose: () => void; setModal: (state: Modals) => void }) => {
  const classes = useStyles();
  const { platform } = useAuth() as any;
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', width: '100%', height: 'fit-content' }}>
      <Box className={classes.loginContainer}>
        <Box className={classes.socialContainer}>
          <Box
            sx={{
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <Box sx={{ display: 'flex', gap: '5px' }}>
              <Typography
                variant="font21"
                sx={{
                  lineHeight: '15.6px',
                  color: 'rgba(10, 37, 64, 0.35)',
                }}
              >
                {t('Don’t have an account?')}
              </Typography>
              <Typography
                variant="font18"
                sx={{
                  textDecoration: 'underline',
                  lineHeight: '15.6px',
                  color: '#0A2540',
                  cursor: 'pointer',
                }}
                onClick={() => setModal('register')}
              >
                {t('Sign up')}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '8px', opacity: 0.6 }}>{LoginSocialIcons?.map((item) => <img src={item?.path} style={{ width: '52px', height: '52px' }} />)}</Box>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Divider>
              <Typography
                variant="font21"
                sx={{
                  color: 'rgba(10, 37, 64, 0.35)',
                  lineHeight: '15.6px',
                }}
              >
                {t('or log in')}
              </Typography>
            </Divider>
          </Box>
        </Box>
        <Box className={classes.loginActionContainer}>
          <Box sx={{ width: '100%' }}>{platform === 'JWT' && <LoginJWT />}</Box>
          <Box sx={{ margin: 'auto' }}>
            <Typography
              variant="font21"
              sx={{
                lineHeight: '15.6px',
                color: '#0A2540',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                setModal('forget');
              }}
            >
              {t('Did you forget your password?')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginContent;
