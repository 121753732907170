import { useEffect } from 'react';
import type { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Container, Divider, Typography, Link, Button } from '@material-ui/core';
import AuthBanner from 'src/components/authentication/AuthBanner';
import { LoginAmplify, LoginAuth0, LoginFirebase, LoginJWT } from 'src/components/authentication/login';
import Logo from 'src/components/Logo';
import useAuth from '../../hooks/useAuth';
import gtm from '../../lib/gtm';
import { makeStyles } from '@material-ui/styles';
import { ReactSVG } from 'react-svg';

const platformIcons = {
  Amplify: '/static/icons/amplify.svg',
  Auth0: '/static/icons/auth0.svg',
  Firebase: '/static/icons/firebase.svg',
  JWT: '/static/icons/jwt.svg',
};

const useStyles = makeStyles(() => ({
  socialLink: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    justifyContent: 'center',
    padding: '0.75rem',
    border: '1px solid #EFEFF0',
    background: '#fff',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  socialIcon: {
    marginRight: '0.5rem',
    marginTop: '0.27rem',
  },
}));

const Socials = [
  { label: 'Sign up with Google', icon: '/images/google.svg' },
  { label: 'Sign up with Apple', icon: '/images/apple.svg' },
  { label: 'Sign up with Twitter', icon: '/images/twitter.svg' },
];

const Login: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { platform } = useAuth() as any;

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Box onClick={() => navigate('/')} sx={{ paddingTop: '20px', paddingLeft: '20px', display: { md: 'none', sm: 'block' } }}>
          {/* <ReactSVG src="/images/logo.svg" className={classes.logo} /> */}
          <img
            src="/images/updated/small.svg"
            // width="209px"
            height="41px"
            style={{ cursor: 'pointer' }}
          />
        </Box>
        {/* <AuthBanner /> */}
        <Container sx={{ py: '70px' }}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              // p: 4,
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                mb: 3,
              }}
            >
              <div>
                <Typography color="textPrimary" gutterBottom variant="h2">
                  Log in
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  Welcome to Tournated Network
                </Typography>
              </div>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              {/* {platform === "Amplify" && <LoginAmplify />}
              {platform === "Auth0" && <LoginAuth0 />}
              {platform === "Firebase" && <LoginFirebase />} */}
              {platform === 'JWT' && <LoginJWT />}
            </Box>
            <Divider sx={{ my: 3 }}>or</Divider>

            <Box>
              {Socials.map((social, index) => (
                <Button key={index} component={RouterLink} to="/404" className={classes.socialLink} disabled>
                  <ReactSVG src={social.icon} className={classes.socialIcon} />
                  <Typography variant="body1" sx={{ mt: '-4px', color: '#1B3861', fontWeight: 500 }}>
                    {social.label}
                  </Typography>
                </Button>
              ))}
            </Box>
            <Link color="textSecondary" component={RouterLink} to="/authentication/register" variant="subtitle1" sx={{ display: 'flex', justifyContent: 'center', gap: 1, mt: 2 }}>
              Dont have account ?{' '}
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#FF5733' }}>
                {' '}
                Sign-up
              </Typography>
            </Link>
          </CardContent>
        </Container>
      </Box>
    </>
  );
};

export default Login;
