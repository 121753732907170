import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, Toolbar, Container, useMediaQuery } from '@material-ui/core';
import { experimentalStyled, Theme } from '@material-ui/core/styles';
import type { AppBarProps } from '@material-ui/core';
import LanguagePopover from './LanguagePopover';
import EventMenu from './event-menu';
import useAuth from 'src/hooks/useAuth';
import SportSelector from './SportSelector';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContext from 'src/contexts/JWTContext';
import WelcomeModal from '../widgets/modals/Welcome/WelcomeModal';
import RecoverModal from '../widgets/modals/Welcome/RecoverModal';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import { CreditCard, MenuOpenOutlined } from '@material-ui/icons';
import AuthModal, { Modals } from 'src/components/CustomComponents/CustomModal/Modals/AuthModal/AuthModal';
import ServerCrashModal from 'src/components/CustomComponents/CustomModal/Modals/ServerCrashModal';
import CustomAvatar from '../CustomComponents/CustomAvatar';

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
  onProfileSidebarOpen?: () => void;
  onMobileClose: () => void;
  openMobile: boolean;
  setRightDrawerOpen;
}

export const NAVBAR_HEIGHT = { lg: 60, sm: 50 };

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: 'white',
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
  }),
  borderBottom: '1px solid #EFEFF0',
  zIndex: 0,
  position: 'inherit',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  width: '100%',
  height: NAVBAR_HEIGHT.lg + 'px',
  [theme.breakpoints.down('md')]: {
    height: NAVBAR_HEIGHT.sm + 'px',
  },
}));

const DashboardNavbar: FC<DashboardNavbarProps> = ({ setRightDrawerOpen, ...props }) => {
  const { onSidebarMobileOpen, onProfileSidebarOpen, onMobileClose, openMobile, ...other } = props;
  const auth = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    loginModalOpen,
    signupModalOpen,
    isServerCrashModalOpen,
    setServerCrashModalOpen,
    forgetModalOpen,
    welcomeModalOpen,
    recoverModalOpen,
    setRecoverModalOpen,
    setWelcomeModalOpen,
    setSignupModalOpen,
    setLoginModalOpen,
    resetModalOpen,
  } = useContext(AuthContext);

  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const modalStates: Record<Modals, boolean> = {
    login: loginModalOpen,
    register: signupModalOpen,
    forget: forgetModalOpen,
    reset: resetModalOpen,
  };

  const isModal = (key: string): key is Modals => {
    return key in modalStates;
  };

  const currentModal = Object.keys(modalStates).find((key): key is Modals => isModal(key) && modalStates[key]) || 'login';

  return (
    <React.Fragment>
      <DashboardNavbarRoot {...other}>
        <Container maxWidth={false} sx={{ maxWidth: '1920px' }}>
          <Toolbar
            sx={{
              minHeight: '10px !important',
              padding: '0 !important',
              alignItems: { lg: 'center', xs: 'center' },
              justifyContent: 'space-between',
              width: '100%',
              gap: '20px',
            }}
          >
            <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', overflow: 'hidden' }}>
              {isTablet && (
                <>
                  <CustomButton size="auto" variant="text" shape="circle" sx={{ justifyContent: 'start' }} onClick={() => navigate('/')}>
                    <img
                      alt="logo"
                      src={auth?.platformData?.lightLogo || process.env.REACT_APP_LOGO_ICON || '/images/updated/small.svg'}
                      // style={{ padding: "0px 0 0 0" }}
                      style={{ width: '22px', height: '22px' }}
                    />
                  </CustomButton>
                  <LanguagePopover />
                </>
              )}
              {!isTablet && <SportSelector />}
            </Box>
            {/* <Box
            sx={{
              width: "100%",
              display: { sm: "flex", xs: "none" },
              justifyContent: "center",
              ml: 1,
            }}
          > */}
            {/* <TextField
              fullWidth
              placeholder={t("search")}
              name="Search"
              variant="outlined"
              size="small"
              sx={{
                flexShrink: 0,
                borderRadius: "6px",
                background: "#FAFAFA",
                maxWidth: "332px",
                width: "100%",

                "& .MuiOutlinedInput-root": {
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "18px",
                  color: "#425466",
                  whiteSpace: "nowrap",
                  height: "29.5px",
                  flexShrink: 0,
                  borderRadius: "6px",
                  border: "1px solid #EDF1F6",
                  "& fieldset": {
                    borderWidth: "0px",
                  },
                  "&:hover fieldset": {
                    borderWidth: "0px",
                  },
                  "&.Mui-focused fieldset": {
                    borderWidth: "0px",
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src="/images/updated/search1.svg"
                      style={{ width: "15px", height: "15px" }}
                    />
                  </InputAdornment>
                ),
              }}
            /> */}
            {/* </Box> */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <EventMenu isManager={auth?.isManager} />
              {auth?.user && (
                <CustomButton
                  variant="secondary"
                  size="small"
                  onClick={() => {
                    auth?.user ? navigate('/get-licence') : auth?.setLoginModalOpen(true);
                  }}
                  sx={{ gap: '4px' }}
                >
                  <CreditCard sx={{ fontSize: '16px' }} /> {t('Buy licence')}
                </CustomButton>
              )}
              {!isTablet && <LanguagePopover />}
              {!auth?.user && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <CustomButton variant="secondary" size="small" onClick={() => setLoginModalOpen(true)}>
                    {t('Log in')}
                  </CustomButton>
                  <CustomButton variant="primary" size="small" onClick={() => setSignupModalOpen(true)}>
                    {t('Sign up')}
                  </CustomButton>
                </Box>
              )}
              {isTablet && auth.user && (
                <CustomButton
                  size="auto"
                  variant="text"
                  sx={{ maxWidth: 'fit-content', maxHeight: 'fit-content', minWidth: 'fit-content', minHeight: 'fit-content', padding: 0, gap: '4px' }}
                  onClick={() => setRightDrawerOpen(true)}
                >
                  <CustomAvatar size={28} src={auth.user?.avatar} seed={auth.user?.name + ' ' + auth.user?.surname} />
                  <MenuOpenOutlined />
                </CustomButton>
              )}
            </Box>
          </Toolbar>
        </Container>
      </DashboardNavbarRoot>
      <AuthModal modal={currentModal} openOverride={loginModalOpen || signupModalOpen || forgetModalOpen || resetModalOpen} />
      <WelcomeModal open={welcomeModalOpen} setOpen={setWelcomeModalOpen} />
      <RecoverModal open={recoverModalOpen} setOpen={setRecoverModalOpen} />
      <ServerCrashModal open={isServerCrashModalOpen} onClose={() => setServerCrashModalOpen(false)} />
    </React.Fragment>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
  onProfileSidebarOpen: PropTypes.func,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardNavbar;
