import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip } from '@material-ui/core';
import CustomButton, { getButtonStyles } from 'src/components/CustomComponents/CustomButton';
import ChevronDown from '../../icons/ChevronDown';

type Props = {
  sports: any;
  onClick?: (event?) => void;
};

const SelectedSports = forwardRef<HTMLDivElement, Props>(({ sports, onClick }, ref) => {
  const { t } = useTranslation();
  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        alignItems: 'center',
        flex: 1,
      }}
      onClick={onClick}
    >
      <CustomButton shape="circle" size="small" sx={{ zIndex: sports?.length + 1 ?? 1, minHeight: '24px', minWidth: '24px', maxHeight: '24px', maxWidth: '24px' }} variant="outline">
        <ChevronDown sx={{ fontSize: 18 }} />
      </CustomButton>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          marginLeft: { sm: '-8px', xs: '-14px' },
        }}
      >
        {sports?.map((item, i) => (
          <Tooltip title={t(item.title)} key={item.title}>
            <Box
              sx={{
                '&:not(:first-child)': {
                  marginLeft: { sm: '-8px', xs: '-14px' },
                },
                ...getButtonStyles('circle', 'medium', 'outline'),
                minHeight: '24px',
                minWidth: '24px',
                maxHeight: '24px',
                maxWidth: '24px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'white',
                zIndex: sports?.length - i,
                '&:hover > *': {
                  backgroundColor: process.env.REACT_APP_PRIMARY_COLOR,
                },
              }}
            >
              <Box
                sx={{
                  minHeight: '16px',
                  minWidth: '16px',
                  backgroundColor: '#0A2540',
                  WebkitMask: `url(${item?.icon || '/images/updated/volley.svg'}) no-repeat center`,
                  mask: `url(${item?.icon || '/images/updated/volley.svg'}) no-repeat center`,
                  maskSize: '16px 16px',
                  transition: 'all 0.3s',
                  cursor: 'pointer',
                }}
              />
            </Box>
          </Tooltip>
        ))}
      </Box>
    </Box>
  );
});

export default SelectedSports;
