import { gql } from '@apollo/client';
const ALL_PLATFORMS = gql`
  query {
    platforms(filter: {}) {
      platforms {
        id
        title
      }
    }
  }
`;
export default ALL_PLATFORMS;
