import { gql } from "@apollo/client";
const MATCHESDATES = gql`
  query ($filter: ListGroupMatchInput) {
    groupMatches(filter: $filter) {
      id
      date
    }
  }
`;

export default MATCHESDATES;
