import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import App from './App';
import { AuthProvider } from './contexts/JWTContext';
import { SettingsProvider } from './contexts/SettingsContext';
import store from './store';
import i18n from './i18n';
import { ApolloProvider } from '@apollo/client';
import client from './utils/apolloClient';
import { createRoot } from 'react-dom/client';
import { RegisterProvider } from './contexts/RegisterContext';
import { TournamentRegistrationProvider } from './contexts/TournamentRegistrationContext';

const container = document.getElementById('root');

const root = createRoot(container);
root.render(
  <StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <BrowserRouter>
                <ApolloProvider client={client}>
                  <AuthProvider>
                    <RegisterProvider>
                      <TournamentRegistrationProvider>
                        <App />
                      </TournamentRegistrationProvider>
                    </RegisterProvider>
                  </AuthProvider>
                </ApolloProvider>
              </BrowserRouter>
            </SettingsProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>
);
