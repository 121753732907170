import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Stack, Typography, useMediaQuery } from '@material-ui/core';
import LanguagePopover from './LanguagePopover';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/hooks/useAuth';
import CustomButton from 'src/components/CustomComponents/CustomButton';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    border: '1px solid #EFEFF0',
    borderBottom: 'none',
    marginTop: '40px',
    borderRight: 'none',
    borderLeft: 'none',
    minHeight: '450px',
    background: '#FFF',
  },
  footerContainer: {
    maxWidth: '1920px',
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBlock: '32px',
    paddingInline: '24px',
    gap: '80px',
    [theme.breakpoints.down('sm')]: {
      gap: '40px',
    },
  },
  subContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '20px',
  },
  footerSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '18px',
    [theme.breakpoints.down('sm')]: {
      gap: '25px',
    },
  },
  links: {
    display: 'flex',
    maxWidth: '260px',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
    [theme.breakpoints.down('sm')]: {
      gap: '10px',
    },
  },
  title: {
    color: '#425466',
    fontStyle: 'normal',
    lineHeight: 'normal',
    textTransform: 'uppercase',
  },
  linkText: {
    color: '#0A2540',
    fontStyle: 'normal',
    lineHeight: 'normal',
    cursor: 'pointer',
  },
}));

const UpdatedFooter: React.FC<any> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useAuth();
  const handleEmail = (email) => {
    // Change this to the desired email address
    window.location.href = `mailto:${encodeURIComponent(email)}`;
  };
  const handlePhone = (phone) => {
    window.location.href = `tel:${encodeURIComponent(phone)}`;
  };

  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  function handleNavigation(subItem, title) {
    if (title == 'Sports') {
      window?.open(subItem?.link);
    } else if (title == 'Resources' || title == 'Packages') {
      if (subItem?.title == 'Feature') {
        window?.open(subItem?.link, '_self');
      } else if (subItem?.title == 'Create account') {
        auth?.setSignupModalOpen(true);
      } else if (subItem?.title == 'Login') {
        auth?.setLoginModalOpen(true);
      } else {
        navigate(subItem?.link);
      }
    } else {
      if (subItem?.link == 'email' || subItem?.title == 'Contact us') {
        handleEmail(subItem?.title);
      } else if (subItem?.link == 'phone') {
        handlePhone(subItem?.title);
      } else {
        window.open(subItem?.link, '_self');
      }
    }
  }
  const Footer = [
    {
      heading: 'About',

      section: [
        { title: 'Landing', link: 'https://tournated.com/en' },

        // { title: 'tennis', link: 'https://tennisvalley.co/' },
        // { title: 'beachTenis', link: 'https://beachtennis.io/' },
        // { title: 'Beach Volley', link: 'https://beachvolley.io/' },
        // { title: 'Sport Fishing', link: 'https://sportfishing.live/' },
        // { title: 'Padel', link: 'https://padelclub.io/' },
        // { title: 'Pickleball', link: 'https://padelclub.io/' },
      ],
    },
    {
      heading: 'LEGAL',
      section: [
        { title: 'Terms & Conditions', link: 'https://ik.imagekit.io/zzzcpg8lqxv/2.%20Terms%20and%20Conditions%20TC%20(3)_RmUitetWd.docx' },
        { title: 'Privacy policy', link: 'https://ik.imagekit.io/zzzcpg8lqxv/3.%20Privacy%20Policy%20(2)_R1fhpLupf.docx' },
        { title: 'Cancellation policy', link: 'https://ik.imagekit.io/zzzcpg8lqxv/4.%20Refund%20and%20Cancellation%20(2)_bRKAR5TkC.docx' },
        { title: 'GDPR', link: 'https://ik.imagekit.io/zzzcpg8lqxv/6.%20DPA%20(3)_Wl2VLYsml.docx' },
      ],
    },
    {
      heading: 'Packages',
      section: [
        { title: 'Tournated Manager', link: '/pricing?plan=manager' },
        { title: 'Tournated Custom', link: '/pricing?plan=custom' },
      ],
    },
    {
      heading: 'Customer support',
      section: [
        { title: 'FAQ', link: 'https://tournated.gitbook.io/tournated' },
        {
          title: 'Documentation',
          link: 'https://tournated.gitbook.io/tournated',
        },
        // {
        //   title: 'Video tutorials',
        //   link: 'https://tournated.gitbook.io/tournated',
        // },
        { title: 'Submit ticket', link: 'https://discord.gg/y9KDc48U' },
        { title: 'Contact us', link: 'hello@tournated.com' },
      ],
    },
    {
      heading: 'Our contacts',
      section: [
        { title: auth?.platformData?.companyPhone, link: 'phone' },
        { title: auth?.platformData?.companyEmail, link: 'email' },
      ],
    },
  ];
  const currentYear = new Date()?.getFullYear();
  return (
    <footer className={classes.footer}>
      <Box className={classes.footerContainer}>
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'flex-start',
              gap: '40px',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            {Footer.map((item, i) => (
              <Box key={i} className={classes.footerSection}>
                <Typography className={classes.title} variant="heading12">
                  {t(item.heading)}
                </Typography>
                <Box className={classes.links}>
                  {item.section.map(
                    (subItem: any, i2) =>
                      subItem.title && (
                        <Typography key={subItem + i2} className={classes.linkText} variant="heading18" onClick={() => handleNavigation(subItem, item.heading)}>
                          {t(subItem.title)}
                        </Typography>
                      )
                  )}
                </Box>
              </Box>
            ))}
            {!isTablet && (
              <CustomButton size="auto" variant="text" sx={{ marginTop: '4px' }} onClick={() => window.open('https://tournated.com')}>
                <Stack gap="4px" alignItems="end">
                  <Typography
                    variant="heading12"
                    sx={{
                      color: '#425466',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                    }}
                  >
                    {t('Powered by')}
                  </Typography>
                  <img alt="tournated logo" src="/images/tournatedLogoBig.svg" style={{ height: '20px' }} />
                </Stack>
              </CustomButton>
            )}
          </Box>
          {isTablet && (
            <CustomButton size="auto" variant="text" sx={{ marginTop: '4px' }} onClick={() => window.open('https://tournated.com')}>
              <Stack gap="4px" alignItems="end">
                <Typography
                  variant="heading12"
                  sx={{
                    color: '#425466',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                  }}
                >
                  {t('Powered by')}
                </Typography>
                <img alt="tournated logo" src="/images/darkLogo.svg" style={{ height: '20px' }} />
              </Stack>
            </CustomButton>
          )}
        </Box>
        {/* </Box> */}
        {/* 2 */}
        <Box className={classes.subContainer}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '24px',
            }}
          >
            <LanguagePopover />
            {auth?.platformData?.companyName && (
              <Typography
                variant="heading12"
                sx={{
                  color: '#425466',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                }}
              >
                {`${auth?.platformData?.companyName} © ${currentYear} ${t(' All Rights Reserved')}`}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: '20px',
              padding: { sm: '0', xs: '10px 0' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                padding: '8.626px 18.439px',
                alignItems: 'center',
                gap: '12.501px',
                borderRadius: '9.376px',
                background: '#0A2540',
              }}
            >
              <img alt="android icon" src="/images/updated/andriod.svg" style={{ width: '24px', height: '24px' }} />
              <Box>
                <Typography
                  sx={{
                    color: '#FFF',
                    fontFamily: 'Inter',
                    fontSize: ' 9.376px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                  }}
                >
                  Get it on
                </Typography>
                <Typography
                  sx={{
                    color: '#FFF',
                    fontFamily: 'Inter',
                    fontSize: '12.626px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                  }}
                >
                  Google Play
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                padding: '8.626px 18.439px',
                alignItems: 'center',
                gap: '12.501px',
                borderRadius: '9.376px',
                background: '#0A2540',
              }}
            >
              <img alt="ios icon" src="/images/updated/ios.svg" style={{ width: '24px', height: '24px' }} />
              <Box>
                <Typography
                  sx={{
                    color: '#FFF',
                    fontFamily: 'Inter',
                    fontSize: ' 9.376px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                  }}
                >
                  Download on the
                </Typography>
                <Typography
                  sx={{
                    color: '#FFF',
                    fontFamily: 'Inter',
                    fontSize: '12.626px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                  }}
                >
                  Apple Store
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </footer>
  );
};

export default UpdatedFooter;
