import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      header: {
        discover: 'Discover',
        enterName: 'Enter Name',
        sportsManager: 'Sports Manager?',
        learnHow: 'Learn how',
        accelerate: 'we can accelerate your organization.',
        powering: 'Powering',
        tournaments: 'tournaments,',
        leagues: 'leagues',
        organizations: 'organizations',
        clubs: 'clubs',
        coaches: 'coaches',
        placeholder: {
          tournaments: 'Enter tournament name',
          leagues: 'Enter league name',
          organizations: 'Enter organization name',
          clubs: 'Enter club name',
          coaches: 'Enter coach name',
        },
      },
      registrationOpen: 'Registration open',
      read_more: 'Read more',
      registrationClosed: 'Registration closed',
      live: 'Live',
      Close: 'Close',
      bannerHeading: 'Start managing your tournaments absolutely for free',
      bannerDescription: 'From entries management to automated generation of groups, draws and order of play, management of results and more',
      startNow: 'Start Now',
      featureTournaments: 'Featured Tournaments',
      tournamentNotFound: 'No featured tournaments found',
      viewAll: 'View all',
      featureLeague: 'Featured Leagues',
      leaguesNotFound: 'No featured leagues found',
      Tournaments: 'Tournaments',
      NoTournamentsFound: 'No tournaments found',
      Rankings: 'Rankings',
      noRankingFound: 'No rankings found',
      news: 'News',
      noNewsFound: 'No news found',
      partners: 'Our Partners',
      Partners: 'Partners',
      noPartnersFound: 'No partners found',
      worldwide: 'Worldwide',
      search: 'Search',
      pricing: 'Pricing',
      loginRegister: 'Login / Register',
      home: 'Home',
      organizations: 'Organizations',
      leagues: 'Leagues',
      teams: 'Teams',
      clubs: 'Clubs',
      coaches: 'Coaches',
      tennis: 'Tennis',
      beachTenis: 'Beach Tennis',
      'Beach Volley': 'Beach Volley',
      'Sport Fishing': 'Sport Fishing',
      Volleyball: 'Volleyball',
      Sports: 'Sports',
      RESOURSES: 'RESOURSES',
      Feature: 'Feature',
      'Create account': 'Create account',
      Login: 'Login',
      Packages: 'Packages',
      'Package one': 'Package one',
      'Package two': 'Package two',
      'Package three': 'Package three',
      'Package Four': 'Package Four',
      'Customer suppor': 'Customer suppor',
      FAQ: 'FAQ',
      Documentation: 'Documentation',
      'Video tutorials': 'Video tutorials',
      'Submit ticket': 'Submit ticket',
      'Contact us': 'Contact us',
      'Our contacts': 'Our contacts',
      Padel: 'Padel',
      Pickleball: 'Pickleball',
      'FEATURED LEAGUES': 'FEATURED LEAGUES',
      View: 'View',
      'No leagues found': 'No leagues found',
      Athletes: 'Athletes',
      'Managed by': 'Managed by',
      'View League': 'View League',
      Type: 'Type',
      National: 'National',
      International: 'International',
      Local: 'Local',
      Period: 'Period',
      League: 'League',
      Country: 'Country',
      Year: 'Year',
      Points: 'Points',
      Position: 'Position',
      Team: 'Team',
      'No teams found': 'No teams found',
      'Team Members': 'Team Members',
      Sport: 'Sport',
      'No clubs found': 'No clubs found',
      Experience: 'Experience',
      Students: 'Students',
      Author: 'Author',
      Category: 'Category',
      'My Entries': 'My Entries',
      'My Leagues': 'My Leagues',
      'My Licences': 'My Licences',
      'My Teams': 'My Teams',
      'Get Licence': 'Get Licence',
      demo: 'demo',
      Settings: 'Settings',
      'Log out': 'Log out',
      'Dark view': 'Dark view',
      Manager: 'Manager',
      Tournament: 'Tournament',
      Cost: 'Cost',
      Duration: 'Duration',
      'Purchase on': 'Purchase on',
      'No license found': 'No license found',
      Additional: 'Additional',
      Status: 'Status',
      Date: 'Date',
      'No entries found': 'No entries found',
      'My Licenses': 'My Licenses',
      'Add new License': 'Add new License',
      'Create Team': 'Create Team',
      Info: 'Info',
      infoDes: 'To take part in certain leagues / tours, a player must have a corresponding active licence.',
      'Select League': 'Select League',
      'Select License': 'Select License',
      'Promo code': 'Promo code',
      'I agree to the': 'I agree to the',
      'Terms and Conditions': 'Terms and Conditions',
      Price: 'Price',
      'Confirm Payment': 'Confirm Payment',
      History: 'History',
      'Tournated Manager': 'Tournated Manager',
      'Tournated Custom': 'Tournated Custom',
      'Customer support': 'CUSTOMER SUPPORT',
      Categories: 'Categories',
      Matches: 'Matches',
      'Organized by': 'Organized by',
      Register: 'Register',
      Entries: 'Entries',
      Location: 'Location',
      'National Tour': 'National Tour',
      '4 mins read': '4 mins read',
      'Age group': 'Age group',
      'Skills level': 'Skills level',
      someDes: 'SIA Vertexo © 2020. All Rights Reserved',
      'Manage in CMS': 'Manage in CMS',
      'Fact Sheet': 'Fact Sheet',
      General: 'General',
      Participants: 'Participants',
      Groups: 'Groups',
      Draws: 'Draws',
      'Live Standings': 'Live Standings',
      'Order of play': 'Order of play',
      Pairings: 'Pairings',
      Standings: 'Standings',
      'Final standings': 'Final standings',
      'List & Results': 'List & Results',
      'Entry Fee': 'Entry Fee',
      'Entry Deadline': 'Entry Deadline',
      'Entry deadline': 'Entry deadline',
      'Withdrawal deadline': 'Withdrawal deadline',
      'Start date': 'Start date',
      'End date': 'End date',
      and: 'and',
      others: 'others',
      'are confirmed': 'are confirmed',
      Description: 'Description',
      'Tournament Poster': 'Tournament Poster',
      'More Info': 'More Info',
      'Additional materials about tournament': 'Additional materials about tournament',
      Regulations: 'Regulations',
      'Tournaments Discord': 'Tournaments Discord',
      discordDes: 'Talk directly with tournament admins, ask questions and talk with other players!',
      'Join Channel': 'Join Channel',
      'Fact sheet': 'Fact sheet',
      'Search hotels and more': 'Search hotels and more...',
      Destination: 'Destination',
      'Select Destination': 'Select Destination',
      'Check in date': 'Check in date',
      'Check out date': 'Check out date',
      'Best players on this tournament': 'Best players on this tournament',
      Gallery: 'Gallery',
      'Recent photos': 'Recent photos',
      'Buy licence': 'Buy licence',
      'Select All': 'Select All',
      "Don't see your sport? Contact us": "Don't see your sport? Contact us",
      'Description is missing': 'Description is missing',
      day: 'day',
      days: 'days',
      Organization: 'Organization',
      Athlete: 'Athlete',
      'Birth year': 'Birth year',
      Joined: 'Joined',
      'Select Sport': 'Select Sport',
      'Select organization': 'Select organization',
      'Select league': 'Select league',
      Overview: 'Overview',
      About: 'About',
      'Win rate': 'Win rate',
      Achievements: 'Achievements',
      'Upcoming Matches': 'Upcoming Matches',
      'Past Matches': 'Past Matches',
      'Upcoming Tournaments': 'Upcoming Tournaments',
      'Purchase history': 'Purchase history',
      'description and detail': 'Improve: Acquire licences / memberships required for participants in specific leagues or tournaments',
      'Purchase datetime': 'Purchase datetime',
      'Payment Status': 'Payment Status',
      Licence: 'Licence',
      Members: 'Members',
      Student: 'Student',
      'Personal page': 'Personal page',
      'My Career': 'My Career',
      'Latest tournaments': 'Latest tournaments',
      'Upcoming tournaments': 'Upcoming tournaments',
      'No members found': 'No members found',
      'No tournaments found': 'No tournaments found',
      'No matches found': 'No matches found',
      'No rankings found': 'No rankings found',
      'No past matches found': 'No past matches found',
      'No upcoming matches found': 'No upcoming matches found',
      'No achievements found': 'No achievements found',
      Documents: 'Documents',
      Contact: 'Contact',
      'No photos added': 'No photos added',
      'Manage league': 'Manage league',
      'No documents added': 'No documents added',
      Email: 'Email',
      Phone: 'Phone',
      Website: 'Website',
      Place: 'Place',
      'Tournament Name': 'Tournament Name',
      'Manage organization': 'Manage organization',
      'Terms & Conditions': 'Terms & Conditions',
      LEGAL: 'LEGAL',
      'Privacy policy': 'Privacy policy',
      'Cancellation policy': 'Cancellation policy',
      GDPR: 'GDPR',
      'All Rights Reserved': 'All Rights Reserved',
      'League [Category]': 'League [Category]',
      'Other tournaments': 'Other tournaments',
      'Points Details': 'Points Details',
      'International rating': 'International rating',
      Day: 'Day',
      //////////// NEW ///////////////////////////////
      Days: 'Days',
      Tennis: 'Tennis',
      'Beach Tennis': 'Beach Tennis',
      'Beach Volleyball': 'Beach Volleyball',
      Chess: 'Chess',
      'In progress': 'In progress',
      Registration: 'Registration',
      'Deadline past': 'Deadline past',
      Completed: 'Completed',
      'No points yet': 'No points yet',
      'is confirmed': 'is confirmed',
      Contacts: 'Contacts',
      Organizer: 'Organizer',
      'Featured athletes': 'Featured athletes',
      'Top-ranked participants of the tournament': 'Top-ranked participants of the tournament',
      NR: 'NR',
      PLAYER: 'PLAYER',
      CITY: 'CITY',
      RATING: 'RATING',
      CLUB: 'CLUB',
      TOTAL: 'TOTAL',
      SEGMENT: 'SEGMENT',
      SEED: 'SEED',
      Search: 'Search',
      'No participants found': 'No participants found',
      Total: 'Total',
      Seed: 'Seed',
      Rating: 'Rating',
      Rank: 'Rank',
      'Category:': 'Category:',
      'No category found': 'No category found',
      'No Rounds found': 'No Rounds found',
      White: 'White',
      Black: 'Black',
      Name: 'Name',
      Pts: 'Pts',
      Score: 'Score',
      'There is no available pairings!': 'There is no available pairings!',
      'Generating PDF...': 'Generating PDF...',
      'PDF Generated Successfully!': 'PDF Generated Successfully!',
      'PDF Downloaded Successfully!': 'PDF Downloaded Successfully!',
      'Downloading...': 'Downloading...',
      'Pairing Standings': 'Pairing Standings',
      Opponents: 'Opponents',
      BHC1: 'BHC1',
      BHC: 'BHC',
      BGP: 'BGP',
      WIN: 'WIN',
      'There is no available entries!': 'There is no available entries!',
      "I'm a coach/parent": "I'm a coach/parent",
      'Step 1': 'Step 1',
      'Select category': 'Select category',
      Request: 'Request',
      'Register & Pay Now': 'Register & Pay Now',
      'Register & Pay On-Site': 'Register & Pay On-Site',
      'Terms And Conditions': 'Terms And Conditions',
      'Latest Winners': 'Latest Winners',
      'No winners found': 'No winners found',
      'No news found': 'No news found',
      'No partners found': 'No partners found',
      'Skills Levels': 'Skills Levels',
      'To get register for the tournament you must have active tour licence.': 'To get register for the tournament you must have active tour licence.',
      'Contact Details': 'Contact Details',
      'Our Partners': 'Our Partners',
      'View all': 'View all',
      Member: 'Member',
      'Team Name': 'Team Name',
      News: 'News',
      'Men Pro': 'Men Pro',
      'Latvia Tennis Union League': 'Latvia Tennis Union League',
      Leagues: 'Leagues',
      'No league found': 'No league found',
      'Home Page': 'Home Page',
      Upcoming: 'Upcoming',
      'This month': 'This month',
      'Last 7 days': 'Last 7 days',
      'Last 30 days': 'Last 30 days',
      Hours: 'Hours',
      Minutes: 'Minutes',
      Teams: 'Teams',
      'No student found': 'No student found',
      'Other news': 'Other news',
      'Don’t have an account?': 'Don’t have an account?',
      'Sign up': 'Sign up',
      'or log in': 'or log in',
      'Did you forget your password?': 'Did you forget your password?',
      'Must be a valid email': 'Must be a valid email',
      'Email is required': 'Email is required',
      'Password is required': 'Password is required',
      Password: 'Password',
      'Remember me': 'Remember me',
      'Log In': 'Log In',
      'Unauthorized , please sign in': 'Unauthorized , please sign in',
      'Reset your password': 'Reset your password',
      'Enter the e-mail address': 'Enter the e-mail address',
      'Enter your e-mail address with which you are registered': 'Enter your e-mail address with which you are registered',
      'Please check your email for reset password link!': 'Please check your email for reset password link!',
      'Send instructions': 'Send instructions',
      'My Licenes': 'My Licenes',
      'My leagues': 'My leagues',
      'My tournaments': 'My tournaments',
      'My matches': 'My matches',
      'My entries': 'My entries',
      'My rankings': 'My rankings',
      Stats: 'Stats',
      'Leagues took part in': 'Leagues took part in',
      'Active licences': 'Active licences',
      Titles: 'Titles',
      'Matches played': 'Matches played',
      'Matches lost': 'Matches lost',
      'Games/points played': 'Games/points played',
      'All sports': 'All sports',
      'All organizations': 'All organizations',
      'All leagues': 'All leagues',
      Registered: 'Registered',
      Withdraw: 'Withdraw',
      'Withdraw deadline passed': 'Withdraw deadline passed',
      'Request Withdrawn': 'Request Withdrawn',
      'Something went wrong!': 'Something went wrong!',
      Withdrawal: 'Withdrawal',
      'Entry to withdraw': 'Entry to withdraw',
      'Withdrawal Reason': 'Withdrawal Reason',
      'Write your reason here': 'Write your reason here',
      Confirm: 'Confirm',
      'Please specify reason': 'Please specify reason',
      'AUDI - The Beach Tennis Championship': 'AUDI - The Beach Tennis Championship',
      'Get licence': 'Get licence',
      'Additional questions': 'Additional questions',
      'Select duration': 'Select duration',
      Career: 'Career',
      'Third Party Licenes': 'Third Party Licenes',
      'User updated successfully': 'User updated successfully',
      'User Account': 'User Account',
      'We recommend an image that is 256 x 256 pixels': 'We recommend an image that is 256 x 256 pixels',
      'Enter Email Address': 'Enter Email Address',
      'Enter name': 'Enter name',
      Surname: 'Surname',
      'Enter surname': 'Enter surname',
      'Contact phone number': 'Contact phone number',
      'Enter phone number': 'Enter phone number',
      'Date of Birth': 'Date of Birth',
      'Date of birth': 'Date of birth',
      Gender: 'Gender',
      'Select gender': 'Select gender',
      'Representing country': 'Representing country',
      'Select country': 'Select country',
      City: 'City',
      'Select role(s)': 'Select role(s)',
      Address: 'Address',
      'Enter address': 'Enter address',
      'Parent found': 'Parent found',
      'Parent not found': 'Parent not found',
      'Removed coach': 'Removed coach',
      'Failed to removed coach': 'Failed to removed coach',
      'Coach created': 'Coach created',
      'Failed to create coach': 'Failed to create coach',
      Club: 'Club',
      Save: 'Save',
      'Select club': 'Select club',
      Coach: 'Coach',
      'Select a coach': 'Select a coach',
      Parent: 'Parent',
      'Enter email': 'Enter email',
      Biography: 'Biography',
      'Write a bio': 'Write a bio',
      CHECKBOXES: 'CHECKBOXES',
      'Give access to coach to purchase licences and manage entries on your behalf': 'Give access to coach to purchase licences and manage entries on your behalf',
      'Give access to club manager(-es) to purchase licences and manage entries on your behalf': 'Give access to club manager(-es) to purchase licences and manage entries on your behalf',
      'Give access to parent(-s) to purchase licences and manage entries on your behalf': 'Give access to parent(-s) to purchase licences and manage entries on your behalf',
      Socials: 'Socials',
      'Insert link here': 'Insert link here',
      Facebook: 'Facebook',
      Instagram: 'Instagram',
      Linkedin: 'Linkedin',
      Tiktok: 'Tiktok',
      Youtube: 'Youtube',
      'Enter Id': 'Enter Id',
      'Password should be 8 chars minimum.': 'Password should be 8 chars minimum.',
      'Passwords must match': 'Passwords must match',
      'Password changed successfully': 'Password changed successfully',
      'New Password': 'New Password',
      'Re-Enter': 'Confirm password',
      'Name is Required': 'Name is Required',
      'Surname is Required': 'Surname is Required',
      'Re enter password': 'Re enter password',
      'User is already registered': 'User is already registered',
      'Create an account': 'Create an account',
      'Already have an account?': 'Already have an account?',
      'Log in': 'Log in',
      'or create account': 'or create account',
      "I'm creating an account for a kid": "I'm creating an account for a kid",
      'sign-up description':
        "You are creating an account for a tournament participant. If you are registering a kid, please create a profile for him/her. Coach and parent profiles will be available soon, allowing you to link to the kid's profile.",
      'Email Address': 'Email Address',
      Continue: 'Continue',
      'Pick a sport': 'Pick a sport',
      'Pick a sport that you play': 'Pick a sport that you play',
      'Gender is required': 'Gender is required',
      'Phone is required': 'Phone is required',
      'Citizenship is required': 'Country is required',
      'Dob is required': 'Dob is required',
      'FIDE ID Not found': 'FIDE ID Not found',
      'FIDE ID found': 'FIDE ID found',
      'Tell us more about you': 'Tell us more about you',
      'More information about yourself': 'More information about yourself',
      'Enter FIDE ID': 'Enter FIDE ID',
      'IPIN ID is correct': 'IPIN ID is correct',
      'Your Licenses': 'Your Licenses',
      'Attach your licenses': 'Attach your licenses',
      Skip: 'Skip',
      'User registered successfully': 'User registered successfully',
      'What is your primary role in the world of sports?': 'What is your primary role in the world of sports?',
      'Register & Go to profile': 'Register & Go to profile',
      'PUBLISHED ON': 'PUBLISHED ON',
      'Federation member': 'Federation member',
      'Club owner': 'Club owner',
      'Club manager': 'Club manager',
      Referee: 'Referee',
      'Tournament organizer': 'Tournament organizer',
      'Sport fan': 'Sport fan',
      Other: 'Other',
      PAY: 'PAY',
      '1 Year': '1 Year',
      '2 Years': '2 Years',
      '3 Years': '3 Years',
      '4 Years': '4 Years',
      'Registration time': 'Registration time',
      'In progress / Upcoming': 'In progress / Upcoming',
      'Matches won': 'Matches won',
      'In progress / upcoming entries': 'In progress / upcoming entries',
      'In progress / upcoming tournaments': 'In progress / upcoming tournaments',
      'Past tournaments': 'Past tournaments',
      'Past entries': 'Past entries',
      'Your password must be different to previous passwords': 'Your password must be different to previous passwords',
      'less than a minute': 'less than a minute',
      read: 'read',
      minute: 'minute',
      'Select Licence': 'Select Licence',
      'Start time': 'Start time',
      'Try again': 'Try again',
      maintenanceMessage: 'We are currently updating the platform, which may take a few minutes. Please try again shortly.',
      networkErrorMessage: 'Please check your network connection and try again.',
      errorTitle: 'Oops! Something is wrong!',
      'View List': 'View List',
      Free: 'Free',
      'Primary Sport': 'Primary Sport',
      'Role(s)': 'Role(s)',
      Menu: 'Menu',
      Calendar: 'Calendar',
      'Manage tournament': 'Manage tournament',
      'Partner search': 'Partner search',
      'Partner Search': 'Partner search',
      'Searching...': 'Searching...',
      Apply: 'Apply',
      Delete: 'Delete',
      'Create application': 'Create application',
      'No partner search applications found': 'No partner search applications found',
      'Log in to apply': 'Log in to apply',
      Player: 'Player',
      'Time of registration': 'Time of registration',
      confirmed: 'confirmed',
      declined: 'declined',
      'payment pending': 'payment pending',
      WITHDRAW: 'WITHDRAW',
      'No other tournament found': 'No other tournament found',
      welcomeTextCustom:
        'is powered by Tournated, an all-in-one sports management software that empowers sports organizations to efficiently manage, grow, and monetize their operations. The solution allows organizations to create custom online platform(s), automate and simplify the management of leagues, clubs, tournaments, and other events, boost audience engagement, and process payments effortlessly.',
      welcomeText:
        'the result of five years of R&D and collaboration with over 200 organizations, is an innovative, customizable, user-friendly, and affordable all-in-one solution for sports organizations of any size',
      'Did you have an account in one of ': 'Did you have an account in one of ',
      those: 'those',
      'platforms?': 'platforms?',
      'Recover password': 'Recover password',
      newUser: 'I’m a new user. Register',
      'Continue as a guest': 'Continue as a guest',
      Support: 'Support',
      'Welcome to': 'Welcome to',
      'If you have an account on one of ': 'If you have an account on one of ',
      these: 'these',
      'platforms, log in with your e-mail or create a new profile': 'platforms, log in with your e-mail or create a new profile',
      'Reset password': 'Reset password',
      'Welcome to the': 'Welcome to the',
      platform: 'platform',
      'Powered by': 'Powered by',
      'All Categories': 'All Categories',
      'Tournament Results': 'Tournament Results',
      versionText: 'Beta is live. Please submit your feedback',
      here: 'here',
      'Full view': 'Full view',
      Timeline: 'Timeline',
      Grid: 'Grid',
      "Bird's View": "Bird's View",
      'Third Party Licences': 'Third Party Licences',
      Friend: 'Friend',
      'Step 2': 'Step 2:',
      'Step 3': 'Step 3:',
      'Your Details': 'Your Details',
      'Partner Details': 'Partner Details',
      'Enter partner email': 'Enter partner email',
      'Register & Pay': `Register & Pay`,
      'On-Site': 'On-Site',
      Now: 'Now',
    },
  },
  la: {
    translation: {
      maintenanceMessage: 'Pašlaik mēs atjauninām platformu, kas var aizņemt dažas minūtes. Lūdzu, mēģiniet vēlreiz drīz.',
      networkErrorMessage: 'Lūdzu, pārbaudiet savu tīkla savienojumu un mēģiniet vēlreiz.',
      errorTitle: 'Ups! Kaut kas nav kārtībā',
      header: {
        discover: 'Atrodi',
        enterName: 'Ievadiet',
        sportsManager: 'Sporta menedžeris?',
        learnHow: 'Uzziniet,',
        accelerate: 'kā mēs varam paātrināt jūsu organizācijas izaugsmi.',
        powering: 'Mūsu klienti',
        tournaments: 'turnīrus,',
        leagues: 'līgas',
        organizations: 'organizācijas',
        clubs: 'klubus',
        coaches: 'trenerus',
        placeholder: {
          tournaments: 'Ievadiet turnīra nosaukumu',
          leagues: 'Ievadiet līgas nosaukumu',
          organizations: 'Ievadiet organizācijas nosaukumu',
          clubs: 'Ievadiet kluba nosaukumu',
          coaches: 'Ievadiet trenera vārdu',
        },
      },
      read_more: 'Lasīt vairāk',
      registrationOpen: 'Reģistrācija atvērta',
      registrationClosed: 'Reģistrācija slēgta',
      live: 'Tiešraide',
      Close: 'Aizvert',
      bannerHeading: 'Sāciet pārvaldīt savus turnīrus pilnīgi bez maksas',
      bannerDescription: 'No pieteikumu pārvaldības līdz automātiskai grupu, izložu un spēļu kārtības ģenerēšanai, rezultātu pārvaldībai un daudz kam citam',
      startNow: 'Sākt tagad',
      featureTournaments: 'Īpašie turnīri',
      tournamentNotFound: 'Īpašie turnīri nav atrasti',
      viewAll: 'Skatīt visus',
      featureLeague: 'Izceltās līgas',
      leaguesNotFound: 'Nav atrastas izceltās līgas',
      Tournaments: 'Turnīri',
      NoTournamentsFound: 'Nav atrasti turnīri',
      Rankings: 'Reitingi',
      noRankingFound: 'Nav atrasti reitingi',
      news: 'Ziņas',
      noNewsFound: 'Nav atrastas ziņas',
      partners: 'Mūsu partneri',
      noPartnersFound: 'Nav atrasti partneri',
      worldwide: 'Visā pasaulē',
      search: 'Meklēt',
      pricing: 'Cenas',
      loginRegister: 'Pieteikties / Reģistrēties',
      home: 'Sākums',
      organizations: 'Organizācijas',
      leagues: 'Līgas',
      teams: 'Komandas',
      clubs: 'Klubi',
      coaches: 'Treneri',
      tennis: 'Teniss',
      beachTenis: 'Pludmales teniss',
      'Beach Volley': 'Pludmales volejbols',
      'Sport Fishing': 'Sporta makšķerēšana',
      Volleyball: 'Volejbols',
      Sports: 'Sporti',
      RESOURSES: 'RESURSI',
      Feature: 'Izcelts',
      'Create account': 'Izveidot profilu',
      Login: 'Pieteikties',
      Packages: 'Komplekti',
      'Package one': 'Komplekts viens',
      'Package two': 'Komplekts divi',
      'Package three': 'Komplekts trīs',
      'Package Four': 'Komplekts četri',
      'Customer suppor': 'Klientu atbalsts',
      FAQ: 'BUJ',
      Documentation: 'Dokumentācija',
      'Video tutorials': 'Video pamācības',
      'Submit ticket': 'Iesniegt biļeti',
      'Contact us': 'Sazinieties ar mums',
      'Our contacts': 'Mūsu kontakti',
      Padel: 'Padel',
      Pickleball: 'Pickleball',
      'FEATURED LEAGUES': 'IZCELTĀS LĪGAS',
      View: 'Skatīt',
      'No leagues found': 'Nav atrastas līgas',
      Athletes: 'Sportisti',
      'Managed by': 'Pārvaldīts ar',
      'View League': 'Skatīt līgu',
      Type: 'Tips',
      National: 'Nacionālais',
      International: 'Starptautisks',
      Local: 'Vietājais',
      Period: 'Periods',
      League: 'Līga',
      Country: 'Valsts',
      Year: 'Gads',
      Points: 'Punkti',
      Position: 'Pozīcija',
      Team: 'Komanda',
      'No teams found': 'Nav atrastas komandas',
      'Team Members': 'Komandas dalībnieki',
      Sport: 'Sporta veidi',
      'No clubs found': 'Nav atrasti klubi',
      Experience: 'Pieredze',
      Students: 'Studenti',
      Author: 'Autors',
      Category: 'Kategorija',
      'My Entries': 'Mani pieteikumi',
      'My Leagues': 'Manas līgas',
      'My Licences': 'Manas licences',
      'My Teams': 'Manas komandas',
      'Get Licence': 'Iegūt licenci',
      demo: 'demo',
      Settings: 'Iestatījumi',
      'Log out': 'Izrakstīties',
      'Dark view': 'Tumšais skats',
      Manager: 'Pārvaldnieks',
      Tournament: 'Turnīrs',
      Cost: 'Izmaksas',
      Duration: 'Ilgums',
      'Purchase on': 'Nopirkts',
      'No license found': 'Nav atrasta iegādāta licence',
      Additional: 'Papildus',
      Status: 'Statuss',
      Date: 'Datums',
      'No entries found': 'Nav atrasti pieteikumi',
      'My Licenses': 'Manas licences',
      'Add new License': 'Pievienot jaunu licenci',
      'Create Team': 'Izveidot komandu',
      Info: 'Informācija',
      infoDes: 'Lai piedalītos noteiktās līgās / tūrēs, spēlētājam ir jābūt atbilstošai aktīvai licencei.',
      'Select League': 'Izvēlieties līgu',
      'Select License': 'Izvēlieties licenci',
      'Promo code': 'Promo kampaņas kods',
      'I agree to the': 'Es piekrītu',
      'Terms and Conditions': 'Noteikumi un nosacījumi',
      Price: 'Cena',
      'Confirm Payment': 'Apstiprināt maksājumu',
      History: 'Vēsture',
      'Tournated Manager': 'Turnīru pārvaldnieks',
      'Tournated Custom': 'Turnīru pielāgošana',
      'Customer support': 'Klientu atbalsts',
      Categories: 'Kategorijas',
      Matches: 'Spēles',
      'Organized by': 'Organizē',
      Register: 'Reģistrēties',
      Entries: 'Pieteikumi',
      Location: 'Norises vieta',
      'National Tour': 'Nacionālā tūre',
      '4 mins read': '4 minūtes lasīšanai',
      'Age group': 'Vecuma grupa',
      'Skills level': 'Prasmju līmenis',
      someDes: 'SIA Vertexo © 2020. Visas tiesības aizsargātas',
      'Manage in CMS': 'Pārvaldīt CMS',
      'Fact Sheet': 'Nolikums',
      General: 'Vispārīgā informācija',
      Participants: 'Dalībnieki',
      Groups: 'Grupas',
      Draws: 'Tabulas',
      'Live Standings': 'Tiešraides rezultāti',
      'Order of play': 'Spēļu grafiks',
      Pairings: 'Pāri',
      Standings: 'Rezultāti',
      'Final standings': 'Sacensību rezultāti',
      'List & Results': 'Spēļu saraksts un rezultāti',
      'Entry Fee': 'Dalības maksa',
      'Entry Deadline': 'Pieteikšanās termiņš',
      'Withdrawal deadline': 'Atteikšanās termiņš',
      'Start date': 'Turnīra sākuma datums',
      'End date': 'Turnīra noslēguma datums',
      and: 'un',
      others: 'citi',
      'are confirmed': 'ir apstiprināti',
      Description: 'Apraksts',
      'Tournament Poster': 'Turnīra plakāts',
      'More Info': 'Vairāk informācijas',
      'Additional materials about tournament': 'Papildu materiāli par turnīru',
      Regulations: 'Noteikumi',
      'Tournaments Discord': 'Turnīru Discord',
      discordDes: 'Runājiet tieši ar turnīru organizētājiem, uzdodiet jautājumus un runājiet ar citiem spēlētājiem!',
      'Join Channel': 'Pievienoties kanālam',
      'Fact sheet': 'Nolikums',
      'Search hotels and more': 'Meklējiet viesnīcas un daudz ko citu...',
      Destination: 'Galamērķis',
      'Select Destination': 'Izvēlieties galamērķi',
      'Check in date': 'Reģistrēšanās datums',
      'Check out date': 'Izbraukšanas datums',
      'Best players on this tournament': 'Labākie šī turnīra spēlētāji',
      Gallery: 'Galerija',
      'Recent photos': 'Nesenie fotoattēli',
      'Buy licence': 'Nopirkt licenci',
      'Select All': 'Izvēlieties visu',
      "Don't see your sport? Contact us": 'Neredziet savu sportu? Sazinieties ar mums',
      'Description is missing': 'Trūkst apraksts',
      day: 'diena',
      days: 'dienas',
      Organization: 'Organizācija',
      Athlete: 'Sportists',
      'Birth year': 'Dzimšanas gads',
      Joined: 'Pievienojās',
      'Select Sport': 'Izvēlieties sportu',
      'Select organization': 'Izvēlieties organizāciju',
      'Select league': 'Izvēlieties līgu',
      Overview: 'Pārskats',
      About: 'Par',
      'Win rate': 'Uzvaru procents',
      Achievements: 'Sasniegumi',
      'Upcoming Matches': 'Gaidāmās spēles',
      'Past Matches': 'Iepriekšējās spēles',
      'Upcoming Tournaments': 'Gaidāmie turnīri',
      'Purchase history': 'Pirkumu vēsture',
      'description and detail': 'Uzlabot: Iegādājieties licences / apmaksājiet dalības maksas, kas nepieciešamas dalībniekiem noteiktās līgās vai turnīros',
      'Purchase datetime': 'Pirkuma datums un laiks',
      'Payment Status': 'Maksājuma statuss',
      Licence: 'Licence',
      Members: 'Biedri',
      Student: 'Students',
      'Personal page': 'Personīgā lapa',
      'My Career': 'Mana karjera',
      'Latest tournaments': 'Jaunākie turnīri',
      'Upcoming tournaments': 'Gaidāmie turnīri',
      'No members found': 'Nav atrasti biedri',
      'No tournaments found': 'Nav atrasti turnīri',
      'No matches found': 'Nav atrastas spēles',
      'No rankings found': 'Nav atrasti reitingi',
      'No past matches found': 'Nav atrastas iepriekšējās spēles',
      'No upcoming matches found': 'Nav atrastas gaidāmās spēles',
      'No achievements found': 'Nav atrasti sasniegumi',
      Documents: 'Dokumenti',
      Contact: 'Kontaktinformācija',
      'No photos added': 'Nav pievienoti fotoattēli',
      'Manage league': 'Pārvaldīt līgu',
      'No documents added': 'Nav pievienoti dokumenti',
      Email: 'E-pasts',
      Phone: 'Tālrunis',
      Website: 'Tīmekļa vietne',
      Place: 'Vieta',
      'Tournament Name': 'Turnīra nosaukums',
      'Manage organization': 'Pārvaldīt organizāciju',
      'Terms & Conditions': 'Noteikumi un nosacījumi',
      LEGAL: 'JURIDISKS',
      'Privacy policy': 'Privātuma politika',
      'Cancellation policy': 'Atcelšanas politika',
      GDPR: 'GDPR',
      'All Rights Reserved': 'Visas tiesības aizsargātas',
      'League [Category]': 'Līga [Kategorija]',
      'Other tournaments': 'Citi turnīri',
      'Points Details': 'Punktu detaļas',
      'International rating': 'Starptautiskais reitings',
      Day: 'Diena',
      Days: 'Dienas',
      Tennis: 'Teniss',
      'Beach Tennis': 'Pludmales teniss',
      'Beach Volleyball': 'Pludmales volejbols',
      Chess: 'Šahs',
      'In progress': 'Notiek',
      Registration: 'Reģistrācija',
      'Deadline past': 'Pieteikšanās termiņš pagājis',
      Completed: 'Noslēdzies',
      'No points yet': 'Punktu vēl nav',
      'is confirmed': 'ir apstiprināts',
      Contacts: 'Kontakti',
      Organizer: 'Organizators',
      'Featured athletes': 'Izceltie sportisti',
      'Top-ranked participants of the tournament': 'Reitingā augstāk esošie turnīra dalībnieki',
      NR: 'NR',
      PLAYER: 'SPĒLĒTĀJS',
      CITY: 'PILSĒTA',
      RATING: 'REITINGS',
      CLUB: 'KLUBS',
      TOTAL: 'KOPĀ',
      SEGMENT: 'SEGMENTS',
      SEED: 'Izliktais',
      Search: 'Meklēt',
      'No participants found': 'Nav atrasti dalībnieki',
      Total: 'Kopā',
      Seed: 'Sēkla',
      Rating: 'Reitings',
      Rank: 'Reitings',
      'Category:': 'Kategorija:',
      'No category found': 'Nav atrasta kategorija',
      'No Rounds found': 'Nav atrastas izspēles kārtas',
      White: 'Balts',
      Black: 'Melns',
      Name: 'Vārds',
      Pts: 'Punkti',
      Score: 'Rezultāts',
      'There is no available pairings!': 'Nav pieejami pāri!',
      'Generating PDF...': 'PDF dokuments tiek ģenerēts...',
      'PDF Generated Successfully!': 'PDF dokuments veiksmīgi ģenerēts!',
      'PDF Downloaded Successfully!': 'PDF dokuments veiksmīgi lejupielādēts!',
      'Downloading...': 'Lejupielādē...',
      'Pairing Standings': 'Pāru rezultāti',
      Opponents: 'Pretinieki',
      BHC1: 'BHC1',
      BHC: 'BHC',
      BGP: 'BGP',
      WIN: 'UZVARA',
      'There is no available entries!': 'Nav pieejami pieteikumi!',
      "I'm a coach/parent": 'Es esmu treneris/vecāks',
      'Step 1': '1. solis',
      'Select category': 'Izvēlieties kategoriju',
      Request: 'Pieprasīt',
      'Register & Pay Now': 'Reģistrēties un maksāt tagad',
      'Register & Pay On-Site': 'Reģistrēties un maksāt uz vietas',
      'Terms And Conditions': 'Noteikumi un nosacījumi',
      'Latest Winners': 'Pēdējie uzvarētāji',
      'No winners found': 'Nav atrasti uzvarētāji',
      'No news found': 'Nav atrastas ziņas',
      'No partners found': 'Nav atrasti partneri',
      'Skills Levels': 'Prasmju līmeņi',
      'To get register for the tournament you must have active tour licence.': 'Lai reģistrētos turnīram, jums jābūt aktīvai licencei.',
      'Contact Details': 'Kontaktinformācija',
      'Our Partners': 'Mūsu partneri',
      'View all': 'Skatīt visus',
      Member: 'Biedrs',
      'Team Name': 'Komandas nosaukums',
      News: 'Ziņas',
      'Men Pro': 'Vīrieši Pro',
      'Latvia Tennis Union League': 'Latvijas Tenisa Savienības Līga',
      Leagues: 'Līgas',
      'No league found': 'Nav atrasta līga',
      'Home Page': 'Sākumlapa',
      Upcoming: 'Gaidāms',
      'This month': 'Šis mēnesis',
      'Last 7 days': 'Pēdējās 7 dienas',
      'Last 30 days': 'Pēdējās 30 dienas',
      Hours: 'Stundas',
      Minutes: 'Minūtes',
      Teams: 'Komandas',
      'No student found': 'Nav atrasts students',
      'Other news': 'Citas ziņas',
      'Don’t have an account?': 'Nav profila?',
      'Sign up': 'Reģistrēties',
      'or log in': 'vai pieslēgties',
      'Did you forget your password?': 'Vai aizmirsāt paroli?',
      'Must be a valid email': 'Jābūt derīgam e-pastam',
      'Email is required': 'E-pasts ir nepieciešams',
      'Password is required': 'Parole ir nepieciešama',
      Password: 'Parole',
      'Remember me': 'Atcerēties mani',
      'Log In': 'Pieteikties',
      'Unauthorized , please sign in': 'Neautorizēta piekļuve, lūdzu, piesakieties',
      'Reset your password': 'Atjaunot paroli',
      'Enter the e-mail address': 'Ievadiet e-pasta adresi',
      'Enter your e-mail address with which you are registered': 'Ievadiet e-pasta adresi, ar kuru esat reģistrēts',
      'Please check your email for reset password link!': 'Lūdzu pārbaudiet savu e-pastu, lai iegūtu saiti paroles atjaunošanai!',
      'Send instructions': 'Sūtīt instrukcijas',
      'My Licenes': 'Manas licences',
      'My leagues': 'Manas līgas',
      'My tournaments': 'Mani turnīri',
      'My matches': 'Manas spēles',
      'My entries': 'Mani pieteikumi',
      'My rankings': 'Mani reitingi',
      Stats: 'Statistika',
      'Leagues took part in': 'Līgas, kurās ir bijusi dalība',
      'Active licences': 'Aktīvās licences',
      Titles: 'Tituli',
      'Matches played': 'Aizvadītās spēles',
      'Matches lost': 'Zaudētās spēles',
      'Games/points played': 'spēles/iegūtie punkti',
      'All sports': 'Visi sporti',
      'All organizations': 'Visas organizācijas',
      'All leagues': 'Visas līgas',
      Registered: 'Reģistrēts',
      Withdraw: 'Atteicies',
      'Withdraw deadline passed': 'Atteikšanās Pieteikšanās termiņš pagājis',
      'Request Withdrawn': 'Pieprasījums atsaukts',
      'Something went wrong!': 'Kaut kas nogāja greizi!',
      Withdrawal: 'Atteikšanās',
      'Entry to withdraw': 'Atsaukt pieteikumu',
      'Withdrawal Reason': 'Atteikšanās iemesls',
      'Write your reason here': 'atteikšanās iemeslu šeit',
      Confirm: 'Apstiprināt',
      'Please specify reason': 'Lūdzu norādiet iemeslu',
      'AUDI - The Beach Tennis Championship': 'AUDI - Pludmales tenisa čempionāts',
      'Get licence': 'Iegūt licenci',
      'Additional questions': 'Papildu jautājumi',
      'Select duration': 'Izvēlieties ilgumu',
      Career: 'Karjera',
      'Third Party Licenes': 'Ārējās licences',
      'User updated successfully': 'Lietotājs veiksmīgi atjaunināts',
      'User Account': 'Lietotāja konts',
      'We recommend an image that is 256 x 256 pixels': 'Iesakām attēlu, kas ir 256 x 256 pikseļi',
      'Enter Email Address': 'Ievadiet e-pasta adresi',
      'Enter name': 'Ievadiet vārdu',
      Surname: 'Uzvārds',
      'Enter surname': 'Ievadiet uzvārdu',
      'Contact phone number': 'Kontakta tālruņa numurs',
      'Enter phone number': 'Ievadiet tālruņa numuru',
      'Date of Birth': 'Dzimšanas datums',
      'Date of birth': 'Dzimšanas datums',
      Gender: 'Dzimums',
      'Select gender': 'Izvēlieties dzimumu',
      'Representing country': 'Pārstāvētā valsts',
      'Select country': 'Izvēlieties valsti',
      City: 'Pilsēta',
      'Select role(s)': 'Izvēlieties lomu(-as)',
      Address: 'Adrese',
      'Enter address': 'Ievadiet adresi',
      'Parent found': 'Vecāks atrasts',
      'Parent not found': 'Vecāks nav atrasts',
      'Removed coach': 'Noņemts treneris',
      'Failed to removed coach': 'Neizdevās noņemt treneri',
      'Coach created': 'Treneris izveidots',
      'Failed to create coach': 'Neizdevās izveidot treneri',
      Club: 'Klubs',
      Save: 'Saglabāt',
      'Select club': 'Izvēlieties klubu',
      Coach: 'Treneris',
      'Select a coach': 'Izvēlieties treneri',
      Parent: 'Vecāks',
      'Enter email': 'Ievadiet e-pastu',
      Biography: 'Biogrāfija',
      'Write a bio': 'Rakstiet biogrāfiju',
      CHECKBOXES: 'IZVĒLES',
      'Give access to coach to purchase licences and manage entries on your behalf': 'Piešķirt piekļuvi trenerim, lai viņš varētu iegādāties licences un pārvaldīt ierakstus jūsu vārdā',
      'Give access to club manager(-es) to purchase licences and manage entries on your behalf':
        'Piešķirt piekļuvi kluba vadītājam(-iem), lai viņi varētu iegādāties licences un pārvaldīt pieteikumus jūsu vārdā',
      'Give access to parent(-s) to purchase licences and manage entries on your behalf': 'Piešķirt piekļuvi vecākiem, lai viņi varētu iegādāties licences un pārvaldīt ierakstus jūsu vārdā',
      Socials: 'Sociālie tīkli',
      'Insert link here': 'Ievadiet saiti šeit',
      Facebook: 'Facebook',
      Instagram: 'Instagram',
      Linkedin: 'Linkedin',
      Tiktok: 'Tiktok',
      Youtube: 'Youtube',
      'Enter Id': 'Ievadiet Id',
      'Password should be 8 chars minimum.': 'Parolei jābūt vismaz 8 rakstzīmēm.',
      'Passwords must match': 'Parolēm jāsakrīt',
      'Password changed successfully': 'Parole veiksmīgi nomainīta',
      'New Password': 'Jauna parole',
      'Re-Enter': 'Apstipriniet paroli',
      'Name is Required': 'Vārds ir nepieciešams',
      'Surname is Required': 'Uzvārds ir nepieciešams',
      'Re enter password': 'Atkārtoti ievadiet paroli',
      'User is already registered': 'Lietotājs jau ir reģistrēts',
      'Create an account': 'Izveidot kontu',
      'Already have an account?': 'Jums jau ir konts?',
      'Log in': 'Pieslēgties',
      'or create account': 'vai izveidot kontu',
      "I'm creating an account for a kid": 'Es izveidoju kontu bērnam',
      'sign-up description':
        'Jūs izveidojat kontu turnīra dalībniekam. Ja jūs reģistrējat bērnu, lūdzu, izveidojiet viņa/viņas profilu. Drīz būs pieejami treneru un vecāku profili, kas ļaus jums saistīt tos ar bērna profilu.',
      'Email Address': 'E-pasta adrese',
      Continue: 'Turpināt',
      'Pick a sport': 'Izvēlieties sportu',
      'Pick a sport that you play': 'Izvēlieties sportu, kuru spēlējat',
      'Gender is required': 'Ir nepieciešams dzimums',
      'Phone is required': 'Tālrunis ir nepieciešams',
      'Citizenship is required': 'Valsts ir obligāta',
      'Dob is required': 'Dzimšanas datums ir nepieciešams',
      'FIDE ID Not found': 'FIDE ID nav atrasts',
      'FIDE ID found': 'FIDE ID atrasts',
      'Tell us more about you': 'Pastāstiet mums vairāk par sevi',
      'More information about yourself': 'Vairāk informācijas par sevi',
      'Enter FIDE ID': 'Ievadiet FIDE ID',
      'IPIN ID is correct': 'IPIN ID ir pareizs',
      'Your Licenses': 'Jūsu licences',
      'Attach your licenses': 'Pievienojiet savas licences',
      Skip: 'Izlaist',
      'User registered successfully': 'Lietotājs veiksmīgi reģistrēts',
      'What is your primary role in the world of sports?': 'Kāda ir jūsu galvenā loma sporta pasaulē?',
      'Register & Go to profile': 'Reģistrēties un doties uz profilu',
      'Matches won': 'Uzvarētās spēles',
      'less than a minute': 'mazāk par minūti',
      read: 'lasīt',
      minute: 'minūte',
      'Select Licence': 'Izvēlēties licenci',
      'In progress / upcoming tournaments': 'Notiek / gaidāmie turnīri',
      'Past tournaments': 'Iepriekšējie turnīri',
      'Entry deadline': 'Pieteikšanās termiņš',
      'Registration closing in': 'Pieteikšanās beidzas pēc',
      minutes: 'minūtēm',
      hours: 'stundām',
      'Start time': 'Sākuma laiks',
      'View List': 'Skatīties sarakstu',
      Free: 'Bezmaksas',
      Calendar: 'kalendārs',
      Menu: 'Izvēlne',
      'Manage tournament': 'Pārvaldīt turnīru',
      'Partner search': 'Partneru meklēšana',
      'Partner Search': 'Partneru meklēšana',
      'Searching...': 'Meklē...',
      Apply: 'Pieteikties',
      Delete: 'Dzēst',
      'Create application': 'Izveidot pieteikumu',
      'No partner search applications found': 'Netika atrasti partneru meklēšanas pieteikumi',
      'Log in to apply': 'Pieslēdzieties, lai pieteiktos',
      Player: 'Spēlētājs',
      'Time of registration': 'Reģistrācijas laiks',
      confirmed: 'apstiprināts',
      declined: 'noraidīts',
      'payment pending': 'gaida apmaksu',
      WITHDRAW: 'IZSTĀTIES',
      'No other tournament found': 'Cits turnīrs netika atrasts',
      welcomeTextCustom:
        'darbojas ar Tournated, visaptverošu sporta pārvaldības programmatūru, kas ļauj sporta organizācijām efektīvi pārvaldīt, attīstīt un pelnīt no savas darbības. Risinājums ļauj organizācijām izveidot pielāgotas tiešsaistes platformas, automatizēt un vienkāršot līgu, klubu, turnīru un citu pasākumu pārvaldību, palielināt auditorijas iesaisti un vienkārši apstrādāt maksājumus.',
      welcomeText:
        'piecu gadu pētniecības un attīstības rezultāts, sadarbojoties ar vairāk nekā 200 organizācijām, ir inovatīvs, pielāgojams, lietotājam draudzīgs un pieejams visaptverošs risinājums jebkura lieluma sporta organizācijām.',
      'Did you have an account in one of ': 'Vai jums bija konts kādā no ',
      those: 'šiem',
      'platforms?': 'platformām?',
      'Recover password': 'Atgūt paroli',
      newUser: 'Es esmu jauns lietotājs. Reģistrēties',
      'Continue as a guest': 'Turpināt kā viesis',
      Support: 'Atbalsts',
      'Welcome to': 'Laipni lūdzam',
      'If you have an account on one of ': 'Ja jums ir konts kādā no ',
      these: 'šīm',
      'platforms, log in with your e-mail or create a new profile': 'platformām, piesakieties ar savu e-pastu vai izveidojiet jaunu profilu',
      'Reset password': 'Atiestatīt paroli',
      'Welcome to the': 'Laipni lūdzam',
      platform: 'platformā',
      'Powered by': 'Darbina',
      'All Categories': 'Visas kategorijas',
      'Tournament Results': 'Turnīra rezultāti',
      versionText: 'Beta versija ir sākusi darbību. Lūdzu, iesniedziet atsauksmes',
      here: 'šeit',
      'Full view': 'Pilns skats',
      Timeline: 'Laika skala',
      Grid: 'Režģis',
      "Bird's View": 'KOPSKATS',
      'Third Party Licences': 'Trešo pušu licences',
      Friend: 'Draugs',
      'Step 2': '2. solis:',
      'Step 3': '3. solis:',
      'Your Details': 'Jūsu informācija',
      'Partner Details': 'Partnera informācija',
      'Enter partner email': 'Ievadiet partnera e-pastu',
      'Register & Pay': 'Reģistrēties un Maksāt',
      'On-Site': 'Uz Vietas',
      Now: 'Tagad',
    },
  },
  ka: {
    translation: {
      maintenanceMessage: 'ჩვენ ამჟამად ვაახლებთ პლატფორმას, რაც შეიძლება რამდენიმე წუთი დასჭირდეს. გთხოვთ, სცადოთ კიდევ ერთხელ ცოტა ხანში.',
      networkErrorMessage: 'გთხოვთ, შეამოწმოთ თქვენი ქსელის კავშირი და სცადოთ კიდევ ერთხელ.',
      errorTitle: 'უი! რაღაც არასწორია!',
      registrationOpen: 'რეგისტრაცია ღიაა',
      registrationClosed: 'რეგისტრაცია დახურულია',
      live: 'ცოცხალი',
      header: {
        discover: 'აღმოაჩინეთ',
        enterName: 'შეიყვანეთ სახელი',
        sportsManager: 'სპორტის მენეჯერი?',
        learnHow: 'გაიგეთ როგორ',
        accelerate: 'ჩვენ შეგვიძლია თქვენი ორგანიზაციის აჩქარება.',
        powering: 'ჩვენი კლიენტები',
        tournaments: 'ტურნირები,',
        leagues: 'ლიგები',
        organizations: 'ორგანიზაციები',
        clubs: 'კლუბები',
        coaches: 'მწვრთნელები',
        placeholder: {
          tournaments: 'შეიყვანეთ ტურნირის სახელი',
          leagues: 'შეიყვანეთ ლიგის სახელი',
          organizations: 'შეიყვანეთ ორგანიზაციის სახელი',
          clubs: 'შეიყვანეთ კლუბის სახელი',
          coaches: 'შეიყვანეთ მწვრთნელის სახელი',
        },
      },
      read_more: 'წაიკითხეთ მეტი',
      Close: 'დახურვა',
      bannerHeading: 'დაიწყეთ თქვენი ტურნირების დაგეგმვა სრულიად უფასოდ',
      bannerDescription: 'ტურნირზე რეგისტრაციის, ჯგუფების ავტომატურად დალაგების, ბადეს და თამაშების განრიგის და შედეგების მართვა  ',
      startNow: 'დაიწყეთ ახლავე',
      featureTournaments: 'გამორჩეული ტურნირები',
      tournamentNotFound: ' ტურნირები არ არის ნაპოვნი',
      viewAll: 'ყველას ნახვა',
      featureLeague: 'გამორჩეული ლიგები',
      leaguesNotFound: 'გამორჩეული ლიგები არ არის ნაპოვნი',
      Tournaments: 'ტურნირები',
      NoTournamentsFound: 'ტურნირები არ არის ნაპოვნი',
      Rankings: 'რეიტინგები',
      noRankingFound: 'რეიტინგები არ არის ნაპოვნი',
      news: 'სიახლეები',
      noNewsFound: 'სიახლეები არ არის ნაპოვნი',
      partners: 'ჩვენი პარტნიორები',
      noPartnersFound: 'პარტნიორები არ არის ნაპოვნი',
      worldwide: 'მსოფლიოში მასშტაბით',
      search: 'ძიება',
      pricing: 'ფასები',
      loginRegister: 'შესვლა / რეგისტრაცია',
      home: 'მთავარი',
      organizations: 'ორგანიზაციები',
      leagues: 'ლიგები',
      teams: 'გუნდები',
      clubs: 'კლუბები',
      coaches: 'მწვრთნელები',
      tennis: 'ჩოგბურთი',
      beachTenis: 'პლაჟის ჩოგბურთი',
      'Beach Volley': 'პლაჟის ფრენბურთი',
      'Sport Fishing': 'სპორტული თევზაობა',
      Volleyball: 'ფრენბურთი',
      Sports: 'სპორტის სახეობები',
      RESOURSES: 'რესურსები',
      Feature: 'გამორჩეული',
      'Create account': 'ანგარიშის შექმნა',
      Login: 'შესვლა',
      Packages: 'პაკეტები',
      'Package one': 'პაკეტი ერთი',
      'Package two': 'პაკეტი ორი',
      'Package three': 'პაკეტი სამი',
      'Package Four': 'პაკეტი ოთხი',
      'Customer suppor': 'მომხმარებელთა მხარდაჭერა',
      FAQ: 'ხშირად დასმული კითხვები',
      Documentation: 'დოკუმენტაცია',
      'Video tutorials': 'ვიდეო გაკვეთილები',
      'Submit ticket': 'ბილეთის გაგზავნა',
      'Contact us': 'კონტაქტი',
      'Our contacts': 'ჩვენი კონტაქტები',
      Padel: 'პადელი',
      Pickleball: 'პიკლბოლი',
      'FEATURED LEAGUES': 'გამორჩეული ლიგები',
      View: 'ნახვა',
      'No leagues found': 'ლიგები არ არის ნაპოვნი',
      Athletes: 'სპორტსმენები',
      'Managed by': 'მართავს',
      'View League': 'ლიგის ნახვა',
      Type: 'ტიპი',
      National: 'ეროვნული',
      International: 'საერთაშორისო',
      Local: 'ადგილობრივი',
      Period: 'პერიოდი',
      League: 'ლიგა',
      Country: 'ქვეყანა',
      Year: 'წელი',
      Points: 'ქულები',
      Position: 'პოზიცია',
      Team: 'გუნდი',
      'No teams found': 'გუნდები არ არის ნაპოვნი',
      'Team Members': 'გუნდის წევრები',
      Sport: 'სპორტი',
      'No clubs found': 'კლუბები არ არის ნაპოვნი',
      Experience: 'გამოცდილება',
      Students: 'სტუდენტები',
      Author: 'ავტორი',
      Category: 'კატეგორია',
      'My Entries': 'ჩემი შესვლები',
      'My Leagues': 'ჩემი ლიგები',
      'My Licences': 'ჩემი ლიცენზიები',
      'My Teams': 'ჩემი გუნდები',
      'Get Licence': 'ლიცენზიის მიღება',
      demo: 'დემო',
      Settings: 'პარამეტრები',
      'Log out': 'გამოსვლა',
      'Dark view': 'მუქი დიზაინი',
      Manager: 'მენეჯერი',
      Tournament: 'ტურნირი',
      Cost: 'ღირებულება',
      Duration: 'ხანგრძლივობა',
      'Purchase on': 'შესყიდვა',
      'No license found': 'ლიცენზია არ არის ნაპოვნი',
      Additional: 'დამატებითი',
      Status: 'სტატუსი',
      Date: 'თარიღი',
      'No entries found': 'შესვლები არ არის ნაპოვნი',
      'My Licenses': 'ჩემი ლიცენზიები',
      'Add new License': 'ახალი ლიცენზიის დამატება',
      'Create Team': 'გუნდის შექმნა',
      Info: 'ინფორმაცია',
      infoDes: 'გარკვეულ ლიგებში / ტურებში მონაწილეობის მისაღებად მოთამაშეს უნდა ჰქონდეს შესაბამისი აქტიური ლიცენზია.',
      'Select League': 'ლიგის არჩევა',
      'Select License': 'ლიცენზიის არჩევა',
      'Promo code': 'პრომო კოდი',
      'I agree to the': 'ვეთანხმები',
      'Terms and Conditions': 'წესები და პირობები',
      Price: 'ფასი',
      'Confirm Payment': 'გადახდის დადასტურება',
      History: 'ისტორია',
      'Tournated Manager': 'ტურნირების მენეჯერი',
      'Tournated Custom': 'ტურნირების მორგებული',
      'Customer support': 'მომხმარებლის მხარდაჭერა',
      Categories: 'კატეგორიები',
      Matches: 'მატჩები',
      'Organized by': 'ორგანიზებულია',
      Register: 'რეგისტრაცია',
      Entries: 'შესვლები',
      Location: 'მდებარეობა',
      'National Tour': 'ეროვნული ტური',
      '4 mins read': '4 წუთი წასაკითხად',
      'Age group': 'ასაკობრივი ჯგუფი',
      'Skills level': 'კვალიფიკაციის დონე',
      someDes: 'SIA Vertexo © 2020. ყველა უფლება დაცულია',
      'Manage in CMS': 'მართვა CMS-ში',
      'Fact Sheet': 'საინფორმაციო ფურცელი',
      General: 'ზოგადი',
      Participants: 'მონაწილეები',
      Groups: 'ჯგუფები',
      Draws: 'ბადე',
      'Live Standings': 'ცოცხალი რეიტინგები',
      'Order of play': 'თამაშის წესები',
      Pairings: 'წყვილები',
      Standings: 'რეიტინგები',
      'Final standings': 'საბოლოო რეიტინგები',
      'List & Results': 'სია და შედეგები',
      'Entry Fee': 'ტურნირზე რეგისტრაციის საფასური',
      'Entry Deadline': 'რეგისტრაციის ბოლო ვადა',
      'Withdrawal deadline': 'ამოწერის ბოლო  ვადა',
      'Start date': 'დაწყების თარიღი',
      'End date': 'დასრულების თარიღი',
      and: 'და',
      others: 'სხვა',
      'are confirmed': 'დამტკიცებულია',
      Description: 'აღწერა',
      'Tournament Poster': 'ტურნირის პოსტერი',
      'More Info': 'მეტი ინფორმაცია',
      'Additional materials about tournament': 'დამატებითი მასალები ტურნირის შესახებ',
      Regulations: 'რეგულაციები',
      'Tournaments Discord': 'ტურნირების დისკორდი',
      discordDes: 'გაესაუბრეთ ტურნირის ადმინისტრატორს, დაუსვით კითხვები და გაესაუბრეთ  სხვა მოთამაშეებს!',
      'Join Channel': 'შემოგვიერთდით',
      'Fact sheet': 'საინფორმაციო ფურცელი',
      'Search hotels and more': 'ძიება სასტუმროების და ა.შ...',
      Destination: 'მიზანი',
      'Select Destination': 'მიზნის არჩევა',
      'Check in date': 'რეგისტრაციის თარიღი',
      'Check out date': 'რეგისტრაციის გაუქმების თარიღი',
      'Best players on this tournament': 'ტურნირის საუკეთესო მოთამაშეები',
      Gallery: 'გალერეა',
      'Recent photos': 'უახლესი ფოტოები',
      'Buy licence': 'ლიცენზიის ყიდვა',
      'Select All': 'ყველას არჩევა',
      "Don't see your sport? Contact us": 'თქვენი სპორტის სახეობა არ ჩანს? დაგვიკავშირდით',
      'Description is missing': 'აღწერა არ არის',
      day: 'დღე',
      days: 'დღეები',
      Organization: 'ორგანიზაცია',
      Athlete: 'მოთამაშე',
      'Birth year': 'დაბადების წელი',
      Joined: 'შემოუერთდა',
      'Select Sport': 'სპორტის არჩევა',
      'Select organization': 'ორგანიზაციის არჩევა',
      'Select league': 'ლიგის არჩევა',
      Overview: 'მიმოხილვა',
      About: 'შესახებ',
      'Win rate': 'გამარჯვების პროცენტული მაჩვენებელი',
      Achievements: 'მიღწევები',
      'Upcoming Matches': 'მომავალი მატჩები',
      'Past Matches': 'წინა მატჩები',
      'Upcoming Tournaments': 'მომავალი ტურნირები',
      'Purchase history': 'შეძენის ისტორია',
      'description and detail': 'გაუმჯობესება:ლიცენზიის/წევრობის გააქტიურება სავალდებულოა შესაბამის  ლიგაში  და ტურნირებში მონაწილეობისთვის',
      'Purchase datetime': 'შეძენის თარიღი და დრო',
      'Payment Status': 'გადახდის სტატუსი',
      Licence: 'ლიცენზია',
      Members: 'წევრები',
      Student: 'სტუდენტი',
      'Personal page': 'პირადი გვერდი',
      'My Career': 'ჩემი კარიერა',
      'Latest tournaments': 'უახლესი ტურნირები',
      'Upcoming tournaments': 'მომავალი ტურნირები',
      'No members found': 'წევრები არ არის ნაპოვნი',
      'No tournaments found': 'ტურნირები არ არის ნაპოვნი',
      'No matches found': 'მატჩები არ არის ნაპოვნი',
      'No rankings found': 'რეიტინგები არ არის ნაპოვნი',
      'No past matches found': 'წინა მატჩები არ არის ნაპოვნი',
      'No upcoming matches found': 'მომავალი მატჩები არ არის ნაპოვნი',
      'No achievements found': 'მიღწევები არ არის ნაპოვნი',
      Documents: 'დოკუმენტები',
      Contact: 'კონტაქტი',
      'No photos added': 'ფოტოები არ არის დამატებული',
      'Manage league': 'ლიგის მართვა',
      'No documents added': 'დოკუმენტები არ არის დამატებული',
      Email: 'ელფოსტა',
      Phone: 'ტელეფონი',
      Website: 'ვებგვერდი',
      Place: 'ადგილი',
      'Tournament Name': 'ტურნირის სახელი',
      'Manage organization': 'ორგანიზაციის მართვა',
      'Terms & Conditions': 'წესები და პირობები',
      LEGAL: 'იურიდიული',
      'Privacy policy': 'კონფიდენციალურობა',
      'Cancellation policy': 'გაუქმების წესები',
      GDPR: 'GDPR',
      'All Rights Reserved': 'ყველა უფლება დაცულია',
      'League [Category]': 'ლიგა [კატეგორია]',
      'Other tournaments': 'სხვა ტურნირები',
      'Points Details': 'ქულების დეტალები',
      'International rating': 'საერთაშორისო რეიტინგი',
      Day: 'დღე',
      Days: 'დღეები',
      Tennis: 'ჩოგბურთი',
      'Beach Tennis': 'პლაჟის ჩოგბურთი',
      'Beach Volleyball': 'პლაჟის ფრენბურთი',
      Chess: 'ჭადრაკი',
      'In progress': 'მიმდინარეობს',
      Registration: 'რეგისტრაცია',
      'Deadline past': 'ვადა გასულია',
      Completed: 'დასრულებულია',
      'No points yet': 'ჯერ არ არის ქულები',
      'is confirmed': 'დადასტურებულია',
      Contacts: 'კონტაქტები',
      Organizer: 'ორგანიზატორი',
      'Featured athletes': 'გამორჩეული სპორტსმენები',
      'Top-ranked participants of the tournament': 'ტურნირის ყველაზე მაღალი რეიტინგის მქონე მონაწილეები',
      NR: 'NR',
      PLAYER: 'მოთამაშე',
      CITY: 'ქალაქი',
      RATING: 'რეიტინგი',
      CLUB: 'კლუბი',
      TOTAL: 'სულ',
      SEGMENT: 'სეგმენტი',
      SEED: 'განთესილი',
      Search: 'ძებნა',
      'No participants found': 'მონაწილეები არ არის ნაპოვნი',
      Total: 'სულ',
      Seed: 'განთესილი',
      Rating: 'რეიტინგი',
      Rank: 'რეიტინგი',
      'Category:': 'კატეგორია:',
      'No category found': 'კატეგორია არ არის ნაპოვნი',
      'No Rounds found': 'რაუნდები არ არის ნაპოვნი',
      White: 'თეთრი',
      Black: 'შავი',
      Name: 'სახელი',
      Pts: 'ქულები',
      Score: 'ანგარიში',
      'There is no available pairings!': 'მიმდინარე წყვილები არ არის!',
      'Generating PDF...': 'PDF გენერირება...',
      'PDF Generated Successfully!': 'PDF წარმატებით გენერირებულია!',
      'PDF Downloaded Successfully!': 'PDF წარმატებით გადმოწერილია!',
      'Downloading...': 'გადმოწერა...',
      'Pairing Standings': 'წყვილების სტატუსი',
      Opponents: 'მოწინააღმდეგეები',
      BHC1: 'BHC1',
      BHC: 'BHC',
      BGP: 'BGP',
      WIN: 'გამარჯვება',
      'There is no available entries!': 'ჩანაწერები არ არის!',
      "I'm a coach/parent": 'მე ვარ მწვრთნელი/მშობელი',
      'Step 1': 'ნაბიჯი 1',
      'Select category': 'კატეგორიის არჩევა',
      Request: 'მოთხოვნა',
      'Register & Pay Now': 'რეგისტრაცია და გადახდა ახლა',
      'Register & Pay On-Site': 'რეგისტრაცია და გადახდა ადგილზე',
      'Terms And Conditions': 'წესები და პირობები',
      'Latest Winners': 'ბოლო გამარჯვებულები',
      'No winners found': 'გამარჯვებულები არ არის ნაპოვნი',
      'No news found': 'ახალი ამბები არ არის ნაპოვნი',
      'No partners found': 'პარტნიორები არ არის ნაპოვნი',
      'Skills Levels': 'კვალიფიკაციის დონეები',
      'To get register for the tournament you must have active tour licence.': 'ტურნირზე რეგისტრაციისთვის სავალდებულოა აქტიური ლიცენზია.',
      'Contact Details': 'საკონტაქტო დეტალები',
      'Our Partners': 'ჩვენი პარტნიორები',
      'View all': 'ყველას ნახვა',
      Member: 'წევრი',
      'Team Name': 'გუნდის სახელი',
      News: 'ახალი ამბები',
      'Men Pro': 'მამაკაცები ',
      'Latvia Tennis Union League': 'ლატვიის ჩოგბურთის კავშირის ლიგა',
      Leagues: 'ლიგები',
      'No league found': 'ლიგა არ არის ნაპოვნი',
      'Home Page': 'მთავარი გვერდი',
      Upcoming: 'მომავალი',
      'This month': 'ამ თვეში',
      'Last 7 days': 'ბოლო 7 დღე',
      'Last 30 days': 'ბოლო 30 დღე',
      Hours: 'საათები',
      Minutes: 'წუთები',
      Teams: 'გუნდები',
      'No student found': 'სტუდენტი არ არის ნაპოვნი',
      'Other news': 'სხვა ახალი ამბები',
      'Don’t have an account?': 'ანგარიში არ გაქვთ?',
      'Sign up': 'რეგისტრაცია',
      'or log in': 'ან შესვლა',
      'Did you forget your password?': 'პაროლი დაგავიწყდათ?',
      'Must be a valid email': 'სავალდებულოა მოქმედი ელფოსტა',
      'Email is required': 'ელფოსტა აუცილებელია',
      'Password is required': 'პაროლი აუცილებელია',
      Password: 'პაროლი',
      'Remember me': 'დამიმახსოვრე',
      'Log In': 'შესვლა',
      'Unauthorized , please sign in': 'არ არის ავტორიზებული, გთხოვთ, შედით სისტემაში',
      'Reset your password': 'პაროლის აღდგენა',
      'Enter the e-mail address': 'შეიყვანეთ ელფოსტის მისამართი',
      'Enter your e-mail address with which you are registered': 'შეიყვანეთ ელფოსტის მისამართი, რომლითაც დარეგისტრირებული ხართ და მიიღებთ პაროლის აღდგენის ბმულს.',
      'Please check your email for reset password link!': 'გთხოვთ შეამოწმეთ თქვენი ელფოსტა პაროლის აღდგენისთვის!',
      'Send instructions': 'ინსტრუქციების გაგზავნა',
      'My Licenes': 'ჩემი ლიცენზიები',
      'My leagues': 'ჩემი ლიგები',
      'My tournaments': 'ჩემი ტურნირები',
      'My matches': 'ჩემი მატჩები',
      'My entries': 'ჩემი ჩანაწერები',
      'My rankings': 'ჩემი რეიტინგები',
      Stats: 'სტატისტიკა',
      'Leagues took part in': 'ლიგები, რომლებშიც მონაწილეობა მიიღო',
      'Active licences': 'აქტიური ლიცენზიები',
      Titles: 'ტიტულები',
      'Matches played': 'გამართული მატჩები',
      'Matches lost': 'წაგებული მატჩები',
      'Games/points played': 'გამართული თამაშები/ქულები',
      'All sports': 'ყველა სპორტი',
      'All organizations': 'ყველა ორგანიზაცია',
      'All leagues': 'ყველა ლიგა',
      Registered: 'დარეგისტრირებული',
      Withdraw: 'რეგისტრაციის გაუქმება',
      'Withdraw deadline passed': 'გაუქმების ვადა გასულია',
      'Request Withdrawn': 'მოთხოვნა გაუქმებულია',
      'Something went wrong!': 'რაღაც არასწორია!',
      Withdrawal: 'გაუქმება',
      'Entry to withdraw': 'ჩანაწერის გაუქმება',
      'Withdrawal Reason': 'გაუქმების მიზეზი',
      'Write your reason here': 'მიზეზი ჩაწერეთ აქ',
      Confirm: 'დადასტურება',
      'Please specify reason': 'გთხოვთ, მიუთითოთ მიზეზი',
      'AUDI - The Beach Tennis Championship': 'AUDI - პლაჟის ჩოგბურთის ჩემპიონატი',
      'Get licence': 'ლიცენზიის მიღება',
      'Additional questions': 'დამატებითი კითხვები',
      'Select duration': 'ხანგრძლივობის არჩევა',
      Career: 'კარიერა',
      'Third Party Licenes': 'მესამე პირის ლიცენზიები',
      'User updated successfully': 'მომხმარებელი წარმატებით განახლდა',
      'User Account': 'მომხმარებლის ანგარიში',
      'We recommend an image that is 256 x 256 pixels': 'გირჩევთ ატვირთოთ ფოტოსურათი ზომით  256 x 256 პიქსელი ',
      'Enter Email Address': 'შეიყვანეთ ელფოსტის მისამართი',
      'Enter name': 'შეიყვანეთ სახელი',
      Surname: 'გვარი',
      'Enter surname': 'შეიყვანეთ გვარი',
      'Contact phone number': 'საკონტაქტო ტელეფონის ნომერი',
      'Enter phone number': 'შეიყვანეთ ტელეფონის ნომერი',
      'Date of Birth': 'დაბადების თარიღი',
      'Date of birth': 'დაბადების თარიღი',
      Gender: 'სქესი',
      'Select gender': 'სქესის არჩევა',
      'Representing country': 'წარმომადგენელი ქვეყანა',
      'Select country': 'ქვეყნის არჩევა',
      City: 'ქალაქი',
      'Select role(s)': 'როლის არჩევა',
      Address: 'მისამართი',
      'Enter address': 'შეიყვანეთ მისამართი',
      'Parent found': 'მშობელი ნაპოვნია',
      'Parent not found': 'მშობელი არ არის ნაპოვნი',
      'Removed coach': 'მწვრთნელი წაშლილია',
      'Failed to removed coach': 'მწვრთნელის წაშლა ვერ მოხერხდა',
      'Coach created': 'მწვრთნელი შექმნილია',
      'Failed to create coach': 'მწვრთნელის შექმნა ვერ მოხერხდა',
      Club: 'კლუბი',
      Save: 'შენახვა',
      'Select club': 'კლუბის არჩევა',
      Coach: 'მწვრთნელი',
      'Select a coach': 'მწვრთნელის არჩევა',
      Parent: 'მშობელი',
      'Enter email': 'შეიყვანეთ ელფოსტა',
      Biography: 'ბიოგრაფია',
      'Write a bio': 'დაწერეთ ბიოგრაფია',
      CHECKBOXES: 'მონიშნულები',
      'Give access to coach to purchase licences and manage entries on your behalf': 'მწვრთნელს მიანიჭეთ უფლება შეიძინოს ლიცენზიები და მართოს ჩანაწერები თქვენი სახელით',
      'Give access to club manager(-es) to purchase licences and manage entries on your behalf': 'კლუბის მენეჯერს მიანიჭეთ უფლება შეიძინოს ლიცენზიები და მართოს ჩანაწერები თქვენი სახელით',
      'Give access to parent(-s) to purchase licences and manage entries on your behalf': 'მშობლებს მიანიჭეთ უფლება შეიძინოს ლიცენზიები და მართოს ჩანაწერები თქვენი სახელით',
      Socials: 'სოციალური ქსელები',
      'Insert link here': 'ჩასვით ბმული აქ',
      Facebook: 'Facebook',
      Instagram: 'Instagram',
      Linkedin: 'Linkedin',
      Tiktok: 'Tiktok',
      Youtube: 'Youtube',
      'Enter Id': 'შეიყვანეთ ID',
      'Password should be 8 chars minimum.': 'პაროლი უნდა შეიცავდეს მინიმუმ 8 სიმბოლოს.',
      'Passwords must match': 'პაროლები უნდა ემთხვეოდეს',
      'Password changed successfully': 'პაროლი წარმატებით შეიცვალა',
      'New Password': 'ახალი პაროლი',
      'Re-Enter': 'პაროლის დადასტურება',
      'Name is Required': 'სახელი აუცილებელია',
      'Surname is Required': 'გვარი აუცილებელია',
      'Re enter password': 'ხელახლა ჩაწერეთ პაროლი',
      'User is already registered': 'მომხმარებელი უკვე დარეგისტრირებულია',
      'Create an account': 'ანგარიშის შექმნა',
      'Already have an account?': 'უკვე გაქვთ ანგარიში?',
      'Log in': 'შესვლა',
      'or create account': 'ან შექმენით ანგარიში',
      "I'm creating an account for a kid": 'მე ვქმნი ანგარიშს ბავშვისთვის',
      'sign-up description':
        'თქვენ ქმნით ანგარიშს ტურნირის მონაწილისთვის. თუ არეგისტრირებთ ბავშვს, გთხოვთ, შექმენით მისი პროფილი. მწვრთნელებისა და მშობლების პროფილები მალე იქნება ხელმისაწვდომი, რაც მათ საშუალებას მისცემს მათი და ბავშვის პროფილი ერთმანეთს დაუკავშიროს .',
      'Email Address': 'ელფოსტის მისამართი',
      Continue: 'გაგრძელება',
      'Pick a sport': 'სპორტის არჩევა',
      'Pick a sport that you play': 'აირჩიეთ სპორტი, რომელსაც თამაშობთ',
      'Gender is required': 'სქესი აუცილებელია',
      'Phone is required': 'ტელეფონი აუცილებელია',
      'Citizenship is required': 'ქვეყანა აუცილებელია',
      'Dob is required': 'დაბადების თარიღი აუცილებელია',
      'FIDE ID Not found': 'FIDE ID არ არის ნაპოვნი',
      'FIDE ID found': 'FIDE ID ნაპოვნია',
      'Tell us more about you': 'მოგვიყევით მეტი თქვენს შესახებ',
      'More information about yourself': 'მეტი ინფორმაცია თქვენ შესახებ',
      'Enter FIDE ID': 'შეიყვანეთ FIDE ID',
      'IPIN ID is correct': 'IPIN ID სწორია',
      'Your Licenses': 'თქვენი ლიცენზიები',
      'Attach your licenses': 'მიამაგრეთ თქვენი ლიცენზიები',
      Skip: 'გამოტოვება',
      'User registered successfully': 'მომხმარებელი წარმატებით დარეგისტრირდა',
      'What is your primary role in the world of sports?': 'რა არის თქვენი ძირითადი როლი სპორტის სამყაროში?',
      'Register & Go to profile': 'რეგისტრაცია და პროფილის ნახვა',
      'Matches won': 'მატჩები, რომელშიც გაიმარჯვეთ',
      'less than a minute': 'წუთზე ნაკლები',
      read: 'წაიკითხეთ',
      minute: 'წუთი',
      'Select Licence': 'აირჩიეთ ლიცენზია',
      'In progress / upcoming tournaments': 'მიმდინარეობს / მომავალი ტურნირები',
      'Past tournaments': 'გასული ტურნირები',
      'Entry deadline': 'შესვლის ვადა',
      Calendar: 'კალენდარი',
      Menu: 'მენიუ',
      'Manage tournament': 'ტურნირის მართვა',
      'Partner search': 'პარტნიორის ძებნა',
      'Partner Search': 'პარტნიორის ძებნა',
      'Searching...': 'ძიება...',
      Apply: 'განაცხადის გაკეთება',
      Delete: 'წაშლა',
      'Create application': 'განაცხადის შექმნა',
      'No partner search applications found': 'პარტნიორების ძიების განაცხადები ვერ მოიძებნა',
      'Log in to apply': 'განაცხადის გასაკეთებლად შესვლა',
      Player: 'მოთამაშე',
      'Time of registration': 'რეგისტრაციის დრო',
      confirmed: 'დადასტურებული',
      declined: 'უარყოფილი',
      'payment pending': 'გადახდის მოლოდინში',
      WITHDRAW: 'გატანა',
      'No other tournament found': 'სხვა ტურნირი ვერ მოიძებნა',
      welcomeTextCustom:
        'ხელმძღვანელობს Tournated-ით, სპორტის მართვის ყოვლისმომცველი პროგრამული უზრუნველყოფა, რომელიც აძლევს სპორტულ ორგანიზაციებს საშუალებას ეფექტურად მართონ, განავითარონ და გამოიმუშავონ თავიანთი ოპერაციებიდან. ეს გადაწყვეტა ორგანიზაციებს საშუალებას აძლევს შექმნან მორგებული ონლაინ პლატფორმები, ავტომატიზირონ და გამარტივონ ლიგების, კლუბების, ტურნირების და სხვა ღონისძიებების მართვა, გააუმჯობესონ აუდიტორიის ჩართულობა და მარტივად დაამუშაონ გადახდები.',
      welcomeText:
        'ხუთწლიანი კვლევისა და განვითარებისა და 200-ზე მეტი ორგანიზაციის თანამშრომლობის შედეგია ინოვაციური, მორგებადი, მომხმარებელზე ორიენტირებული და ხელმისაწვდომი ყოვლისმომცველი გადაწყვეტა ნებისმიერი ზომის სპორტული ორგანიზაციებისათვის.',
      'Did you have an account in one of ': 'გქონდათ თუ არა ანგარიში რომელიმე ',
      those: 'ამ',
      'platforms?': 'პლატფორმებზე?',
      'Recover password': 'პაროლის აღდგენა',
      newUser: 'მე ახალი მომხმარებელი ვარ. რეგისტრაცია',
      'Continue as a guest': 'გაგრძელება როგორც სტუმარი',
      Support: 'მხარდაჭერა',
      'Welcome to': 'კეთილი იყოს თქვენი მობრძანება',
      'If you have an account on one of ': 'თუ გაქვთ ანგარიში რომელიმე ',
      these: 'ამ',
      'platforms, log in with your e-mail or create a new profile': 'პლატფორმაზე, შედით თქვენი ელფოსტით ან შექმენით ახალი პროფილი',
      'Reset password': 'პაროლის განულება',
      'Welcome to the': 'კეთილი იყოს თქვენი მობრძანება',
      platform: 'პლატფორმაზე',
      'Powered by': 'მუშაობს',
      'All Categories': 'ყველა კატეგორია',
      'Tournament Results': 'ტურნირის შედეგები',
      versionText: 'Beta პირდაპირ ეთერშია. გთხოვთ, გამოაგზავნოთ თქვენი გამოხმაურება',
      here: 'აქ',
      'Full view': 'სრული ხედი',
      Timeline: 'ვადები',
      Grid: 'ბადე',
      "Bird's View": 'ჩიტის ხედი',
      'Third Party Licences': 'მესამე მხარის ლიცენზიები',
      Friend: 'მეგობარი',
      'Step 2': 'ნაბიჯი 2:',
      'Step 3': 'ნაბიჯი 3:',
      'Your Details': 'თქვენი დეტალები',
      'Partner Details': 'პარტნიორის დეტალები',
      'Enter partner email': 'შეიყვანეთ პარტნიორის ელფოსტა',
      'Register & Pay': 'დარეგისტრირდი და გადაიხადე',
      'On-Site': 'ადგილზე',
      Now: 'ახლა',
    },
  },
  esp: {
    translation: {
      maintenanceMessage: 'Actualmente estamos actualizando la plataforma, lo que puede tardar unos minutos. Por favor, inténtelo de nuevo en breve.',
      networkErrorMessage: 'Por favor, verifique su conexión de red y vuelva a intentarlo.',
      errorTitle: '¡Vaya! Algo está mal!',
      registrationOpen: 'Registro abierto',
      registrationClosed: 'Registro cerrado',
      live: 'En vivo',
      header: {
        discover: 'Descubra',
        enterName: 'Ingrese nombre',
        sportsManager: '¿Gerente deportivo?',
        learnHow: 'Aprenda cómo',
        accelerate: 'podemos acelerar su organización.',
        powering: 'Nuestros Clientes',
        tournaments: 'torneos,',
        leagues: 'ligas',
        organizations: 'organizaciones',
        clubs: 'clubes',
        coaches: 'entrenadores',
        placeholder: {
          tournaments: 'Ingrese el nombre del torneo',
          leagues: 'Ingrese el nombre de la liga',
          organizations: 'Ingrese el nombre de la organización',
          clubs: 'Ingrese el nombre del club',
          coaches: 'Ingrese el nombre del entrenador',
        },
      },
      read_more: 'Leer más',
      Close: 'Cerca',
      bannerHeading: 'Empieza a gestionar tus torneos absolutamente gratis',
      bannerDescription: 'Desde la gestión de inscripciones hasta la generación automática de grupos, sorteos y orden de juego, gestión de resultados y más',
      startNow: 'Empieza ahora',
      featureTournaments: 'Torneos destacados',
      tournamentNotFound: 'No se encontraron torneos destacados',
      viewAll: 'Ver todo',
      featureLeague: 'Ligas destacadas',
      leaguesNotFound: 'No se encontraron ligas destacadas',
      Tournaments: 'Torneos',
      NoTournamentsFound: 'No se encontraron torneos',
      Rankings: 'Clasificaciones',
      noRankingFound: 'No se encontraron clasificaciones',
      news: 'Noticias',
      noNewsFound: 'No se encontraron noticias',
      partners: 'Nuestros socios',
      Partners: 'Socios',
      noPartnersFound: 'No se encontraron socios',
      worldwide: 'En todo el mundo',
      search: 'Buscar',
      pricing: 'Precios',
      loginRegister: 'Iniciar sesión / Registrarse',
      home: 'Inicio',
      organizations: 'Organizaciones',
      leagues: 'Ligas',
      teams: 'Equipos',
      clubs: 'Clubes',
      coaches: 'Entrenadores',
      tennis: 'Tenis',
      beachTenis: 'Tenis Playa',
      'Beach Volley': 'Vóley Playa',
      'Sport Fishing': 'Pesca Deportiva',
      Volleyball: 'Voleibol',
      Sports: 'Deportes',
      RESOURSES: 'RECURSOS',
      Feature: 'Destacar',
      'Create account': 'Crear cuenta',
      Login: 'Iniciar sesión',
      Packages: 'Paquetes',
      'Package one': 'Paquete uno',
      'Package two': 'Paquete dos',
      'Package three': 'Paquete tres',
      'Package Four': 'Paquete cuatro',
      'Customer suppor': 'Soporte al cliente',
      FAQ: 'FAQ',
      Documentation: 'Documentación',
      'Video tutorials': 'Tutoriales en video',
      'Submit ticket': 'Enviar ticket',
      'Contact us': 'Contáctenos',
      'Our contacts': 'Nuestros contactos',
      Padel: 'Pádel',
      Pickleball: 'Pickleball',
      'FEATURED LEAGUES': 'LIGAS DESTACADAS',
      View: 'Ver',
      'No leagues found': 'No se encontraron ligas',
      Athletes: 'Atletas',
      'Managed by': 'Gestionado por',
      'View League': 'Ver liga',
      Type: 'Tipo',
      National: 'Nacional',
      International: 'Internacional',
      Local: 'Local',
      Period: 'Período',
      League: 'Liga',
      Country: 'País',
      Year: 'Año',
      Points: 'Puntos',
      Position: 'Posición',
      Team: 'Equipo',
      'No teams found': 'No se encontraron equipos',
      'Team Members': 'Miembros del equipo',
      Sport: 'Deporte',
      'No clubs found': 'No se encontraron clubes',
      Experience: 'Experiencia',
      Students: 'Estudiantes',
      Author: 'Autor',
      Category: 'Categoría',
      'My Entries': 'Mis inscripciones',
      'My Leagues': 'Mis ligas',
      'My Licences': 'Mis licencias',
      'My Teams': 'Mis equipos',
      'Get Licence': 'Obtener licencia',
      demo: 'demo',
      Settings: 'Configuración',
      'Log out': 'Cerrar sesión',
      'Dark view': 'Vista oscura',
      Manager: 'Gestor',
      Tournament: 'Torneo',
      Cost: 'Costo',
      Duration: 'Duración',
      'Purchase on': 'Compra en',
      'No license found': 'No se encontró licencia',
      Additional: 'Adicional',
      Status: 'Estado',
      Date: 'Fecha',
      'No entries found': 'No se encontraron inscripciones',
      'My Licenses': 'Mis licencias',
      'Add new License': 'Agregar nueva licencia',
      'Create Team': 'Crear equipo',
      Info: 'Info',
      infoDes: 'Para participar en ciertas ligas/tours, un jugador debe tener una licencia activa correspondiente.',
      'Select League': 'Seleccionar liga',
      'Select License': 'Seleccionar licencia',
      'Promo code': 'Código promocional',
      'I agree to the': 'Estoy de acuerdo con los',
      'Terms and Conditions': 'Términos y Condiciones',
      Price: 'Precio',
      'Confirm Payment': 'Confirmar Pago',
      History: 'Historial',
      'Tournated Manager': 'Gestor de Torneos',
      'Tournated Custom': 'Torneo Personalizado',
      'Customer support': 'SOPORTE AL CLIENTE',
      Categories: 'Categorías',
      Matches: 'Partidos',
      'Organized by': 'Organizado por',
      Register: 'Registrarse',
      Entries: 'Inscripciones',
      Location: 'Ubicación',
      'National Tour': 'Gira Nacional',
      '4 mins read': '4 mins de lectura',
      'Age group': 'Grupo de edad',
      'Skills level': 'Nivel de habilidades',
      someDes: 'SIA Vertexo © 2020. Todos los Derechos Reservados',
      'Manage in CMS': 'Gestionar en CMS',
      'Fact Sheet': 'Ficha Técnica',
      General: 'General',
      Participants: 'Participantes',
      Groups: 'Grupos',
      Draws: 'Sorteos',
      'Live Standings': 'Clasificación en Vivo',
      'Order of play': 'Orden de juego',
      Pairings: 'Emparejamientos',
      Standings: 'Clasificación',
      'Final standings': 'Clasificación final',
      'List & Results': 'Lista y Resultados',
      'Entry Fee': 'Cuota de inscripción',
      'Entry Deadline': 'Fecha límite de inscripción',
      'Entry deadline': 'Fecha límite de inscripción',
      'Withdrawal deadline': 'Fecha límite de retiro',
      'Start date': 'Fecha de inicio',
      'End date': 'Fecha de finalización',
      and: 'y',
      others: 'otros',
      'are confirmed': 'están confirmados',
      Description: 'Descripción',
      'Tournament Poster': 'Póster del Torneo',
      'More Info': 'Más Información',
      'Additional materials about tournament': 'Materiales adicionales sobre el torneo',
      Regulations: 'Reglamentos',
      'Tournaments Discord': 'Discord de Torneos',
      discordDes: '¡Habla directamente con los administradores del torneo, haz preguntas y charla con otros jugadores!',
      'Join Channel': 'Unirse al Canal',
      'Fact sheet': 'Ficha técnica',
      'Search hotels and more': 'Buscar hoteles y más...',
      Destination: 'Destino',
      'Select Destination': 'Seleccionar Destino',
      'Check in date': 'Fecha de entrada',
      'Check out date': 'Fecha de salida',
      'Best players on this tournament': 'Mejores jugadores en este torneo',
      Gallery: 'Galería',
      'Recent photos': 'Fotos recientes',
      'Buy licence': 'Comprar licencia',
      'Select All': 'Seleccionar todo',
      "Don't see your sport? Contact us": '¿No ves tu deporte? Contáctanos',
      'Description is missing': 'Falta la descripción',
      day: 'día',
      days: 'días',
      Organization: 'Organización',
      Athlete: 'Atleta',
      'Birth year': 'Año de nacimiento',
      Joined: 'Unido',
      'Select Sport': 'Seleccionar Deporte',
      'Select organization': 'Seleccionar organización',
      'Select league': 'Seleccionar liga',
      Overview: 'Resumen',
      About: 'Acerca de',
      'Win rate': 'Tasa de victorias',
      Achievements: 'Logros',
      'Upcoming Matches': 'Próximos partidos',
      'Past Matches': 'Partidos pasados',
      'Upcoming Tournaments': 'Próximos torneos',
      'Purchase history': 'Historial de compras',
      'description and detail': 'Mejorar: Adquirir licencias/membresías requeridas para participantes en ligas o torneos específicos',
      'Purchase datetime': 'Fecha y hora de compra',
      'Payment Status': 'Estado del Pago',
      Licence: 'Licencia',
      Members: 'Miembros',
      Student: 'Estudiante',
      'Personal page': 'Página personal',
      'My Career': 'Mi carrera',
      'Latest tournaments': 'Últimos torneos',
      'Upcoming tournaments': 'Próximos torneos',
      'No members found': 'No se encontraron miembros',
      'No tournaments found': 'No se encontraron torneos',
      'No matches found': 'No se encontraron partidos',
      'No rankings found': 'No se encontraron clasificaciones',
      'No past matches found': 'No se encontraron partidos pasados',
      'No upcoming matches found': 'No se encontraron próximos partidos',
      'No achievements found': 'No se encontraron logros',
      Documents: 'Documentos',
      Contact: 'Contacto',
      'No photos added': 'No se añadieron fotos',
      'Manage league': 'Gestionar liga',
      'No documents added': 'No se añadieron documentos',
      Email: 'Correo electrónico',
      Phone: 'Teléfono',
      Website: 'Sitio web',
      Place: 'Lugar',
      'Tournament Name': 'Nombre del Torneo',
      'Manage organization': 'Gestionar organización',
      'Terms & Conditions': 'Términos y Condiciones',
      LEGAL: 'LEGAL',
      'Privacy policy': 'Política de privacidad',
      'Cancellation policy': 'Política de cancelación',
      GDPR: 'RGPD',
      'All Rights Reserved': 'Todos los Derechos Reservados',
      'League [Category]': 'Liga [Categoría]',
      'Other tournaments': 'Otros torneos',
      'Points Details': 'Detalles de Puntos',
      'International rating': 'Calificación internacional',
      Day: 'Día',
      Days: 'Días',
      Tennis: 'Tenis',
      'Beach Tennis': 'Tenis Playa',
      'Beach Volleyball': 'Vóley Playa',
      Chess: 'Ajedrez',
      'In progress': 'En progreso',
      Registration: 'Registro',
      'Deadline past': 'Plazo vencido',
      Completed: 'Completado',
      'No points yet': 'Aún no hay puntos',
      'is confirmed': 'está confirmado',
      Contacts: 'Contactos',
      Organizer: 'Organizador',
      'Featured athletes': 'Atletas destacados',
      'Top-ranked participants of the tournament': 'Participantes mejor clasificados del torneo',
      NR: 'NR',
      PLAYER: 'JUGADOR',
      CITY: 'CIUDAD',
      RATING: 'CLASIFICACIÓN',
      CLUB: 'CLUB',
      TOTAL: 'TOTAL',
      SEGMENT: 'SEGMENTO',
      SEED: 'SEMILLA',
      Search: 'Buscar',
      'No participants found': 'No se encontraron participantes',
      Total: 'Total',
      Seed: 'Semilla',
      Rating: 'Clasificación',
      Rank: 'Rango',
      'Category:': 'Categoría:',
      'No category found': 'No se encontró categoría',
      'No Rounds found': 'No se encontraron rondas',
      White: 'Blanco',
      Black: 'Negro',
      Name: 'Nombre',
      Pts: 'Pts',
      Score: 'Puntuación',
      'There is no available pairings!': '¡No hay emparejamientos disponibles!',
      'Generating PDF...': 'Generando PDF...',
      'PDF Generated Successfully!': '¡PDF generado con éxito!',
      'PDF Downloaded Successfully!': '¡PDF descargado con éxito!',
      'Downloading...': 'Descargando...',
      'Pairing Standings': 'Clasificación de emparejamientos',
      Opponents: 'Oponentes',
      BHC1: 'BHC1',
      BHC: 'BHC',
      BGP: 'BGP',
      WIN: 'VICTORIA',
      'There is no available entries!': '¡No hay inscripciones disponibles!',
      "I'm a coach/parent": 'Soy entrenador/padre',
      'Step 1': 'Paso 1',
      'Select category': 'Seleccionar categoría',
      Request: 'Solicitud',
      'Register & Pay Now': 'Registrar y Pagar Ahora',
      'Register & Pay On-Site': 'Registrar y Pagar en el Lugar',
      'Terms And Conditions': 'Términos y Condiciones',
      'Latest Winners': 'Últimos Ganadores',
      'No winners found': 'No se encontraron ganadores',

      'No news found': 'No se encontraron noticias',
      'No partners found': 'No se encontraron socios',
      'Skills Levels': 'Niveles de habilidades',
      'To get register for the tournament you must have active tour licence.': 'Para registrarte en el torneo debes tener una licencia de tour activa.',
      'Contact Details': 'Detalles de contacto',
      'Our Partners': 'Nuestros socios',
      'View all': 'Ver todos',
      Member: 'Miembro',
      'Team Name': 'Nombre del equipo',
      News: 'Noticias',
      'Men Pro': 'Hombres Pro',
      'Latvia Tennis Union League': 'Liga de la Unión de Tenis de Letonia',
      Leagues: 'Ligas',
      'No league found': 'No se encontró liga',
      'Home Page': 'Página de inicio',
      Upcoming: 'Próximos',
      'This month': 'Este mes',
      'Last 7 days': 'Últimos 7 días',
      'Last 30 days': 'Últimos 30 días',
      Hours: 'Horas',
      Minutes: 'Minutos',
      Teams: 'Equipos',
      'No student found': 'No se encontró estudiante',
      'Other news': 'Otras noticias',
      'Don’t have an account?': '¿No tienes una cuenta?',
      'Sign up': 'Regístrate',
      'or log in': 'o inicia sesión',
      'Did you forget your password?': '¿Olvidaste tu contraseña?',
      'Must be a valid email': 'Debe ser un correo electrónico válido',
      'Email is required': 'Correo electrónico es obligatorio',
      'Password is required': 'Contraseña es obligatoria',
      Password: 'Contraseña',
      'Remember me': 'Recuérdame',
      'Log In': 'Iniciar sesión',
      'Unauthorized , please sign in': 'No autorizado, por favor inicia sesión',
      'Reset your password': 'Restablece tu contraseña',
      'Enter the e-mail address': 'Ingresa la dirección de correo electrónico',
      'Enter your e-mail address with which you are registered': 'Ingresa la dirección de correo electrónico con la que estás registrado',
      'Please check your email for reset password link!': '¡Por favor, revisa tu correo electrónico para el enlace de restablecimiento de contraseña!',
      'Send instructions': 'Enviar instrucciones',
      'My Licenes': 'Mis licencias',
      'My leagues': 'Mis ligas',
      'My tournaments': 'Mis torneos',
      'My matches': 'Mis partidos',
      'My entries': 'Mis inscripciones',
      'My rankings': 'Mis clasificaciones',
      Stats: 'Estadísticas',
      'Leagues took part in': 'Ligas en las que participó',
      'Active licences': 'Licencias activas',
      Titles: 'Títulos',
      'Matches played': 'Partidos jugados',
      'Matches lost': 'Partidos perdidos',
      'Games/points played': 'Juegos/puntos jugados',
      'All sports': 'Todos los deportes',
      'All organizations': 'Todas las organizaciones',
      'All leagues': 'Todas las ligas',
      Registered: 'Registrado',
      Withdraw: 'Retirar',
      'Withdraw deadline passed': 'Plazo de retiro vencido',
      'Request Withdrawn': 'Solicitud retirada',
      'Something went wrong!': '¡Algo salió mal!',
      Withdrawal: 'Retiro',
      'Entry to withdraw': 'Entrada para retirar',
      'Withdrawal Reason': 'Razón de retiro',
      'Write your reason here': 'Escribe tu razón aquí',
      Confirm: 'Confirmar',
      'Please specify reason': 'Por favor especifica la razón',
      'AUDI - The Beach Tennis Championship': 'AUDI - El Campeonato de Tenis de Playa',
      'Get licence': 'Obtener licencia',
      'Additional questions': 'Preguntas adicionales',
      'Select duration': 'Seleccionar duración',
      Career: 'Carrera',
      'Third Party Licenes': 'Licencias de terceros',
      'User updated successfully': 'Usuario actualizado con éxito',
      'User Account': 'Cuenta de usuario',
      'We recommend an image that is 256 x 256 pixels': 'Recomendamos una imagen de 256 x 256 píxeles',
      'Enter Email Address': 'Ingresa la dirección de correo electrónico',
      'Enter name': 'Ingresa nombre',
      Surname: 'Apellido',
      'Enter surname': 'Ingresa apellido',
      'Contact phone number': 'Número de teléfono de contacto',
      'Enter phone number': 'Ingresa número de teléfono',
      'Date of Birth': 'Fecha de nacimiento',
      'Date of birth': 'Fecha de nacimiento',
      Gender: 'Género',
      'Select gender': 'Seleccionar género',
      'Representing country': 'País que representa',
      'Select country': 'Seleccionar país',
      City: 'Ciudad',
      'Select role(s)': 'Seleccionar rol(es)',
      Address: 'Dirección',
      'Enter address': 'Ingresa dirección',
      'Parent found': 'Padre encontrado',
      'Parent not found': 'Padre no encontrado',
      'Removed coach': 'Entrenador eliminado',
      'Failed to removed coach': 'No se pudo eliminar el entrenador',
      'Coach created': 'Entrenador creado',
      'Failed to create coach': 'No se pudo crear el entrenador',
      Club: 'Club',
      Save: 'Guardar',
      'Select club': 'Seleccionar club',
      Coach: 'Entrenador',
      'Select a coach': 'Seleccionar un entrenador',
      Parent: 'Padre',
      'Enter email': 'Ingresa correo electrónico',
      Biography: 'Biografía',
      'Write a bio': 'Escribe una biografía',
      CHECKBOXES: 'CUADROS DE VERIFICACIÓN',
      'Give access to coach to purchase licences and manage entries on your behalf': 'Dar acceso al entrenador para comprar licencias y gestionar inscripciones en tu nombre',
      'Give access to club manager(-es) to purchase licences and manage entries on your behalf': 'Dar acceso al administrador del club para comprar licencias y gestionar inscripciones en tu nombre',
      'Give access to parent(-s) to purchase licences and manage entries on your behalf': 'Dar acceso al padre para comprar licencias y gestionar inscripciones en tu nombre',
      Socials: 'Redes sociales',
      'Insert link here': 'Inserta enlace aquí',
      Facebook: 'Facebook',
      Instagram: 'Instagram',
      Linkedin: 'Linkedin',
      Tiktok: 'Tiktok',
      Youtube: 'Youtube',
      'Enter Id': 'Ingresa Id',
      'Password should be 8 chars minimum.': 'La contraseña debe tener un mínimo de 8 caracteres.',
      'Passwords must match': 'Las contraseñas deben coincidir',
      'Password changed successfully': 'Contraseña cambiada con éxito',
      'New Password': 'Nueva contraseña',
      'Re-Enter': 'Confirmar Contraseña',
      'Name is Required': 'El nombre es obligatorio',

      'Surname is Required': 'El apellido es obligatorio',
      'Re enter password': 'Reingresar contraseña',
      'User is already registered': 'El usuario ya está registrado',
      'Create an account': 'Crear una cuenta',
      'Already have an account?': '¿Ya tienes una cuenta?',
      'Log in': 'Iniciar sesión',
      'or create account': 'o crear cuenta',
      "I'm creating an account for a kid": 'Estoy creando una cuenta para un niño',
      'sign-up description':
        'Estás creando una cuenta para un participante del torneo. Si estás registrando a un niño, por favor crea un perfil para él/ella. Los perfiles de entrenador y padres estarán disponibles pronto, lo que te permitirá vincularte al perfil del niño.',
      'Email Address': 'Dirección de correo electrónico',
      Continue: 'Continuar',
      'Pick a sport': 'Elige un deporte',
      'Pick a sport that you play': 'Elige un deporte que practicas',
      'Gender is required': 'El género es obligatorio',
      'Phone is required': 'El teléfono es obligatorio',
      'Citizenship is required': 'Se requiere país',
      'Dob is required': 'La fecha de nacimiento es obligatoria',
      'FIDE ID Not found': 'ID de FIDE no encontrado',
      'FIDE ID found': 'ID de FIDE encontrado',
      'Tell us more about you': 'Cuéntanos más sobre ti',
      'More information about yourself': 'Más información sobre ti',
      'Enter FIDE ID': 'Ingresa ID de FIDE',
      'IPIN ID is correct': 'El ID de IPIN es correcto',
      'Your Licenses': 'Tus licencias',
      'Attach your licenses': 'Adjunta tus licencias',
      Skip: 'Saltar',
      'User registered successfully': 'Usuario registrado con éxito',
      'What is your primary role in the world of sports?': '¿Cuál es tu rol principal en el mundo del deporte?',
      'Register & Go to profile': 'Regístrate e ir al perfil',
      'PUBLISHED ON': 'PUBLICADO EL',
      'Federation member': 'Miembro de la federación',
      'Club owner': 'Propietario del club',
      'Club manager': 'Gerente del club',
      Referee: 'Árbitro',
      'Tournament organizer': 'Organizador de torneos',
      'Sport fan': 'Fanático del deporte',
      Other: 'Otro',
      PAY: 'PAGAR',
      '1 Year': '1 año',
      '2 Years': '2 años',
      '3 Years': '3 años',
      '4 Years': '4 años',
      'Registration time': 'Tiempo de registro',
      'In progress / Upcoming': 'En progreso / Próximos',
      'Matches won': 'Partidos ganados',
      'In progress / upcoming entries': 'Entradas en progreso / próximas',
      'In progress / upcoming tournaments': 'Torneos en progreso / próximos',
      'Past tournaments': 'Torneos pasados',
      'Past entries': 'Entradas pasadas',
      'Your password must be different to previous passwords': 'Tu contraseña debe ser diferente a las contraseñas anteriores',
      'less than a minute': 'menos de un minuto',
      read: 'leer',
      minute: 'minuto',
      'Select Licence': 'Seleccionar licencia',
      Calendar: 'calendario',
      Menu: 'Menú',
      'Manage tournament': 'Administrar torneo',
      'Partner search': 'Búsqueda de socios',
      'Partner Search': 'Búsqueda de socios',
      'Searching...': 'Buscando...',
      Apply: 'Aplicar',
      Delete: 'Eliminar',
      'Create application': 'Crear solicitud',
      'No partner search applications found': 'No se encontraron solicitudes de búsqueda de socios',
      'Log in to apply': 'Inicia sesión para aplicar',
      Player: 'Jugador',
      'Time of registration': 'Hora de registro',
      confirmed: 'confirmado',
      declined: 'rechazado',
      'payment pending': 'pago pendiente',
      WITHDRAW: 'RETIRARSE',
      'No other tournament found': 'No se encontró otro torneo',
      welcomeTextCustom:
        'está impulsado por Tournated, un software de gestión deportiva todo en uno que permite a las organizaciones deportivas gestionar, crecer y monetizar sus operaciones de manera eficiente. La solución permite a las organizaciones crear plataformas personalizadas en línea, automatizar y simplificar la gestión de ligas, clubes, torneos y otros eventos, aumentar el compromiso de la audiencia y procesar pagos sin esfuerzo.',
      welcomeText:
        'el resultado de cinco años de investigación y desarrollo en colaboración con más de 200 organizaciones, es una solución todo en uno innovadora, personalizable, fácil de usar y asequible para organizaciones deportivas de cualquier tamaño.',
      'Did you have an account in one of ': '¿Tenías una cuenta en una de ',
      those: 'esas',
      'platforms?': 'plataformas?',
      'Recover password': 'Recuperar contraseña',
      newUser: 'Soy un nuevo usuario. Regístrate',
      'Continue as a guest': 'Continuar como invitado',
      Support: 'Soporte',
      'Welcome to': 'Bienvenido a',
      'If you have an account on one of ': 'Si tienes una cuenta en una de ',
      these: 'estas',
      'platforms, log in with your e-mail or create a new profile': 'plataformas, inicia sesión con tu correo electrónico o crea un nuevo perfil',
      'Reset password': 'Restablecer contraseña',
      'Welcome to the': 'Bienvenido a la',
      platform: 'plataforma',
      'Powered by': 'Impulsado por',
      'All Categories': 'Todas las categorías',
      'Tournament Results': 'Resultados del torneo',
      versionText: 'La beta está activa. Por favor envíe sus comentarios',
      here: 'Aquí',
      'Full view': 'Vista completa',
      Timeline: 'Línea de tiempo',
      Grid: 'Red',
      "Bird's View": 'Vista de pájaro',
      'Third Party Licences': 'Licencias de terceros',
      Friend: 'Amiga',
      'Step 2': 'Paso 2:',
      'Step 3': 'Paso 3:',
      'Your Details': 'Tus detalles',
      'Partner Details': 'Detalles del socio',
      'Enter partner email': 'Ingrese el correo electrónico del socio',
      'Register & Pay': 'Regístrate y Paga',
      'On-Site': 'En el Sitio',
      Now: 'Ahora',
    },
  },
  ru: {
    translation: {
      maintenanceMessage: 'В настоящее время мы обновляем платформу, это может занять несколько минут. Пожалуйста, попробуйте еще раз через некоторое время.',
      networkErrorMessage: 'Пожалуйста, проверьте ваше сетевое соединение и попробуйте снова.',
      errorTitle: 'Упс! Что-то пошло не так',
      registrationOpen: 'Регистрация открыта',
      registrationClosed: 'Регистрация закрыта',
      live: 'Начался',
      header: {
        discover: 'Найдите',
        enterName: 'Введите имя',
        sportsManager: 'Спортивный менеджер?',
        learnHow: 'Узнайте как',
        accelerate: 'мы можем ускорить рост вашей организации.',
        powering: 'Наши клиенты',
        tournaments: 'турниры,',
        leagues: 'лиги',
        organizations: 'организации',
        clubs: 'клубы',
        coaches: 'тренеров',
        placeholder: {
          tournaments: 'Введите название турнира',
          leagues: 'Введите название лиги',
          organizations: 'Введите название организации',
          clubs: 'Введите название клуба',
          coaches: 'Введите имя тренера',
        },
      },
      read_more: 'Читать дальше',
      Close: 'Закрыть',
      bannerHeading: 'Начните управлять своими турнирами абсолютно бесплатно',
      bannerDescription: 'От управления заявками до автоматической генерации групп, жеребьевки и расписания игр, управления результатами и многого другого',
      startNow: 'Начать сейчас',
      featureTournaments: 'Избранные турниры',
      tournamentNotFound: 'Избранные турниры не найдены',
      viewAll: 'Смотреть все',
      featureLeague: 'Избранные лиги',
      leaguesNotFound: 'Избранные лиги не найдены',
      Tournaments: 'Турниры',
      NoTournamentsFound: 'Турниры не найдены',
      Rankings: 'Рейтинги',
      noRankingFound: 'Рейтинги не найдены',
      news: 'Новости',
      noNewsFound: 'Новости не найдены',
      partners: 'Наши партнеры',
      noPartnersFound: 'Партнеры не найдены',
      worldwide: 'По всему миру',
      search: 'Поиск',
      pricing: 'Цены',
      loginRegister: 'Войти / Зарегистрироваться',
      home: 'Главная',
      organizations: 'Организации',
      leagues: 'Лиги',
      teams: 'Команды',
      clubs: 'Клубы',
      coaches: 'Тренеры',
      tennis: 'Теннис',
      beachTenis: 'Пляжный теннис',
      'Beach Volley': 'Пляжный волейбол',
      'Sport Fishing': 'Спортивная рыбалка',
      Volleyball: 'Волейбол',
      Sports: 'Виды спорта',
      RESOURSES: 'РЕСУРСЫ',
      Feature: 'Избранное',
      'Create account': 'Создать аккаунт',
      Login: 'Войти',
      Packages: 'Пакеты',
      'Package one': 'Пакет один',
      'Package two': 'Пакет два',
      'Package three': 'Пакет три',
      'Package Four': 'Пакет четыре',
      'Customer suppor': 'Поддержка клиентов',
      FAQ: 'ЧАВО',
      Documentation: 'Документация',
      'Video tutorials': 'Видеоуроки',
      'Submit ticket': 'Отправить заявку',
      'Contact us': 'Связаться с нами',
      'Our contacts': 'Наши контакты',
      Padel: 'Падел',
      Pickleball: 'Пиклбол',
      'FEATURED LEAGUES': 'ИЗБРАННЫЕ ЛИГИ',
      View: 'Просмотр',
      'No leagues found': 'Лиги не найдены',
      Athletes: 'Спортсмены',
      'Managed by': 'Управляется',
      'View League': 'Посмотреть лигу',
      Type: 'Тип',
      National: 'Национальный',
      International: 'Международный',
      Local: 'Местный',
      Period: 'Период',
      League: 'Лига',
      Country: 'Страна',
      Year: 'Год',
      Points: 'Очки',
      Position: 'Позиция',
      Team: 'Команда',
      'No teams found': 'Команды не найдены',
      'Team Members': 'Члены команды',
      Sport: 'Вид спорта',
      'No clubs found': 'Клубы не найдены',
      Experience: 'Опыт',
      Students: 'Студенты',
      Author: 'Автор',
      Category: 'Категория',
      'My Entries': 'Мои записи',
      'My Leagues': 'Мои лиги',
      'My Licences': 'Мои лицензии',
      'My Teams': 'Мои команды',
      'Get Licence': 'Получить лицензию',
      demo: 'демо',
      Settings: 'Настройки',
      'Log out': 'Выйти',
      'Dark view': 'Тёмная тема',
      Manager: 'Менеджер',
      Tournament: 'Турнир',
      Cost: 'Стоимость',
      Duration: 'Продолжительность',
      'Purchase on': 'Куплено',
      'No license found': 'Лицензия не найдена',
      Additional: 'Дополнительно',
      Status: 'Статус',
      Date: 'Дата',
      'No entries found': 'Записи не найдены',
      'My Licenses': 'Мои лицензии',
      'Add new License': 'Добавить новую лицензию',
      'Create Team': 'Создать команду',
      Info: 'Информация',
      infoDes: 'Для участия в определённых лигах / турах игрок должен иметь соответствующую активную лицензию.',
      'Select League': 'Выбрать лигу',
      'Select License': 'Выбрать лицензию',
      'Promo code': 'Промокод',
      'I agree to the': 'Я согласен с',
      'Terms and Conditions': 'Условиями и положениями',
      Price: 'Цена',
      'Confirm Payment': 'Подтвердить оплату',
      History: 'История',
      'Tournated Manager': 'Менеджер турниров',
      'Tournated Custom': 'Кастомизация турниров',
      'Customer support': 'Поддержка клиентов',
      Categories: 'Категории',
      Matches: 'Матчи',
      'Organized by': 'Организатор',
      Register: 'Зарегистрироваться',
      Entries: 'Записи',
      Location: 'Местоположение',
      'National Tour': 'Национальный тур',
      '4 mins read': 'Чтение 4 минуты',
      'Age group': 'Возрастная группа',
      'Skills level': 'Уровень навыков',
      someDes: 'SIA Vertexo © 2020. Все права защищены',
      'Manage in CMS': 'Управлять в CMS',
      'Fact Sheet': 'Информационный лист',
      General: 'Общее',
      Participants: 'Участники',
      Groups: 'Группы',
      Draws: 'Жеребьевка',
      'Live Standings': 'Текущие результаты',
      'Order of play': 'Расписание игр',
      Pairings: 'Пары',
      Standings: 'Результаты',
      'Final standings': 'Итоговые результаты',
      'List & Results': 'Список и результаты',
      'Entry Fee': 'Вступительный взнос',
      'Entry Deadline': 'Крайний срок подачи заявок',
      'Withdrawal deadline': 'Крайний срок отмены',
      'Start date': 'Дата начала',
      'End date': 'Дата окончания',
      and: 'и',
      others: 'другие',
      'are confirmed': 'подтверждены',
      Description: 'Описание',
      'Tournament Poster': 'Постер турнира',
      'More Info': 'Больше информации',
      'Additional materials about tournament': 'Дополнительные материалы о турнире',
      Regulations: 'Правила',
      'Tournaments Discord': 'Discord турниров',
      discordDes: 'Общайтесь напрямую с администраторами турниров, задавайте вопросы и общайтесь с другими игроками!',
      'Join Channel': 'Присоединиться к каналу',
      'Fact sheet': 'Информационный лист',
      'Search hotels and more': 'Ищите отели и многое другое...',
      Destination: 'Пункт назначения',
      'Select Destination': 'Выберите пункт назначения',
      'Check in date': 'Дата заезда',
      'Check out date': 'Дата выезда',
      'Best players on this tournament': 'Лучшие игроки этого турнира',
      Gallery: 'Галерея',
      'Recent photos': 'Недавние фотографии',
      'Buy licence': 'Купить лицензию',
      'Select All': 'Выбрать все',
      "Don't see your sport? Contact us": 'Не видите свой спорт? Свяжитесь с нами',
      'Description is missing': 'Описание отсутствует',
      day: 'день',
      days: 'дни',
      Organization: 'Организация',
      Athlete: 'Спортсмен',
      'Birth year': 'Год рождения',
      Joined: 'Присоединился',
      'Select Sport': 'Выберите вид спорта',
      'Select organization': 'Выберите организацию',
      'Select league': 'Выберите лигу',
      Overview: 'Обзор',
      About: 'О',
      'Win rate': 'Процент побед',
      Achievements: 'Достижения',
      'Upcoming Matches': 'Предстоящие матчи',
      'Past Matches': 'Прошедшие матчи',
      'Upcoming Tournaments': 'Предстоящие турниры',
      'Purchase history': 'История покупок',
      'description and detail': 'Улучшение: покупайте лицензии / участия, необходимые для участия в определённых лигах или турнирах',
      'Purchase datetime': 'Дата и время покупки',
      'Payment Status': 'Статус платежа',
      Licence: 'Лицензия',
      Members: 'Участники',
      Student: 'Студент',
      'Personal page': 'Личная страница',
      'My Career': 'Моя карьера',
      'Latest tournaments': 'Последние турниры',
      'Upcoming tournaments': 'Предстоящие турниры',
      'Entry deadline': 'Срок подачи заявок',
      Calendar: 'календарь',
      Menu: 'Меню',
      'Manage tournament': 'Управление турниром',
      'Partner search': 'Поиск партнера',
      'Partner Search': 'Поиск партнера',
      'Searching...': 'Поиск...',
      Apply: 'Применить',
      Delete: 'Удалить',
      'Create application': 'Создать заявку',
      'No partner search applications found': 'Заявки на поиск партнера не найдены',
      'Log in to apply': 'Войдите, чтобы подать заявку',
      Player: 'Игрок',
      'Time of registration': 'Время регистрации',
      confirmed: 'подтверждено',
      declined: 'отклонено',
      'payment pending': 'ожидание оплаты',
      WITHDRAW: 'ОТКАЗАТЬСЯ',
      'No other tournament found': 'Другие турниры не найдены',
      welcomeTextCustom:
        'работает на Tournated, всеобъемлющем программном обеспечении для управления спортом, которое позволяет спортивным организациям эффективно управлять, развивать и монетизировать свою деятельность. Решение позволяет организациям создавать настраиваемые онлайн-платформы, автоматизировать и упрощать управление лигами, клубами, турнирами и другими мероприятиями, увеличивать вовлеченность аудитории и легко обрабатывать платежи.',
      welcomeText:
        'результат пятилетних исследований и разработок в сотрудничестве с более чем 200 организациями – это инновационное, настраиваемое, удобное и доступное решение «все в одном» для спортивных организаций любого размера.',
      'Did you have an account in one of ': 'У вас был аккаунт на одной из ',
      those: 'этих',
      'platforms?': 'платформ?',
      'Recover password': 'Восстановить пароль',
      newUser: 'Я новый пользователь. Регистрация',
      'Continue as a guest': 'Продолжить как гость',
      Support: 'Поддержка',
      'Welcome to': 'Добро пожаловать в',
      'If you have an account on one of ': 'Если у вас есть аккаунт на одной из ',
      these: 'этих',
      'platforms, log in with your e-mail or create a new profile': 'платформ, войдите с вашим e-mail или создайте новый профиль',
      'Reset password': 'Сбросить пароль',
      'Welcome to the': 'Добро пожаловать на',
      platform: 'платформу',
      'Powered by': 'Работает на',
      'All Categories': 'Все категории',
      'Tournament Results': 'Результаты турнира',
      versionText: 'Бета уже доступна. Пожалуйста, отправьте свой отзыв',
      here: 'Здесь',
      'Full view': 'Полный просмотр',
      Timeline: 'Хронология',
      Grid: 'Сетка',
      "Bird's View": 'с высоты птичьего полета',
      'Third Party Licences': 'Лицензии третьих лиц',
      Friend: 'Друг',
      'Step 2': 'Шаг 2:',
      'Step 3': 'Шаг 3:',
      'Your Details': 'Ваши данные',
      'Partner Details': 'Подробности о партнере',
      'Enter partner email': 'Введите адрес электронной почты партнера',
      'Register & Pay': 'Зарегистрироваться и Оплатить',
      'On-Site': 'На Месте',
      Now: 'Сейчас',
    },
  },
  pl: {
    translation: {
      maintenanceMessage: 'Obecnie aktualizujemy platformę, co może potrwać kilka minut. Proszę spróbować ponownie za chwilę.',
      networkErrorMessage: 'Proszę sprawdzić swoje połączenie sieciowe i spróbować ponownie.',
      errorTitle: 'Ups! Coś jest nie tak!',
      header: {
        discover: 'Odkryj',
        enterName: 'Wprowadź',
        sportsManager: 'Menedżer sportowy?',
        learnHow: 'Dowiedz się,',
        accelerate: 'jak możemy przyspieszyć rozwój twojej organizacji.',
        powering: 'Nasi klienci',
        tournaments: 'turnieje,',
        leagues: 'ligi',
        organizations: 'organizacje',
        clubs: 'kluby',
        coaches: 'trenerzy',
        placeholder: {
          tournaments: 'Wprowadź nazwę turnieju',
          leagues: 'Wprowadź nazwę ligi',
          organizations: 'Wprowadź nazwę organizacji',
          clubs: 'Wprowadź nazwę klubu',
          coaches: 'Wprowadź imię trenera',
        },
      },
      registrationOpen: 'Rejestracja otwarta',
      registrationClosed: 'Rejestracja zamknięta',
      live: 'Rozpoczęto',
      read_more: 'Czytaj więcej',
      Close: 'Zamknij',
      bannerHeading: 'Zacznij zarządzać swoimi turniejami całkowicie za darmo',
      bannerDescription: 'Od zarządzania zgłoszeniami po automatyczne generowanie grup, losowań i harmonogramów, zarządzanie wynikami i wiele więcej',
      startNow: 'Rozpocznij teraz',
      featureTournaments: 'Polecane turnieje',
      tournamentNotFound: 'Nie znaleziono polecanych turniejów',
      viewAll: 'Zobacz wszystkie',
      featureLeague: 'Polecane ligi',
      leaguesNotFound: 'Nie znaleziono polecanych lig',
      Tournaments: 'Turnieje',
      NoTournamentsFound: 'Nie znaleziono turniejów',
      Rankings: 'Rankingi',
      noRankingFound: 'Nie znaleziono rankingów',
      news: 'Aktualności',
      noNewsFound: 'Nie znaleziono wiadomości',
      partners: 'Nasi partnerzy',
      Partners: 'Partnerzy',
      noPartnersFound: 'Nie znaleziono partnerów',
      worldwide: 'Światowe',
      search: 'Szukaj',
      pricing: 'Cennik',
      loginRegister: 'Zaloguj się / Zarejestruj',
      home: 'Strona główna',
      organizations: 'Organizacje',
      leagues: 'Ligi',
      teams: 'Drużyny',
      clubs: 'Kluby',
      coaches: 'Trenerzy',
      tennis: 'Tenis',
      beachTenis: 'Tenis plażowy',
      'Beach Volley': 'Siatkówka plażowa',
      'Sport Fishing': 'Wędkarstwo sportowe',
      Volleyball: 'Siatkówka',
      Sports: 'Sporty',
      RESOURSES: 'ZASOBY',
      Feature: 'Funkcja',
      'Create account': 'Załóż konto',
      Login: 'Zaloguj się',
      Packages: 'Pakiety',
      'Package one': 'Pakiet jeden',
      'Package two': 'Pakiet dwa',
      'Package three': 'Pakiet trzy',
      'Package Four': 'Pakiet cztery',
      'Customer suppor': 'Wsparcie klienta',
      FAQ: 'FAQ',
      Documentation: 'Dokumentacja',
      'Video tutorials': 'Samouczki wideo',
      'Submit ticket': 'Zgłoś problem',
      'Contact us': 'Skontaktuj się z nami',
      'Our contacts': 'Nasze kontakty',
      Padel: 'Padel',
      Pickleball: 'Pickleball',
      'FEATURED LEAGUES': 'POLECANE LIGI',
      View: 'Zobacz',
      'No leagues found': 'Nie znaleziono lig',
      Athletes: 'Atleci',
      'Managed by': 'Zarządzany przez',
      'View League': 'Zobacz ligę',
      Type: 'Rodzaj',
      National: 'Narodowy',
      International: 'Międzynarodowy',
      Local: 'Lokalny',
      Period: 'Okres',
      League: 'Liga',
      Country: 'Kraj',
      Year: 'Rok',
      Points: 'Punkty',
      Position: 'Pozycja',
      Team: 'Drużyna',
      'No teams found': 'Nie znaleziono drużyn',
      'Team Members': 'Członkowie drużyny',
      Sport: 'Sport',
      'No clubs found': 'Nie znaleziono klubów',
      Experience: 'Doświadczenie',
      Students: 'Uczniowie',
      Author: 'Autor',
      Category: 'Kategoria',
      'My Entries': 'Moje zgłoszenia',
      'My Leagues': 'Moje ligi',
      'My Licences': 'Moje licencje',
      'My Teams': 'Moje drużyny',
      'Get Licence': 'Uzyskaj licencję',
      demo: 'demo',
      Settings: 'Ustawienia',
      'Log out': 'Wyloguj się',
      'Dark view': 'Ciemny widok',
      Manager: 'Menedżer',
      Tournament: 'Turniej',
      Cost: 'Koszt',
      Duration: 'Czas trwania',
      'Purchase on': 'Zakup na',
      'No license found': 'Nie znaleziono licencji',
      Additional: 'Dodatkowe',
      Status: 'Status',
      Date: 'Data',
      'No entries found': 'Nie znaleziono zgłoszeń',
      'My Licenses': 'Moje licencje',
      'Add new License': 'Dodaj nową licencję',
      'Create Team': 'Utwórz drużynę',
      Info: 'Informacje',
      infoDes: 'Aby wziąć udział w niektórych ligach/turach, gracz musi mieć odpowiednią aktywną licencję.',
      'Select League': 'Wybierz ligę',
      'Select License': 'Wybierz licencję',
      'Promo code': 'Kod promocyjny',
      'I agree to the': 'Zgadzam się na',
      'Terms and Conditions': 'Regulamin',
      Price: 'Cena',
      'Confirm Payment': 'Potwierdź płatność',
      History: 'Historia',
      'Tournated Manager': 'Menedżer Tournated',
      'Tournated Custom': 'Niestandardowy Tournated',
      'Customer support': 'WSPARCIE KLIENTA',
      Categories: 'Kategorie',
      Matches: 'Mecze',
      'Organized by': 'Zorganizowany przez',
      Register: 'Zarejestruj się',
      Entries: 'Zgłoszenia',
      Location: 'Lokalizacja',
      'National Tour': 'Narodowa trasa',
      '4 mins read': '4 minuty czytania',
      'Age group': 'Grupa wiekowa',
      'Skills level': 'Poziom umiejętności',
      someDes: 'SIA Vertexo © 2020. Wszelkie prawa zastrzeżone',
      'Manage in CMS': 'Zarządzaj w CMS',
      'Fact Sheet': 'Karta informacyjna',
      General: 'Ogólne',
      Participants: 'Uczestnicy',
      Groups: 'Grupy',
      Draws: 'Losowania',
      'Live Standings': 'Na żywo',
      'Order of play': 'Porządek gry',
      Pairings: 'Parowanie',
      Standings: 'Klasyfikacja',
      'Final standings': 'Ostateczna klasyfikacja',
      'List & Results': 'Lista i wyniki',
      'Entry Fee': 'Opłata za zgłoszenie',
      'Entry Deadline': 'Termin zgłoszeń',
      'Entry deadline': 'Termin zgłoszeń',
      'Withdrawal deadline': 'Termin wycofania',
      'Start date': 'Data rozpoczęcia',
      'End date': 'Data zakończenia',
      and: 'i',
      others: 'inni',
      'are confirmed': 'są potwierdzeni',
      Description: 'Opis',
      'Tournament Poster': 'Plakat turniejowy',
      'More Info': 'Więcej informacji',
      'Additional materials about tournament': 'Dodatkowe materiały dotyczące turnieju',
      Regulations: 'Regulamin',
      'Tournaments Discord': 'Turnieje Discord',
      discordDes: 'Rozmawiaj bezpośrednio z administratorami turnieju, zadawaj pytania i rozmawiaj z innymi graczami!',
      'Join Channel': 'Dołącz do kanału',
      'Fact sheet': 'Karta informacyjna',
      'Search hotels and more': 'Szukaj hoteli i więcej...',
      Destination: 'Cel podróży',
      'Select Destination': 'Wybierz cel podróży',
      'Check in date': 'Data zameldowania',
      'Check out date': 'Data wymeldowania',
      'Best players on this tournament': 'Najlepsi gracze na tym turnieju',
      Gallery: 'Galeria',
      'Recent photos': 'Najnowsze zdjęcia',
      'Buy licence': 'Kup licencję',
      'Select All': 'Wybierz wszystko',
      "Don't see your sport? Contact us": 'Nie widzisz swojego sportu? Skontaktuj się z nami',
      'Description is missing': 'Brak opisu',
      day: 'dzień',
      days: 'dni',
      Organization: 'Organizacja',
      Athlete: 'Zawodnik',
      'Birth year': 'Rok urodzenia',
      Joined: 'Dołączył',
      'Select Sport': 'Wybierz sport',
      'Select organization': 'Wybierz organizację',
      'Select league': 'Wybierz ligę',
      Overview: 'Przegląd',
      About: 'O',
      'Win rate': 'Procent zwycięstw',
      Achievements: 'Osiągnięcia',
      'Upcoming Matches': 'Nadchodzące mecze',
      'Past Matches': 'Zakończone mecze',
      'Upcoming Tournaments': 'Nadchodzące turnieje',
      'Purchase history': 'Historia zakupów',
      'description and detail': 'Popraw: Uzyskaj licencje/członkostwa wymagane dla uczestników w określonych ligach lub turniejach',
      'Purchase datetime': 'Data i godzina zakupu',
      'Payment Status': 'Status płatności',
      Licence: 'Licencja',
      Members: 'Członkowie',
      Student: 'Uczeń',
      'Personal page': 'Strona osobista',
      'My Career': 'Moja kariera',
      'Latest tournaments': 'Najnowsze turnieje',
      'Upcoming tournaments': 'Nadchodzące turnieje',
      'No members found': 'Nie znaleziono członków',
      'No tournaments found': 'Nie znaleziono turniejów',
      'No matches found': 'Nie znaleziono meczów',
      'No rankings found': 'Nie znaleziono rankingów',
      'No past matches found': 'Nie znaleziono zakończonych meczów',
      'No upcoming matches found': 'Nie znaleziono nadchodzących meczów',
      'No achievements found': 'Nie znaleziono osiągnięć',
      Documents: 'Dokumenty',
      Contact: 'Kontakt',
      'No photos added': 'Nie dodano zdjęć',
      'Manage league': 'Zarządzaj ligą',
      'No documents added': 'Nie dodano dokumentów',
      Email: 'Email',
      Phone: 'Telefon',
      Website: 'Strona internetowa',
      Place: 'Miejsce',
      'Tournament Name': 'Nazwa turnieju',
      'Manage organization': 'Zarządzaj organizacją',
      'Terms & Conditions': 'Warunki i zasady',
      LEGAL: 'PRAWNE',
      'Privacy policy': 'Polityka prywatności',
      'Cancellation policy': 'Polityka anulowania',
      GDPR: 'RODO',
      'All Rights Reserved': 'Wszelkie prawa zastrzeżone',
      'League [Category]': 'Liga [Kategoria]',
      'Other tournaments': 'Inne turnieje',
      'Points Details': 'Szczegóły punktów',
      'International rating': 'Międzynarodowy ranking',
      Day: 'Dzień',
      Days: 'Dni',
      Tennis: 'Tenis',
      'Beach Tennis': 'Tenis plażowy',
      'Beach Volleyball': 'Siatkówka plażowa',
      Chess: 'Szachy',
      'In progress': 'W trakcie',
      Registration: 'Rejestracja',
      'Deadline past': 'Termin minął',
      Completed: 'Zakończone',
      'No points yet': 'Brak punktów',
      'is confirmed': 'jest potwierdzony',
      Contacts: 'Kontakty',
      Organizer: 'Organizator',
      'Featured athletes': 'Polecani zawodnicy',
      'Top-ranked participants of the tournament': 'Najlepsi zawodnicy turnieju',
      NR: 'NR',
      PLAYER: 'ZAWODNIK',
      CITY: 'MIASTO',
      RATING: 'RANKING',
      CLUB: 'KLUB',
      TOTAL: 'ŁĄCZNIE',
      SEGMENT: 'SEGMENT',
      SEED: 'ROZSTAWIENIE',
      Search: 'Szukaj',
      'No participants found': 'Nie znaleziono uczestników',
      Total: 'Łącznie',
      Seed: 'Rozstawienie',
      Rating: 'Ranking',
      Rank: 'Miejsce',
      'Category:': 'Kategoria:',
      'No category found': 'Nie znaleziono kategorii',
      'No Rounds found': 'Nie znaleziono rund',
      White: 'Biały',
      Black: 'Czarny',
      Name: 'Nazwa',
      Pts: 'Pkt',
      Score: 'Wynik',
      'There is no available pairings!': 'Brak dostępnych parowań!',
      'Generating PDF...': 'Generowanie PDF...',
      'PDF Generated Successfully!': 'PDF wygenerowany pomyślnie!',
      'PDF Downloaded Successfully!': 'PDF pobrany pomyślnie!',
      'Downloading...': 'Pobieranie...',
      'Pairing Standings': 'Klasyfikacja parowań',
      Opponents: 'Przeciwnicy',
      BHC1: 'BHC1',
      BHC: 'BHC',
      BGP: 'BGP',
      WIN: 'WYGRANA',
      'There is no available entries!': 'Brak dostępnych zgłoszeń!',
      "I'm a coach/parent": 'Jestem trenerem/rodzicem',
      'Step 1': 'Krok 1',
      'Select category': 'Wybierz kategorię',
      Request: 'Żądanie',
      'Register & Pay Now': 'Zarejestruj się i zapłać teraz',
      'Register & Pay On-Site': 'Zarejestruj się i zapłać na miejscu',
      'Terms And Conditions': 'Warunki i zasady',
      'Latest Winners': 'Najnowsi zwycięzcy',
      'No winners found': 'Nie znaleziono zwycięzców',
      'No news found': 'Nie znaleziono wiadomości',
      'No partners found': 'Nie znaleziono partnerów',
      'Skills Levels': 'Poziomy umiejętności',
      'To get register for the tournament you must have active tour licence.': 'Aby zarejestrować się na turniej, musisz mieć aktywną licencję na trasę.',
      'Contact Details': 'Dane kontaktowe',
      'Our Partners': 'Nasi partnerzy',
      'View all': 'Zobacz wszystkie',
      Member: 'Członek',
      'Team Name': 'Nazwa drużyny',
      News: 'Aktualności',
      'Men Pro': 'Mężczyźni Pro',
      'Latvia Tennis Union League': 'Liga Związku Tenisowego Łotwy',
      Leagues: 'Ligi',
      'No league found': 'Nie znaleziono ligi',
      'Home Page': 'Strona główna',
      Upcoming: 'Nadchodzące',
      'This month': 'W tym miesiącu',
      'Last 7 days': 'Ostatnie 7 dni',
      'Last 30 days': 'Ostatnie 30 dni',
      Hours: 'Godziny',
      Minutes: 'Minuty',
      Teams: 'Drużyny',
      'No student found': 'Nie znaleziono ucznia',
      'Other news': 'Inne wiadomości',
      'Don’t have an account?': 'Nie masz konta?',
      'Sign up': 'Zarejestruj się',
      'or log in': 'lub zaloguj się',
      'Did you forget your password?': 'Zapomniałeś hasła?',
      'Must be a valid email': 'Musi to być prawidłowy adres e-mail',
      'Email is required': 'E-mail jest wymagany',
      'Password is required': 'Hasło jest wymagane',
      Password: 'Hasło',
      'Remember me': 'Zapamiętaj mnie',
      'Log In': 'Zaloguj się',
      'Unauthorized , please sign in': 'Nieautoryzowany, proszę się zalogować',
      'Reset your password': 'Zresetuj hasło',
      'Enter the e-mail address': 'Wprowadź adres e-mail',
      'Enter your e-mail address with which you are registered': 'Wprowadź adres e-mail, którym jesteś zarejestrowany',
      'Please check your email for reset password link!': 'Proszę sprawdzić e-mail w celu uzyskania linku do resetowania hasła!',
      'Send instructions': 'Wyślij instrukcje',
      'My Licenes': 'Moje licencje',
      'My leagues': 'Moje ligi',
      'My tournaments': 'Moje turnieje',
      'My matches': 'Moje mecze',
      'My entries': 'Moje zgłoszenia',
      'My rankings': 'Moje rankingi',
      Stats: 'Statystyki',
      'Leagues took part in': 'Ligi, w których uczestniczono',
      'Active licences': 'Aktywne licencje',
      Titles: 'Tytuły',
      'Matches played': 'Rozegrane mecze',
      'Matches lost': 'Przegrane mecze',
      'Games/points played': 'Rozegrane gry/punkty',
      'All sports': 'Wszystkie sporty',
      'All organizations': 'Wszystkie organizacje',
      'All leagues': 'Wszystkie ligi',
      Registered: 'Zarejestrowany',
      Withdraw: 'Wycofaj się',
      'Withdraw deadline passed': 'Termin wycofania minął',
      'Request Withdrawn': 'Zgłoszenie wycofane',
      'Something went wrong!': 'Coś poszło nie tak!',
      Withdrawal: 'Wycofanie',
      'Entry to withdraw': 'Zgłoszenie do wycofania',
      'Withdrawal Reason': 'Powód wycofania',
      'Write your reason here': 'Napisz swój powód tutaj',
      Confirm: 'Potwierdź',
      'Please specify reason': 'Proszę podać powód',
      'AUDI - The Beach Tennis Championship': 'AUDI - Mistrzostwa Tenisa Plażowego',
      'Get licence': 'Uzyskaj licencję',
      'Additional questions': 'Dodatkowe pytania',
      'Select duration': 'Wybierz czas trwania',
      Career: 'Kariera',
      'Third Party Licenes': 'Licencje stron trzecich',
      'User updated successfully': 'Użytkownik zaktualizowany pomyślnie',
      'User Account': 'Konto użytkownika',
      'We recommend an image that is 256 x 256 pixels': 'Zalecamy obraz o rozdzielczości 256 x 256 pikseli',
      'Enter Email Address': 'Wprowadź adres e-mail',
      'Enter name': 'Wprowadź nazwę',
      Surname: 'Nazwisko',
      'Enter surname': 'Wprowadź nazwisko',
      'Contact phone number': 'Kontaktowy numer telefonu',
      'Enter phone number': 'Wprowadź numer telefonu',
      'Date of Birth': 'Data urodzenia',
      'Date of birth': 'Data urodzenia',
      Gender: 'Płeć',
      'Select gender': 'Wybierz płeć',
      'Representing country': 'Kraj reprezentujący',
      'Select country': 'Wybierz kraj',
      City: 'Miasto',
      'Select role(s)': 'Wybierz rolę(e)',
      Address: 'Adres',
      'Enter address': 'Wprowadź adres',
      'Parent found': 'Rodzic znaleziony',
      'Parent not found': 'Rodzic nie znaleziony',
      'Removed coach': 'Usunięty trener',
      'Failed to removed coach': 'Nie udało się usunąć trenera',
      'Coach created': 'Stworzony trener',
      'Failed to create coach': 'Nie udało się stworzyć trenera',
      Club: 'Klub',
      Save: 'Zapisz',
      'Select club': 'Wybierz klub',
      Coach: 'Trener',
      'Select a coach': 'Wybierz trenera',
      Parent: 'Rodzic',
      'Enter email': 'Wprowadź e-mail',
      Biography: 'Biografia',
      'Write a bio': 'Napisz biografię',
      CHECKBOXES: 'PÓLKA',
      'Give access to coach to purchase licences and manage entries on your behalf': 'Daj dostęp trenerowi do zakupu licencji i zarządzania zgłoszeniami w twoim imieniu',
      'Give access to club manager(-es) to purchase licences and manage entries on your behalf': 'Daj dostęp menedżerowi klubu do zakupu licencji i zarządzania zgłoszeniami w twoim imieniu',
      'Give access to parent(-s) to purchase licences and manage entries on your behalf': 'Daj dostęp rodzicowi(-om) do zakupu licencji i zarządzania zgłoszeniami w twoim imieniu',
      Socials: 'Społeczności',
      'Insert link here': 'Wstaw link tutaj',
      Facebook: 'Facebook',
      Instagram: 'Instagram',
      Linkedin: 'Linkedin',
      Tiktok: 'Tiktok',
      Youtube: 'Youtube',
      'Enter Id': 'Wprowadź Id',
      'Password should be 8 chars minimum.': 'Hasło powinno mieć minimum 8 znaków.',
      'Passwords must match': 'Hasła muszą się zgadzać',
      'Password changed successfully': 'Hasło zmienione pomyślnie',
      'New Password': 'Nowe hasło',
      'Re-Enter': 'Wprowadź ponownie',
      'Name is Required': 'Imię jest wymagane',
      'Surname is Required': 'Nazwisko jest wymagane',
      'Re enter password': 'Wprowadź ponownie hasło',
      'User is already registered': 'Użytkownik jest już zarejestrowany',
      'Create an account': 'Utwórz konto',
      'Already have an account?': 'Masz już konto?',
      'Log in': 'Zaloguj się',
      'or create account': 'lub utwórz konto',
      "I'm creating an account for a kid": 'Tworzę konto dla dziecka',
      'sign-up description':
        'Tworzysz konto dla uczestnika turnieju. Jeśli rejestrujesz dziecko, proszę utworzyć profil dla niego/jej. Profile trenera i rodzica będą dostępne wkrótce, umożliwiając połączenie z profilem dziecka.',
      'Email Address': 'Adres e-mail',
      Continue: 'Kontynuuj',
      'Pick a sport': 'Wybierz sport',
      'Pick a sport that you play': 'Wybierz sport, który uprawiasz',
      'Gender is required': 'Płeć jest wymagana',
      'Phone is required': 'Telefon jest wymagany',
      'Citizenship is required': 'Kraj jest wymagany',
      'Dob is required': 'Data urodzenia jest wymagana',
      'FIDE ID Not found': 'FIDE ID nie znaleziono',
      'FIDE ID found': 'FIDE ID znaleziono',
      'Tell us more about you': 'Powiedz nam więcej o sobie',
      'More information about yourself': 'Więcej informacji o sobie',
      'Enter FIDE ID': 'Wprowadź FIDE ID',
      'IPIN ID is correct': 'IPIN ID jest poprawny',
      'Your Licenses': 'Twoje licencje',
      'Attach your licenses': 'Dołącz swoje licencje',
      Skip: 'Pomiń',
      'User registered successfully': 'Użytkownik zarejestrowany pomyślnie',
      'What is your primary role in the world of sports?': 'Jaka jest twoja główna rola w świecie sportu?',
      'Register & Go to profile': 'Zarejestruj się i przejdź do profilu',
      'PUBLISHED ON': 'OPUBLIKOWANE',
      'Federation member': 'Członek federacji',
      'Club owner': 'Właściciel klubu',
      'Club manager': 'Menedżer klubu',
      Referee: 'Sędzia',
      'Tournament organizer': 'Organizator turnieju',
      'Sport fan': 'Fan sportu',
      Other: 'Inne',
      PAY: 'ZAPŁAĆ',
      '1 Year': '1 rok',
      '2 Years': '2 lata',
      '3 Years': '3 lata',
      '4 Years': '4 lata',
      'Registration time': 'Czas rejestracji',
      'In progress / Upcoming': 'W trakcie / Nadchodzące',
      'Matches won': 'Wygrane mecze',
      'In progress / upcoming entries': 'W trakcie / nadchodzące zgłoszenia',
      'In progress / upcoming tournaments': 'W trakcie / nadchodzące turnieje',
      'Past tournaments': 'Przeszłe turnieje',
      'Past entries': 'Przeszłe zgłoszenia',
      'Your password must be different to previous passwords': 'Twoje hasło musi różnić się od poprzednich haseł',
      'less than a minute': 'mniej niż minuta',
      read: 'czytanie',
      minute: 'minuta',
      'Select Licence': 'Wybierz licencję',
      'Start time': 'Czas rozpoczęcia',
      'Try again': 'Spróbuj ponownie',
      Calendar: 'kalendarz',
      Menu: 'Menu',
      'Manage tournament': 'Zarządzaj turniejem',
      'Partner search': 'Wyszukiwanie partnera',
      'Partner Search': 'Wyszukiwanie partnera',
      'Searching...': 'Szukam...',
      Apply: 'Zastosuj',
      Delete: 'Usuń',
      'Create application': 'Utwórz aplikację',
      'No partner search applications found': 'Nie znaleziono aplikacji do wyszukiwania partnerów',
      'Log in to apply': 'Zaloguj się, aby zastosować',
      Player: 'Gracz',
      'Time of registration': 'Czas rejestracji',
      confirmed: 'potwierdzony',
      declined: 'odrzucony',
      'payment pending': 'oczekuje na płatność',
      WITHDRAW: 'WYCOFAĆ SIĘ',
      'No other tournament found': 'Nie znaleziono innych turniejów',
      welcomeTextCustom:
        'jest zasilany przez Tournated, kompleksowe oprogramowanie do zarządzania sportem, które umożliwia organizacjom sportowym efektywne zarządzanie, rozwój i monetyzację ich działań. Rozwiązanie pozwala organizacjom tworzyć niestandardowe platformy online, automatyzować i upraszczać zarządzanie ligami, klubami, turniejami i innymi wydarzeniami, zwiększać zaangażowanie publiczności oraz bezproblemowo przetwarzać płatności.',
      welcomeText:
        'wynik pięciu lat badań i rozwoju oraz współpracy z ponad 200 organizacjami, jest innowacyjnym, konfigurowalnym, przyjaznym użytkownikowi i niedrogim rozwiązaniem typu all-in-one dla organizacji sportowych dowolnej wielkości.',
      'Did you have an account in one of ': 'Czy miałeś konto na jednej z ',
      those: 'tych',
      'platforms?': 'platforma?',
      'Recover password': 'Odzyskaj hasło',
      newUser: 'Jestem nowym użytkownikiem. Zarejestruj się',
      'Continue as a guest': 'Kontynuuj jako gość',
      Support: 'Wsparcie',
      'Welcome to': 'Witamy w',
      'If you have an account on one of ': 'Jeśli masz konto na jednej z ',
      these: 'tych',
      'platforms, log in with your e-mail or create a new profile': 'platforma, zaloguj się za pomocą e-maila lub utwórz nowy profil',
      'Reset password': 'Zresetuj hasło',
      'Welcome to the': 'Witamy na',
      platform: 'platformie',
      'Powered by': 'Obsługiwane przez',
      'All Categories': 'Wszystkie kategorie',
      'Tournament Results': 'Wyniki turnieju',
      versionText: 'Beta jest na żywo. Prosimy o przesłanie swojej opinii',
      here: 'Tutaj',
      'Full view': 'Pełny widok',
      Timeline: 'Oś czasu',
      Grid: 'Siatka',
      "Bird's View": 'Widok z lotu ptaka',
      'Third Party Licences': 'Licencje stron trzecich',
      Friend: 'Przyjaciel',
      'Step 2': 'Krok 2:',
      'Step 3': 'Krok 3:',
      'Your Details': 'Twoje dane',
      'Partner Details': 'Dane partnera',
      'Enter partner email': 'Wpisz adres e-mail partnera',
      'Register & Pay': 'Zarejestruj się i Zapłać',
      'On-Site': 'Na Miejscu',
      Now: 'Teraz',
    },
  },
  hr: {
    translation: {
      header: {
        discover: 'Otkrijte',
        enterName: 'Unesite ime',
        sportsManager: 'Sportski menadžer?',
        learnHow: 'Saznajte kako',
        accelerate: 'možemo ubrzati vašu organizaciju.',
        powering: 'Pokreće',
        tournaments: 'turnire,',
        leagues: 'lige',
        organizations: 'organizacije',
        clubs: 'klubove',
        coaches: 'trenere',
        placeholder: {
          tournaments: 'Unesite naziv turnira',
          leagues: 'Unesite naziv lige',
          organizations: 'Unesite naziv organizacije',
          clubs: 'Unesite naziv kluba',
          coaches: 'Unesite ime trenera',
        },
      },
      registrationOpen: 'Registracija otvorena',
      read_more: 'Pročitajte više',
      registrationClosed: 'Registracija zatvorena',
      live: 'Uživo',
      Close: 'Zatvori',
      bannerHeading: 'Počnite upravljati svojim turnirima potpuno besplatno',
      bannerDescription: 'Od upravljanja prijavama do automatskog generiranja grupa, rasporeda i reda igranja, upravljanja rezultatima i još mnogo toga',
      startNow: 'Započnite sada',
      featureTournaments: 'Istaknuti turniri',
      tournamentNotFound: 'Nema istaknutih turnira',
      viewAll: 'Pogledaj sve',
      featureLeague: 'Istaknute lige',
      leaguesNotFound: 'Nema istaknutih liga',
      Tournaments: 'Turniri',
      NoTournamentsFound: 'Nema pronađenih turnira',
      Rankings: 'Poredak',
      noRankingFound: 'Nema pronađenog poretka',
      news: 'Vijesti',
      noNewsFound: 'Nema pronađenih vijesti',
      partners: 'Naši partneri',
      Partners: 'Partneri',
      noPartnersFound: 'Nema pronađenih partnera',
      worldwide: 'Svjetski',
      search: 'Pretraži',
      pricing: 'Cijene',
      loginRegister: 'Prijava / Registracija',
      home: 'Početna',
      organizations: 'Organizacije',
      leagues: 'Lige',
      teams: 'Timovi',
      clubs: 'Klubovi',
      coaches: 'Treneri',
      tennis: 'Tenis',
      beachTenis: 'Plažni tenis',
      'Beach Volley': 'Plažni odbojka',
      'Sport Fishing': 'Sportski ribolov',
      Volleyball: 'Odbojka',
      Sports: 'Sportovi',
      RESOURSES: 'RESURSI',
      Feature: 'Značajka',
      'Create account': 'Stvori račun',
      Login: 'Prijava',
      Packages: 'Paketi',
      'Package one': 'Paket jedan',
      'Package two': 'Paket dva',
      'Package three': 'Paket tri',
      'Package Four': 'Paket četiri',
      'Customer suppor': 'Korisnička podrška',
      FAQ: 'Često postavljena pitanja',
      Documentation: 'Dokumentacija',
      'Video tutorials': 'Video tutorijali',
      'Submit ticket': 'Podnesite zahtjev',
      'Contact us': 'Kontaktirajte nas',
      'Our contacts': 'Naši kontakti',
      Padel: 'Padel',
      Pickleball: 'Pickleball',
      'FEATURED LEAGUES': 'ISTAKNUTE LIGE',
      View: 'Pogledaj',
      'No leagues found': 'Nema pronađenih liga',
      Athletes: 'Sportaši',
      'Managed by': 'Upravljano od',
      'View League': 'Pogledaj Ligu',
      Type: 'Vrsta',
      National: 'Nacionalno',
      International: 'Međunarodno',
      Local: 'Lokalno',
      Period: 'Razdoblje',
      League: 'Liga',
      Country: 'Država',
      Year: 'Godina',
      Points: 'Bodovi',
      Position: 'Pozicija',
      Team: 'Tim',
      'No teams found': 'Nema pronađenih timova',
      'Team Members': 'Članovi tima',
      Sport: 'Sport',
      'No clubs found': 'Nema pronađenih klubova',
      Experience: 'Iskustvo',
      Students: 'Studenti',
      Author: 'Autor',
      Category: 'Kategorija',
      'My Entries': 'Moje prijave',
      'My Leagues': 'Moje Lige',
      'My Licences': 'Moje Licence',
      'My Teams': 'Moji Timovi',
      'Get Licence': 'Dobijte Licencu',
      demo: 'demo',
      Settings: 'Postavke',
      'Log out': 'Odjava',
      'Dark view': 'Tamni prikaz',
      Manager: 'Menadžer',
      Tournament: 'Turnir',
      Cost: 'Trošak',
      Duration: 'Trajanje',
      'Purchase on': 'Kupnja na',
      'No license found': 'Nema pronađene licence',
      Additional: 'Dodatno',
      Status: 'Status',
      Date: 'Datum',
      'No entries found': 'Nema pronađenih prijava',
      'My Licenses': 'Moje Licence',
      'Add new License': 'Dodaj novu licencu',
      'Create Team': 'Stvori Tim',
      Info: 'Informacije',
      infoDes: 'Za sudjelovanje u određenim ligama/turama, igrač mora imati odgovarajuću aktivnu licencu.',
      'Select League': 'Odaberi Ligu',
      'Select License': 'Odaberi Licencu',
      'Promo code': 'Promotivni kod',
      'I agree to the': 'Slažem se s',
      'Terms and Conditions': 'Uvjetima i odredbama',
      Price: 'Cijena',
      'Confirm Payment': 'Potvrdi uplatu',
      History: 'Povijest',
      'Tournated Manager': 'Tournated Menadžer',
      'Tournated Custom': 'Tournated Prilagođeno',
      'Customer support': 'KORISNIČKA PODRŠKA',
      Categories: 'Kategorije',
      Matches: 'Mečevi',
      'Organized by': 'Organizirano od',
      Register: 'Registracija',
      Entries: 'Prijave',
      Location: 'Lokacija',
      'National Tour': 'Nacionalna Tura',
      '4 mins read': 'Čitanje traje 4 minute',
      'Age group': 'Dobna skupina',
      'Skills level': 'Razina vještina',
      someDes: 'SIA Vertexo © 2020. Sva prava pridržana',
      'Manage in CMS': 'Upravljajte u CMS-u',
      'Fact Sheet': 'Podaci',
      General: 'Općenito',
      Participants: 'Sudionici',
      Groups: 'Grupe',
      Draws: 'Ždrijeb',
      'Live Standings': 'Trenutni Poredak',
      'Order of play': 'Redoslijed igre',
      Pairings: 'Parovi',
      Standings: 'Poredak',
      'Final standings': 'Konačni poredak',
      'List & Results': 'Popis i Rezultati',
      'Entry Fee': 'Kotizacija',
      'Entry Deadline': 'Rok za prijavu',
      'Entry deadline': 'Rok za prijavu',
      'Withdrawal deadline': 'Rok za odustajanje',
      'Start date': 'Datum početka',
      'End date': 'Datum završetka',
      and: 'i',
      others: 'drugi',
      'are confirmed': 'su potvrđeni',
      Description: 'Opis',
      'Tournament Poster': 'Plakat turnira',
      'More Info': 'Više informacija',
      'Additional materials about tournament': 'Dodatni materijali o turniru',
      Regulations: 'Pravilnik',
      'Tournaments Discord': 'Discord turnira',
      discordDes: 'Razgovarajte izravno s administratorima turnira, postavljajte pitanja i razgovarajte s drugim igračima!',
      'Join Channel': 'Pridružite se kanalu',
      'Fact sheet': 'Podaci',
      'Search hotels and more': 'Pretraži hotele i još mnogo toga...',
      Destination: 'Odredište',
      'Select Destination': 'Odaberi Odredište',
      'Check in date': 'Datum prijave',
      'Check out date': 'Datum odjave',
      'Best players on this tournament': 'Najbolji igrači ovog turnira',
      Gallery: 'Galerija',
      'Recent photos': 'Nedavne fotografije',
      'Buy licence': 'Kupi licencu',
      'Select All': 'Odaberi sve',
      "Don't see your sport? Contact us": 'Ne vidite svoj sport? Kontaktirajte nas',
      'Description is missing': 'Opis nedostaje',
      day: 'dan',
      days: 'dani',
      Organization: 'Organizacija',
      Athlete: 'Sportaš',
      'Birth year': 'Godina rođenja',
      Joined: 'Pridružio se',
      'Select Sport': 'Odaberi sport',
      'Select organization': 'Odaberi organizaciju',
      'Select league': 'Odaberi ligu',
      Overview: 'Pregled',
      About: 'O',
      'Win rate': 'Stopa pobjeda',
      Achievements: 'Postignuća',
      'Upcoming Matches': 'Nadolazeći mečevi',
      'Past Matches': 'Prošli mečevi',
      'Upcoming Tournaments': 'Nadolazeći turniri',
      'Purchase history': 'Povijest kupnje',
      'description and detail': 'Poboljšajte: Nabavite licence/članstva potrebna za sudjelovanje u određenim ligama ili turnirima',
      'Purchase datetime': 'Datum i vrijeme kupnje',
      'Payment Status': 'Status Uplate',
      Licence: 'Licenca',
      Members: 'Članovi',
      Student: 'Student',
      'Personal page': 'Osobna stranica',
      'My Career': 'Moja karijera',
      'Latest tournaments': 'Najnoviji turniri',
      'Upcoming tournaments': 'Nadolazeći turniri',
      'No members found': 'Nema pronađenih članova',
      'No tournaments found': 'Nema pronađenih turnira',
      'No matches found': 'Nema pronađenih mečeva',
      'No rankings found': 'Nema pronađenih rangova',
      'No past matches found': 'Nema pronađenih prošlih mečeva',
      'No upcoming matches found': 'Nema pronađenih nadolazećih mečeva',
      'No achievements found': 'Nema pronađenih postignuća',
      Documents: 'Dokumenti',
      Contact: 'Kontakt',
      'No photos added': 'Nema dodanih fotografija',
      'Manage league': 'Upravljaj ligom',
      'No documents added': 'Nema dodanih dokumenata',
      Email: 'E-mail',
      Phone: 'Telefon',
      Website: 'Web stranica',
      Place: 'Mjesto',
      'Tournament Name': 'Naziv turnira',
      'Manage organization': 'Upravljaj organizacijom',
      'Terms & Conditions': 'Uvjeti i odredbe',
      LEGAL: 'PRAVNO',
      'Privacy policy': 'Pravila privatnosti',
      'Cancellation policy': 'Pravila otkazivanja',
      GDPR: 'GDPR',
      'All Rights Reserved': 'Sva prava pridržana',
      'League [Category]': 'Liga [Kategorija]',
      'Other tournaments': 'Drugi turniri',
      'Points Details': 'Detalji o bodovima',
      'International rating': 'Međunarodni rejting',
      Day: 'Dan',
      //////////// NEW ///////////////////////////////
      Days: 'Dani',
      Tennis: 'Tenis',
      'Beach Tennis': 'Plažni Tenis',
      'Beach Volleyball': 'Plažna Odbojka',
      Chess: 'Šah',
      'In progress': 'U tijeku',
      Registration: 'Registracija',
      'Deadline past': 'Rok je prošao',
      Completed: 'Dovršeno',
      'No points yet': 'Još nema bodova',
      'is confirmed': 'je potvrđeno',
      Contacts: 'Kontakti',
      Organizer: 'Organizator',
      'Featured athletes': 'Istaknuti sportaši',
      'Top-ranked participants of the tournament': 'Sudionici turnira s najboljim rangom',
      NR: 'NR',
      PLAYER: 'IGRAČ',
      CITY: 'GRAD',
      RATING: 'REJTING',
      CLUB: 'KLUB',
      TOTAL: 'UKUPNO',
      SEGMENT: 'SEGMENT',
      SEED: 'NOSITELJ',
      Search: 'Pretraži',
      'No participants found': 'Nema pronađenih sudionika',
      Total: 'Ukupno',
      Seed: 'Nositelj',
      Rating: 'Rejting',
      Rank: 'Rang',
      'Category:': 'Kategorija:',
      'No category found': 'Nema pronađene kategorije',
      'No Rounds found': 'Nema pronađenih rundi',
      White: 'Bijeli',
      Black: 'Crni',
      Name: 'Ime',
      Pts: 'Bodovi',
      Score: 'Rezultat',
      'There is no available pairings!': 'Nema dostupnih parova!',
      'Generating PDF...': 'Generiranje PDF-a...',
      'PDF Generated Successfully!': 'PDF uspješno generiran!',
      'PDF Downloaded Successfully!': 'PDF uspješno preuzet!',
      'Downloading...': 'Preuzimanje...',
      'Pairing Standings': 'Poredak parova',
      Opponents: 'Protivnici',
      BHC1: 'BHC1',
      BHC: 'BHC',
      BGP: 'BGP',
      WIN: 'POBJEDA',
      'There is no available entries!': 'Nema dostupnih prijava!',
      "I'm a coach/parent": 'Ja sam trener/roditelj',
      'Step 1': 'Korak 1',
      'Select category': 'Odaberi kategoriju',
      Request: 'Zahtjev',
      'Register & Pay Now': 'Registriraj se i plati odmah',
      'Register & Pay On-Site': 'Registriraj se i plati na mjestu',
      'Terms And Conditions': 'Uvjeti i odredbe',
      'Latest Winners': 'Najnoviji pobjednici',
      'No winners found': 'Nema pronađenih pobjednika',
      'No news found': 'Nema pronađenih vijesti',
      'No partners found': 'Nema pronađenih partnera',
      'Skills Levels': 'Razina vještina',
      'To get register for the tournament you must have active tour licence.': 'Za registraciju na turnir morate imati aktivnu licencu.',
      'Contact Details': 'Kontakt podaci',
      'Our Partners': 'Naši Partneri',
      'View all': 'Pogledaj sve',
      Member: 'Član',
      'Team Name': 'Naziv tima',
      News: 'Vijesti',
      'Men Pro': 'Muški Pro',
      'Latvia Tennis Union League': 'Latvijska teniska unija liga',
      Leagues: 'Lige',
      'No league found': 'Nema pronađene lige',
      'Home Page': 'Početna Stranica',
      Upcoming: 'Nadolazeće',
      'This month': 'Ovaj mjesec',
      'Last 7 days': 'Posljednjih 7 dana',
      'Last 30 days': 'Posljednjih 30 dana',
      Hours: 'Sati',
      Minutes: 'Minute',
      Teams: 'Timovi',
      'No student found': 'Nema pronađenih studenata',
      'Other news': 'Druge vijesti',
      'Don’t have an account?': 'Nemate račun?',
      'Sign up': 'Prijavite se',
      'or log in': 'ili se prijavite',
      'Did you forget your password?': 'Jeste li zaboravili lozinku?',
      'Must be a valid email': 'Mora biti važeći e-mail',
      'Email is required': 'E-mail je obavezan',
      'Password is required': 'Lozinka je obavezna',
      Password: 'Lozinka',
      'Remember me': 'Zapamti me',
      'Log In': 'Prijavi se',
      'Unauthorized , please sign in': 'Neautorizirano, molimo prijavite se',
      'Reset your password': 'Resetirajte lozinku',
      'Enter the e-mail address': 'Unesite e-mail adresu',
      'Enter your e-mail address with which you are registered': 'Unesite e-mail adresu s kojom ste registrirani',
      'Please check your email for reset password link!': 'Molimo provjerite svoj e-mail za link za resetiranje lozinke!',
      'Send instructions': 'Pošalji upute',
      'My Licenes': 'Moje licence',
      'My leagues': 'Moje lige',
      'My tournaments': 'Moji turniri',
      'My matches': 'Moji mečevi',
      'My entries': 'Moje prijave',
      'My rankings': 'Moji poredci',
      Stats: 'Statistike',
      'Leagues took part in': 'Lige u kojima ste sudjelovali',
      'Active licences': 'Aktivne licence',
      Titles: 'Naslovi',
      'Matches played': 'Odigrani mečevi',
      'Matches lost': 'Izgubljeni mečevi',
      'Games/points played': 'Odigrane igre/bodovi',
      'All sports': 'Svi sportovi',
      'All organizations': 'Sve organizacije',
      'All leagues': 'Sve lige',
      Registered: 'Registriran',
      Withdraw: 'Povući',
      'Withdraw deadline passed': 'Rok za povlačenje prošao',
      'Request Withdrawn': 'Zahtjev povučen',
      'Something went wrong!': 'Nešto je pošlo po zlu!',
      Withdrawal: 'Povlačenje',
      'Entry to withdraw': 'Unos za povlačenje',
      'Withdrawal Reason': 'Razlog povlačenja',
      'Write your reason here': 'Napišite razlog ovdje',
      Confirm: 'Potvrdi',
      'Please specify reason': 'Molimo navedite razlog',
      'AUDI - The Beach Tennis Championship': 'AUDI - Plažni Tenis Prvenstvo',
      'Get licence': 'Dobijte licencu',
      'Additional questions': 'Dodatna pitanja',
      'Select duration': 'Odaberi trajanje',
      Career: 'Karijera',
      'Third Party Licenes': 'Licence treće strane',
      'User updated successfully': 'Korisnik uspješno ažuriran',
      'User Account': 'Korisnički račun',
      'We recommend an image that is 256 x 256 pixels': 'Preporučujemo sliku veličine 256 x 256 piksela',
      'Enter Email Address': 'Unesite e-mail adresu',
      'Enter name': 'Unesite ime',
      Surname: 'Prezime',
      'Enter surname': 'Unesite prezime',
      'Contact phone number': 'Kontakt broj telefona',
      'Enter phone number': 'Unesite broj telefona',
      'Date of Birth': 'Datum rođenja',
      'Date of birth': 'Datum rođenja',
      Gender: 'Spol',
      'Select gender': 'Odaberi spol',
      'Representing country': 'Predstavljajući državu',
      'Select country': 'Odaberi državu',
      City: 'Grad',
      'Select role(s)': 'Odaberi ulogu(e)',
      Address: 'Adresa',
      'Enter address': 'Unesite adresu',
      'Parent found': 'Roditelj pronađen',
      'Parent not found': 'Roditelj nije pronađen',
      'Removed coach': 'Trener uklonjen',
      'Failed to removed coach': 'Neuspješno uklanjanje trenera',
      'Coach created': 'Trener stvoren',
      'Failed to create coach': 'Neuspješno stvaranje trenera',
      Club: 'Klub',
      Save: 'Spremi',
      'Select club': 'Odaberi klub',
      Coach: 'Trener',
      'Select a coach': 'Odaberi trenera',
      Parent: 'Roditelj',
      'Enter email': 'Unesite e-mail',
      Biography: 'Biografija',
      'Write a bio': 'Napišite biografiju',
      CHECKBOXES: 'KUĆICE',
      'Give access to coach to purchase licences and manage entries on your behalf': 'Dajte pristup treneru za kupnju licenci i upravljanje prijavama u vaše ime',
      'Give access to club manager(-es) to purchase licences and manage entries on your behalf': 'Dajte pristup klupskom menadžeru(-ima) za kupnju licenci i upravljanje prijavama u vaše ime',
      'Give access to parent(-s) to purchase licences and manage entries on your behalf': 'Dajte pristup roditelju(-ima) za kupnju licenci i upravljanje prijavama u vaše ime',
      Socials: 'Društvene mreže',
      'Insert link here': 'Umetnite link ovdje',
      Facebook: 'Facebook',
      Instagram: 'Instagram',
      Linkedin: 'Linkedin',
      Tiktok: 'Tiktok',
      Youtube: 'Youtube',
      'Enter Id': 'Unesite ID',
      'Password should be 8 chars minimum.': 'Lozinka treba imati najmanje 8 znakova.',
      'Passwords must match': 'Lozinke moraju odgovarati',
      'Password changed successfully': 'Lozinka uspješno promijenjena',
      'New Password': 'Nova lozinka',
      'Re-Enter': 'Ponovno unesite',
      'Name is Required': 'Ime je obavezno',
      'Surname is Required': 'Prezime je obavezno',
      'Re enter password': 'Ponovno unesite lozinku',
      'User is already registered': 'Korisnik je već registriran',
      'Create an account': 'Stvori račun',
      'Already have an account?': 'Već imate račun?',
      'Log in': 'Prijavi se',
      'or create account': 'ili stvori račun',
      "I'm creating an account for a kid": 'Stvaram račun za dijete',
      'sign-up description':
        'Stvarate račun za sudionika turnira. Ako registrirate dijete, molimo kreirajte profil za njega/nju. Profili trenera i roditelja bit će uskoro dostupni, omogućujući povezivanje s profilom djeteta.',
      'Email Address': 'E-mail adresa',
      Continue: 'Nastavi',
      'Pick a sport': 'Odaberi sport',
      'Pick a sport that you play': 'Odaberi sport koji igrate',
      'Gender is required': 'Spol je obavezan',
      'Phone is required': 'Telefon je obavezan',
      'Citizenship is required': 'Državljanstvo je obavezno',
      'Dob is required': 'Datum rođenja je obavezan',
      'FIDE ID Not found': 'FIDE ID nije pronađen',
      'FIDE ID found': 'FIDE ID pronađen',
      'Tell us more about you': 'Reci nam više o sebi',
      'More information about yourself': 'Više informacija o sebi',
      'Enter FIDE ID': 'Unesite FIDE ID',
      'IPIN ID is correct': 'IPIN ID je ispravan',
      'Your Licenses': 'Vaše licence',
      'Attach your licenses': 'Priložite svoje licence',
      Skip: 'Preskoči',
      'User registered successfully': 'Korisnik uspješno registriran',
      'What is your primary role in the world of sports?': 'Koja je vaša glavna uloga u svijetu sporta?',
      'Register & Go to profile': 'Registriraj se i idi na profil',
      'PUBLISHED ON': 'OBJAVLJENO NA',
      'Federation member': 'Član federacije',
      'Club owner': 'Vlasnik kluba',
      'Club manager': 'Menadžer kluba',
      Referee: 'Sudac',
      'Tournament organizer': 'Organizator turnira',
      'Sport fan': 'Sportski fan',
      Other: 'Drugo',
      PAY: 'PLATI',
      '1 Year': '1 godina',
      '2 Years': '2 godine',
      '3 Years': '3 godine',
      '4 Years': '4 godine',
      'Registration time': 'Vrijeme registracije',
      'In progress / Upcoming': 'U tijeku / Nadolazeće',
      'Matches won': 'Dobiveni mečevi',
      'In progress / upcoming entries': 'U tijeku / nadolazeće prijave',
      'In progress / upcoming tournaments': 'U tijeku / nadolazeći turniri',
      'Past tournaments': 'Prošli turniri',
      'Past entries': 'Prošle prijave',
      'Your password must be different to previous passwords': 'Vaša lozinka mora biti različita od prethodnih lozinki',
      'less than a minute': 'manje od minute',
      read: 'pročitajte',
      minute: 'minuta',
      'Select Licence': 'Odaberi licencu',
      'Start time': 'Vrijeme početka',
      'Try again': 'Pokušajte ponovno',
      maintenanceMessage: 'Trenutno ažuriramo platformu, što može potrajati nekoliko minuta. Pokušajte ponovno uskoro.',
      networkErrorMessage: 'Provjerite svoju mrežnu vezu i pokušajte ponovno.',
      errorTitle: 'Ups! Nešto nije u redu!',
      'View List': 'Pogledaj popis',
      Free: 'Besplatno',
      'Primary Sport': 'Glavni sport',
      'Role(s)': 'Uloga(e)',
      Menu: 'Izbornik',
      Calendar: 'Kalendar',
      'Manage tournament': 'Upravljaj turnirom',
      'Partner search': 'Pretraživanje partnera',
      'Partner Search': 'Pretraživanje partnera',
      'Searching...': 'Pretraživanje...',
      Apply: 'Prijavi se',
      Delete: 'Izbriši',
      'Create application': 'Stvori prijavu',
      'No partner search applications found': 'Nema pronađenih prijava za pretraživanje partnera',
      'Log in to apply': 'Prijavite se za prijavu',
      Player: 'Igrač',
      'Time of registration': 'Vrijeme registracije',
      confirmed: 'potvrđeno',
      declined: 'odbijeno',
      'payment pending': 'uplata na čekanju',
      WITHDRAW: 'POVUCI',
      'No other tournament found': 'Nema pronađenih drugih turnira',
      welcomeTextCustom:
        'pokreće Tournated, sve-u-jednom softver za upravljanje sportom koji omogućuje sportskim organizacijama učinkovito upravljanje, rast i unovčavanje svojih operacija. Rješenje omogućuje organizacijama stvaranje prilagođenih online platformi, automatizaciju i pojednostavljenje upravljanja ligama, klubovima, turnirima i drugim događajima, povećanje angažmana publike i jednostavno procesiranje uplata.',
      welcomeText:
        'rezultat je pet godina istraživanja i razvoja i suradnje s više od 200 organizacija, inovativno, prilagodljivo, korisnički prijateljsko i pristupačno sve-u-jednom rješenje za sportske organizacije bilo koje veličine',
      'Did you have an account in one of ': 'Jeste li imali račun na jednoj od ',
      those: 'tih',
      'platforms?': 'platformi?',
      'Recover password': 'Oporavak lozinke',
      newUser: 'Ja sam novi korisnik. Registriraj se',
      'Continue as a guest': 'Nastavi kao gost',
      Support: 'Podrška',
      'Welcome to': 'Dobrodošli u',
      'If you have an account on one of ': 'Ako imate račun na jednoj od ',
      these: 'ovih',
      'platforms, log in with your e-mail or create a new profile': 'platformi, prijavite se s vašim e-mailom ili kreirajte novi profil',
      'Reset password': 'Resetiraj lozinku',
      'Welcome to the': 'Dobrodošli u',
      platform: 'platformu',
      'Powered by': 'Pokreće',
      'All Categories': 'Sve Kategorije',
      'Tournament Results': 'Rezultati Turnira',
      versionText: 'Beta je uživo. Molimo pošaljite povratne informacije',
      here: 'ovdje',
      'Full view': 'Cijeli prikaz',
      Timeline: 'Vremenska crta',
      Grid: 'Mreža',
      "Bird's View": 'Pogled iz ptičje perspektive',
      'Third Party Licences': 'Licence trećih strana',
      Friend: 'Prijatelj',
      'Step 2': 'Korak 2:',
      'Step 3': 'Korak 3:',
      'Your Details': 'Vaši detalji',
      'Partner Details': 'Detalji partnera',
      'Enter partner email': 'Unesite email partnera',
      'Register & Pay': 'Registriraj se i Plati',
      'On-Site': 'Na Licu Mjesta',
      Now: 'Sada',
    },
  },
  cs: {
    translation: {
      header: {
        discover: 'Objevte',
        enterName: 'Zadejte jméno',
        sportsManager: 'Sportovní manažer?',
        learnHow: 'Zjistěte jak',
        accelerate: 'můžeme urychlit vaši organizaci.',
        powering: 'Napájení',
        tournaments: 'turnaje,',
        leagues: 'ligy',
        organizations: 'organizace',
        clubs: 'kluby',
        coaches: 'trenéři',
        placeholder: {
          tournaments: 'Zadejte název turnaje',
          leagues: 'Zadejte název ligy',
          organizations: 'Zadejte název organizace',
          clubs: 'Zadejte název klubu',
          coaches: 'Zadejte jméno trenéra',
        },
      },
      registrationOpen: 'Registrace otevřena',
      read_more: 'Čtěte více',
      registrationClosed: 'Registrace uzavřena',
      live: 'Živě',
      Close: 'Zavřít',
      bannerHeading: 'Začněte spravovat své turnaje zcela zdarma',
      bannerDescription: 'Od správy přihlášek až po automatické generování skupin, losů a pořadí her, správy výsledků a mnohem více',
      startNow: 'Začněte nyní',
      featureTournaments: 'Doporučené turnaje',
      tournamentNotFound: 'Nebyly nalezeny žádné doporučené turnaje',
      viewAll: 'Zobrazit vše',
      featureLeague: 'Doporučené ligy',
      leaguesNotFound: 'Nebyly nalezeny žádné doporučené ligy',
      Tournaments: 'Turnaje',
      NoTournamentsFound: 'Nebyly nalezeny žádné turnaje',
      Rankings: 'Žebříčky',
      noRankingFound: 'Nebyly nalezeny žádné žebříčky',
      news: 'Novinky',
      noNewsFound: 'Nebyly nalezeny žádné novinky',
      partners: 'Naši partneři',
      Partners: 'Partneři',
      noPartnersFound: 'Nebyli nalezeni žádní partneři',
      worldwide: 'Celosvětově',
      search: 'Vyhledávání',
      pricing: 'Ceník',
      loginRegister: 'Přihlásit se / Zaregistrovat',
      home: 'Domů',
      organizations: 'Organizace',
      leagues: 'Ligy',
      teams: 'Týmy',
      clubs: 'Kluby',
      coaches: 'Trenéři',
      tennis: 'Tenis',
      beachTenis: 'Plážový tenis',
      'Beach Volley': 'Plážový volejbal',
      'Sport Fishing': 'Sportovní rybolov',
      Volleyball: 'Volejbal',
      Sports: 'Sporty',
      RESOURSES: 'ZDROJE',
      Feature: 'Vlastnost',
      'Create account': 'Vytvořit účet',
      Login: 'Přihlášení',
      Packages: 'Balíčky',
      'Package one': 'Balíček jeden',
      'Package two': 'Balíček dva',
      'Package three': 'Balíček tři',
      'Package Four': 'Balíček čtyři',
      'Customer suppor': 'Zákaznická podpora',
      FAQ: 'Často kladené otázky',
      Documentation: 'Dokumentace',
      'Video tutorials': 'Video návody',
      'Submit ticket': 'Odeslat žádost',
      'Contact us': 'Kontaktujte nás',
      'Our contacts': 'Naše kontakty',
      Padel: 'Padel',
      Pickleball: 'Pickleball',
      'FEATURED LEAGUES': 'DOPORUČENÉ LIGY',
      View: 'Zobrazit',
      'No leagues found': 'Nebyly nalezeny žádné ligy',
      Athletes: 'Sportovci',
      'Managed by': 'Spravováno',
      'View League': 'Zobrazit Ligu',
      Type: 'Typ',
      National: 'Národní',
      International: 'Mezinárodní',
      Local: 'Místní',
      Period: 'Období',
      League: 'Liga',
      Country: 'Země',
      Year: 'Rok',
      Points: 'Body',
      Position: 'Pozice',
      Team: 'Tým',
      'No teams found': 'Nebyly nalezeny žádné týmy',
      'Team Members': 'Členové týmu',
      Sport: 'Sport',
      'No clubs found': 'Nebyly nalezeny žádné kluby',
      Experience: 'Zkušenost',
      Students: 'Studenti',
      Author: 'Autor',
      Category: 'Kategorie',
      'My Entries': 'Moje přihlášky',
      'My Leagues': 'Moje Ligy',
      'My Licences': 'Moje Licence',
      'My Teams': 'Moje Týmy',
      'Get Licence': 'Získat licenci',
      demo: 'demo',
      Settings: 'Nastavení',
      'Log out': 'Odhlásit se',
      'Dark view': 'Tmavý pohled',
      Manager: 'Manažer',
      Tournament: 'Turnaj',
      Cost: 'Náklady',
      Duration: 'Trvání',
      'Purchase on': 'Zakoupit na',
      'No license found': 'Nebyla nalezena žádná licence',
      Additional: 'Doplňující',
      Status: 'Stav',
      Date: 'Datum',
      'No entries found': 'Nebyly nalezeny žádné přihlášky',
      'My Licenses': 'Moje Licence',
      'Add new License': 'Přidat novou licenci',
      'Create Team': 'Vytvořit tým',
      Info: 'Informace',
      infoDes: 'Pro účast v určitých ligách / tůrách musí mít hráč odpovídající aktivní licenci.',
      'Select League': 'Vyberte Ligu',
      'Select License': 'Vyberte Licenci',
      'Promo code': 'Promo kód',
      'I agree to the': 'Souhlasím s',
      'Terms and Conditions': 'Podmínkami a ustanoveními',
      Price: 'Cena',
      'Confirm Payment': 'Potvrdit platbu',
      History: 'Historie',
      'Tournated Manager': 'Tournated Manažer',
      'Tournated Custom': 'Tournated Přizpůsobený',
      'Customer support': 'ZÁKAZNICKÁ PODPORA',
      Categories: 'Kategorie',
      Matches: 'Zápasy',
      'Organized by': 'Organizováno',
      Register: 'Registrace',
      Entries: 'Přihlášky',
      Location: 'Místo',
      'National Tour': 'Národní turné',
      '4 mins read': 'Čtení trvá 4 minuty',
      'Age group': 'Věková skupina',
      'Skills level': 'Úroveň dovedností',
      someDes: 'SIA Vertexo © 2020. Všechna práva vyhrazena',
      'Manage in CMS': 'Spravovat v CMS',
      'Fact Sheet': 'Fakta',
      General: 'Obecné',
      Participants: 'Účastníci',
      Groups: 'Skupiny',
      Draws: 'Losy',
      'Live Standings': 'Aktuální pořadí',
      'Order of play': 'Pořadí hry',
      Pairings: 'Páry',
      Standings: 'Pořadí',
      'Final standings': 'Konečné pořadí',
      'List & Results': 'Seznam a výsledky',
      'Entry Fee': 'Vstupní poplatek',
      'Entry Deadline': 'Uzávěrka přihlášek',
      'Entry deadline': 'Uzávěrka přihlášek',
      'Withdrawal deadline': 'Termín odstoupení',
      'Start date': 'Datum začátku',
      'End date': 'Datum konce',
      and: 'a',
      others: 'další',
      'are confirmed': 'jsou potvrzeni',
      Description: 'Popis',
      'Tournament Poster': 'Plakát turnaje',
      'More Info': 'Více informací',
      'Additional materials about tournament': 'Další materiály o turnaji',
      Regulations: 'Předpisy',
      'Tournaments Discord': 'Discord turnajů',
      discordDes: 'Mluvte přímo s administrátory turnajů, kladte otázky a komunikujte s ostatními hráči!',
      'Join Channel': 'Připojit se k kanálu',
      'Fact sheet': 'Fakta',
      'Search hotels and more': 'Hledejte hotely a více...',
      Destination: 'Cíl',
      'Select Destination': 'Vyberte Cíl',
      'Check in date': 'Datum příjezdu',
      'Check out date': 'Datum odjezdu',
      'Best players on this tournament': 'Nejlepší hráči na tomto turnaji',
      Gallery: 'Galerie',
      'Recent photos': 'Nedávné fotografie',
      'Buy licence': 'Koupit licenci',
      'Select All': 'Vybrat vše',
      "Don't see your sport? Contact us": 'Nevidíte svůj sport? Kontaktujte nás',
      'Description is missing': 'Popis chybí',
      day: 'den',
      days: 'dny',
      Organization: 'Organizace',
      Athlete: 'Sportovec',
      'Birth year': 'Rok narození',
      Joined: 'Připojen',
      'Select Sport': 'Vyberte Sport',
      'Select organization': 'Vyberte organizaci',
      'Select league': 'Vyberte ligu',
      Overview: 'Přehled',
      About: 'O',
      'Win rate': 'Procento výher',
      Achievements: 'Úspěchy',
      'Upcoming Matches': 'Nadcházející zápasy',
      'Past Matches': 'Minulé zápasy',
      'Upcoming Tournaments': 'Nadcházející turnaje',
      'Purchase history': 'Historie nákupů',
      'description and detail': 'Vylepšení: Získejte licence/členství potřebné pro účast v určitých ligách nebo turnajích',
      'Purchase datetime': 'Datum a čas nákupu',
      'Payment Status': 'Stav platby',
      Licence: 'Licence',
      Members: 'Členové',
      Student: 'Student',
      'Personal page': 'Osobní stránka',
      'My Career': 'Moje kariéra',
      'Latest tournaments': 'Nejnovější turnaje',
      'Upcoming tournaments': 'Nadcházející turnaje',
      'No members found': 'Nebyli nalezeni žádní členové',
      'No tournaments found': 'Nebyly nalezeny žádné turnaje',
      'No matches found': 'Nebyly nalezeny žádné zápasy',
      'No rankings found': 'Nebyly nalezeny žádné žebříčky',
      'No past matches found': 'Nebyly nalezeny žádné minulé zápasy',
      'No upcoming matches found': 'Nebyly nalezeny žádné nadcházející zápasy',
      'No achievements found': 'Nebyly nalezeny žádné úspěchy',
      Documents: 'Dokumenty',
      Contact: 'Kontakt',
      'No photos added': 'Nebyly přidány žádné fotografie',
      'Manage league': 'Spravovat ligu',
      'No documents added': 'Nebyly přidány žádné dokumenty',
      Email: 'E-mail',
      Phone: 'Telefon',
      Website: 'Webová stránka',
      Place: 'Místo',
      'Tournament Name': 'Název turnaje',
      'Manage organization': 'Spravovat organizaci',
      'Terms & Conditions': 'Podmínky a ustanovení',
      LEGAL: 'PRÁVNÍ',
      'Privacy policy': 'Zásady ochrany soukromí',
      'Cancellation policy': 'Zásady zrušení',
      GDPR: 'GDPR',
      'All Rights Reserved': 'Všechna práva vyhrazena',
      'League [Category]': 'Liga [Kategorie]',
      'Other tournaments': 'Další turnaje',
      'Points Details': 'Detaily bodů',
      'International rating': 'Mezinárodní hodnocení',
      Day: 'Den',
      //////////// NEW ///////////////////////////////
      Days: 'Dny',
      Tennis: 'Tenis',
      'Beach Tennis': 'Plážový Tenis',
      'Beach Volleyball': 'Plážový Volejbal',
      Chess: 'Šachy',
      'In progress': 'Probíhá',
      Registration: 'Registrace',
      'Deadline past': 'Termín uplynul',
      Completed: 'Dokončeno',
      'No points yet': 'Zatím žádné body',
      'is confirmed': 'je potvrzeno',
      Contacts: 'Kontakty',
      Organizer: 'Organizátor',
      'Featured athletes': 'Doporučení sportovci',
      'Top-ranked participants of the tournament': 'Nejlépe hodnocení účastníci turnaje',
      NR: 'NR',
      PLAYER: 'HRÁČ',
      CITY: 'MĚSTO',
      RATING: 'HODNOCENÍ',
      CLUB: 'KLUB',
      TOTAL: 'CELKEM',
      SEGMENT: 'SEGMENT',
      SEED: 'SEMENO',
      Search: 'Vyhledávání',
      'No participants found': 'Nebyli nalezeni žádní účastníci',
      Total: 'Celkem',
      Seed: 'Semeno',
      Rating: 'Hodnocení',
      Rank: 'Pozice',
      'Category:': 'Kategorie:',
      'No category found': 'Nebyla nalezena žádná kategorie',
      'No Rounds found': 'Nebyly nalezeny žádné kola',
      White: 'Bílý',
      Black: 'Černý',
      Name: 'Jméno',
      Pts: 'Body',
      Score: 'Skóre',
      'There is no available pairings!': 'Nejsou k dispozici žádné páry!',
      'Generating PDF...': 'Generování PDF...',
      'PDF Generated Successfully!': 'PDF úspěšně vygenerováno!',
      'PDF Downloaded Successfully!': 'PDF úspěšně staženo!',
      'Downloading...': 'Stahování...',
      'Pairing Standings': 'Pořadí párů',
      Opponents: 'Soupeři',
      BHC1: 'BHC1',
      BHC: 'BHC',
      BGP: 'BGP',
      WIN: 'VÍTĚZSTVÍ',
      'There is no available entries!': 'Nejsou k dispozici žádné přihlášky!',
      "I'm a coach/parent": 'Jsem trenér/rodič',
      'Step 1': 'Krok 1',
      'Select category': 'Vyberte kategorii',
      Request: 'Žádost',
      'Register & Pay Now': 'Registrovat & Zaplatit nyní',
      'Register & Pay On-Site': 'Registrovat & Zaplatit na místě',
      'Terms And Conditions': 'Podmínky a ustanovení',
      'Latest Winners': 'Poslední vítězové',
      'No winners found': 'Nebyli nalezeni žádní vítězové',
      'No news found': 'Nebyly nalezeny žádné novinky',
      'No partners found': 'Nebyli nalezeni žádní partneři',
      'Skills Levels': 'Úrovně dovedností',
      'To get register for the tournament you must have active tour licence.': 'Pro registraci na turnaj musíte mít aktivní licenci.',
      'Contact Details': 'Kontaktní údaje',
      'Our Partners': 'Naši Partneři',
      'View all': 'Zobrazit vše',
      Member: 'Člen',
      'Team Name': 'Název týmu',
      News: 'Novinky',
      'Men Pro': 'Muži Pro',
      'Latvia Tennis Union League': 'Liga Lotyšského tenisového svazu',
      Leagues: 'Ligy',
      'No league found': 'Nebyly nalezeny žádné ligy',
      'Home Page': 'Domovská stránka',
      Upcoming: 'Nadcházející',
      'This month': 'Tento měsíc',
      'Last 7 days': 'Posledních 7 dní',
      'Last 30 days': 'Posledních 30 dní',
      Hours: 'Hodiny',
      Minutes: 'Minuty',
      Teams: 'Týmy',
      'No student found': 'Nebyli nalezeni žádní studenti',
      'Other news': 'Další novinky',
      'Don’t have an account?': 'Nemáte účet?',
      'Sign up': 'Zaregistrujte se',
      'or log in': 'nebo se přihlaste',
      'Did you forget your password?': 'Zapomněli jste heslo?',
      'Must be a valid email': 'Musí to být platný e-mail',
      'Email is required': 'E-mail je povinný',
      'Password is required': 'Heslo je povinné',
      Password: 'Heslo',
      'Remember me': 'Zapamatovat si mě',
      'Log In': 'Přihlásit se',
      'Unauthorized , please sign in': 'Neautorizováno, prosím přihlaste se',
      'Reset your password': 'Obnovte heslo',
      'Enter the e-mail address': 'Zadejte e-mailovou adresu',
      'Enter your e-mail address with which you are registered': 'Zadejte e-mailovou adresu, se kterou jste registrováni',
      'Please check your email for reset password link!': 'Zkontrolujte prosím svůj e-mail pro odkaz na resetování hesla!',
      'Send instructions': 'Odeslat pokyny',
      'My Licenes': 'Moje licence',
      'My leagues': 'Moje ligy',
      'My tournaments': 'Moje turnaje',
      'My matches': 'Moje zápasy',
      'My entries': 'Moje přihlášky',
      'My rankings': 'Moje žebříčky',
      Stats: 'Statistiky',
      'Leagues took part in': 'Ligy, kterých jste se účastnili',
      'Active licences': 'Aktivní licence',
      Titles: 'Tituly',
      'Matches played': 'Odehrané zápasy',
      'Matches lost': 'Prohrané zápasy',
      'Games/points played': 'Odehrané hry/body',
      'All sports': 'Všechny sporty',
      'All organizations': 'Všechny organizace',
      'All leagues': 'Všechny ligy',
      Registered: 'Registrován',
      Withdraw: 'Odstoupit',
      'Withdraw deadline passed': 'Lhůta pro odstoupení uplynula',
      'Request Withdrawn': 'Žádost stažena',
      'Something went wrong!': 'Něco se pokazilo!',
      Withdrawal: 'Odstoupení',
      'Entry to withdraw': 'Přihláška k odstoupení',
      'Withdrawal Reason': 'Důvod odstoupení',
      'Write your reason here': 'Zde napište důvod',
      Confirm: 'Potvrdit',
      'Please specify reason': 'Uveďte prosím důvod',
      'AUDI - The Beach Tennis Championship': 'AUDI - Mistrovství v plážovém tenise',
      'Get licence': 'Získat licenci',
      'Additional questions': 'Doplňující otázky',
      'Select duration': 'Vyberte dobu trvání',
      Career: 'Kariéra',
      'Third Party Licenes': 'Licence třetích stran',
      'User updated successfully': 'Uživatel úspěšně aktualizován',
      'User Account': 'Uživatelský účet',
      'We recommend an image that is 256 x 256 pixels': 'Doporučujeme obrázek o velikosti 256 x 256 pixelů',
      'Enter Email Address': 'Zadejte e-mailovou adresu',
      'Enter name': 'Zadejte jméno',
      Surname: 'Příjmení',
      'Enter surname': 'Zadejte příjmení',
      'Contact phone number': 'Kontaktní telefonní číslo',
      'Enter phone number': 'Zadejte telefonní číslo',
      'Date of Birth': 'Datum narození',
      'Date of birth': 'Datum narození',
      Gender: 'Pohlaví',
      'Select gender': 'Vyberte pohlaví',
      'Representing country': 'Reprezentující země',
      'Select country': 'Vyberte zemi',
      City: 'Město',
      'Select role(s)': 'Vyberte roli(e)',
      Address: 'Adresa',
      'Enter address': 'Zadejte adresu',
      'Parent found': 'Rodič nalezen',
      'Parent not found': 'Rodič nenalezen',
      'Removed coach': 'Trenér odstraněn',
      'Failed to removed coach': 'Nepodařilo se odstranit trenéra',
      'Coach created': 'Trenér vytvořen',
      'Failed to create coach': 'Nepodařilo se vytvořit trenéra',
      Club: 'Klub',
      Save: 'Uložit',
      'Select club': 'Vyberte klub',
      Coach: 'Trenér',
      'Select a coach': 'Vyberte trenéra',
      Parent: 'Rodič',
      'Enter email': 'Zadejte e-mail',
      Biography: 'Životopis',
      'Write a bio': 'Napište životopis',
      CHECKBOXES: 'ZAŠKRTÁVACÍ POLE',
      'Give access to coach to purchase licences and manage entries on your behalf': 'Dejte trenérovi přístup ke koupi licencí a správě přihlášek vaším jménem',
      'Give access to club manager(-es) to purchase licences and manage entries on your behalf': 'Dejte přístup klubovému manažerovi(-ům) ke koupi licencí a správě přihlášek vaším jménem',
      'Give access to parent(-s) to purchase licences and manage entries on your behalf': 'Dejte přístup rodiči(-ům) ke koupi licencí a správě přihlášek vaším jménem',
      Socials: 'Sociální sítě',
      'Insert link here': 'Vložte odkaz zde',
      Facebook: 'Facebook',
      Instagram: 'Instagram',
      Linkedin: 'Linkedin',
      Tiktok: 'Tiktok',
      Youtube: 'Youtube',
      'Enter Id': 'Zadejte ID',
      'Password should be 8 chars minimum.': 'Heslo musí mít minimálně 8 znaků.',
      'Passwords must match': 'Hesla se musí shodovat',
      'Password changed successfully': 'Heslo bylo úspěšně změněno',
      'New Password': 'Nové heslo',
      'Re-Enter': 'Zadejte znovu',
      'Name is Required': 'Jméno je povinné',
      'Surname is Required': 'Příjmení je povinné',
      'Re enter password': 'Zadejte heslo znovu',
      'User is already registered': 'Uživatel je již registrován',
      'Create an account': 'Vytvořit účet',
      'Already have an account?': 'Již máte účet?',
      'Log in': 'Přihlásit se',
      'or create account': 'nebo vytvořte účet',
      "I'm creating an account for a kid": 'Vytvářím účet pro dítě',
      'sign-up description':
        'Vytváříte účet pro účastníka turnaje. Pokud registrujete dítě, vytvořte prosím profil pro něj/ni. Profily trenérů a rodičů budou brzy dostupné, což umožní propojení s profilem dítěte.',
      'Email Address': 'E-mailová adresa',
      Continue: 'Pokračovat',
      'Pick a sport': 'Vyberte sport',
      'Pick a sport that you play': 'Vyberte sport, který hrajete',
      'Gender is required': 'Pohlaví je povinné',
      'Phone is required': 'Telefon je povinný',
      'Citizenship is required': 'Státní příslušnost je povinná',
      'Dob is required': 'Datum narození je povinný',
      'FIDE ID Not found': 'FIDE ID nebylo nalezeno',
      'FIDE ID found': 'FIDE ID nalezeno',
      'Tell us more about you': 'Řekněte nám více o sobě',
      'More information about yourself': 'Více informací o sobě',
      'Enter FIDE ID': 'Zadejte FIDE ID',
      'IPIN ID is correct': 'IPIN ID je správný',
      'Your Licenses': 'Vaše licence',
      'Attach your licenses': 'Přiložte své licence',
      Skip: 'Přeskočit',
      'User registered successfully': 'Uživatel byl úspěšně zaregistrován',
      'What is your primary role in the world of sports?': 'Jaká je vaše hlavní role ve světě sportu?',
      'Register & Go to profile': 'Zaregistrujte se a přejděte na profil',
      'PUBLISHED ON': 'PUBLIKOVÁNO DNE',
      'Federation member': 'Člen federace',
      'Club owner': 'Majitel klubu',
      'Club manager': 'Manažer klubu',
      Referee: 'Rozhodčí',
      'Tournament organizer': 'Organizátor turnaje',
      'Sport fan': 'Fanoušek sportu',
      Other: 'Jiné',
      PAY: 'PLATIT',
      '1 Year': '1 rok',
      '2 Years': '2 roky',
      '3 Years': '3 roky',
      '4 Years': '4 roky',
      'Registration time': 'Čas registrace',
      'In progress / Upcoming': 'Probíhá / Nadcházející',
      'Matches won': 'Vyhrané zápasy',
      'In progress / upcoming entries': 'Probíhá / nadcházející přihlášky',
      'In progress / upcoming tournaments': 'Probíhá / nadcházející turnaje',
      'Past tournaments': 'Minulé turnaje',
      'Past entries': 'Minulé přihlášky',
      'Your password must be different to previous passwords': 'Vaše heslo musí být odlišné od předchozích hesel',
      'less than a minute': 'méně než minuta',
      read: 'číst',
      minute: 'minuta',
      'Select Licence': 'Vyberte licenci',
      'Start time': 'Čas začátku',
      'Try again': 'Zkusit znovu',
      maintenanceMessage: 'Aktualizujeme platformu, což může trvat několik minut. Zkuste to prosím za chvíli znovu.',
      networkErrorMessage: 'Zkontrolujte prosím své připojení k síti a zkuste to znovu.',
      errorTitle: 'Oops! Něco je špatně!',
      'View List': 'Zobrazit seznam',
      Free: 'Zdarma',
      'Primary Sport': 'Primární sport',
      'Role(s)': 'Role',
      Menu: 'Nabídka',
      Calendar: 'Kalendář',
      'Manage tournament': 'Spravovat turnaj',
      'Partner search': 'Hledání partnera',
      'Partner Search': 'Hledání partnera',
      'Searching...': 'Vyhledávání...',
      Apply: 'Použít',
      Delete: 'Smazat',
      'Create application': 'Vytvořit žádost',
      'No partner search applications found': 'Nebyly nalezeny žádné žádosti o hledání partnera',
      'Log in to apply': 'Přihlaste se k žádosti',
      Player: 'Hráč',
      'Time of registration': 'Čas registrace',
      confirmed: 'potvrzeno',
      declined: 'odmítnuto',
      'payment pending': 'čekající platba',
      WITHDRAW: 'ODSTOUPIT',
      'No other tournament found': 'Nebyly nalezeny žádné jiné turnaje',
      welcomeTextCustom:
        'je poháněn Tournated, všestranným softwarem pro správu sportu, který umožňuje sportovním organizacím efektivně spravovat, růst a zpeněžovat své operace. Řešení umožňuje organizacím vytvářet vlastní online platformu, automatizovat a zjednodušovat správu lig, klubů, turnajů a dalších akcí, zvyšovat angažovanost publika a bezproblémově zpracovávat platby.',
      welcomeText:
        'výsledek pěti let výzkumu a vývoje a spolupráce s více než 200 organizacemi, je inovativním, přizpůsobitelným, uživatelsky přívětivým a cenově dostupným všestranným řešením pro sportovní organizace jakékoli velikosti',
      'Did you have an account in one of ': 'Měli jste účet na jedné z ',
      those: 'těch',
      'platforms?': 'platforem?',
      'Recover password': 'Obnovení hesla',
      newUser: 'Jsem nový uživatel. Registrovat',
      'Continue as a guest': 'Pokračovat jako host',
      Support: 'Podpora',
      'Welcome to': 'Vítejte na',
      'If you have an account on one of ': 'Pokud máte účet na jedné z ',
      these: 'těchto',
      'platforms, log in with your e-mail or create a new profile': 'platforem, přihlaste se pomocí svého e-mailu nebo vytvořte nový profil',
      'Reset password': 'Obnovení hesla',
      'Welcome to the': 'Vítejte na',
      platform: 'platformě',
      'Powered by': 'Poháněno',
      'All Categories': 'Všechny Kategorie',
      'Tournament Results': 'Výsledky Turnaje',
      versionText: 'Beta je aktivní. Prosím zašlete svou zpětnou vazbu',
      here: 'zde',
      'Full view': 'Plný náhled',
      Timeline: 'Časová osa',
      Grid: 'Mřížka',
      "Bird's View": 'Pohled z ptačí perspektivy',
      'Third Party Licences': 'Licence třetích stran',
      Friend: 'příteli',
      'Step 2': 'Krok 2:',
      'Step 3': 'Krok 3:',
      'Your Details': 'Vaše údaje',
      'Partner Details': 'Podrobnosti o partnerovi',
      'Enter partner email': 'Zadejte e-mail partnera',
      'Register & Pay': 'Zaregistrujte se a Zaplaťte',
      'On-Site': 'Na Místě',
      Now: 'Teď',
    },
  },
  et: {
    translation: {
      header: {
        discover: 'Avasta',
        enterName: 'Sisesta nimi',
        sportsManager: 'Spordijuht?',
        learnHow: 'Uuri, kuidas',
        accelerate: 'me saame kiirendada teie organisatsiooni.',
        powering: 'Tugevdades',
        tournaments: 'turniire,',
        leagues: 'liigasid',
        organizations: 'organisatsioone',
        clubs: 'klubisid',
        coaches: 'treenereid',
        placeholder: {
          tournaments: 'Sisesta turniiri nimi',
          leagues: 'Sisesta liiga nimi',
          organizations: 'Sisesta organisatsiooni nimi',
          clubs: 'Sisesta klubi nimi',
          coaches: 'Sisesta treeneri nimi',
        },
      },
      registrationOpen: 'Registreerimine avatud',
      read_more: 'Loe rohkem',
      registrationClosed: 'Registreerimine suletud',
      live: 'Otseülekanne',
      Close: 'Sulge',
      bannerHeading: 'Alusta oma turniiride haldamist täiesti tasuta',
      bannerDescription: 'Alates osalejate haldamisest kuni automaatse gruppide, looside ja mängude järjekorra loomise, tulemuste haldamise ja muuni',
      startNow: 'Alusta kohe',
      featureTournaments: 'Esiletõstetud turniirid',
      tournamentNotFound: 'Esiletõstetud turniire ei leitud',
      viewAll: 'Vaata kõiki',
      featureLeague: 'Esiletõstetud liigad',
      leaguesNotFound: 'Esiletõstetud liigasid ei leitud',
      Tournaments: 'Turniirid',
      NoTournamentsFound: 'Turniire ei leitud',
      Rankings: 'Edetabelid',
      noRankingFound: 'Edetabeleid ei leitud',
      news: 'Uudised',
      noNewsFound: 'Uudiseid ei leitud',
      partners: 'Meie partnerid',
      Partners: 'Partnerid',
      noPartnersFound: 'Partnereid ei leitud',
      worldwide: 'Ülemaailmselt',
      search: 'Otsi',
      pricing: 'Hinnakiri',
      loginRegister: 'Logi sisse / Registreeri',
      home: 'Avaleht',
      organizations: 'Organisatsioonid',
      leagues: 'Liigad',
      teams: 'Meeskonnad',
      clubs: 'Klubid',
      coaches: 'Treenerid',
      tennis: 'Tennis',
      beachTenis: 'Rannatennis',
      'Beach Volley': 'Rannavõrkpall',
      'Sport Fishing': 'Spordikalapüük',
      Volleyball: 'Võrkpall',
      Sports: 'Spordialad',
      RESOURSES: 'RESSURSID',
      Feature: 'Esiletõst',
      'Create account': 'Loo konto',
      Login: 'Logi sisse',
      Packages: 'Paketid',
      'Package one': 'Pakett üks',
      'Package two': 'Pakett kaks',
      'Package three': 'Pakett kolm',
      'Package Four': 'Pakett neli',
      'Customer suppor': 'Klienditugi',
      FAQ: 'KKK',
      Documentation: 'Dokumentatsioon',
      'Video tutorials': 'Videokoolitused',
      'Submit ticket': 'Esita pilet',
      'Contact us': 'Võta ühendust',
      'Our contacts': 'Meie kontaktid',
      Padel: 'Padel',
      Pickleball: 'Pickleball',
      'FEATURED LEAGUES': 'ESILETÕSTETUD LIIGAD',
      View: 'Vaata',
      'No leagues found': 'Liigasid ei leitud',
      Athletes: 'Sportlased',
      'Managed by': 'Haldab',
      'View League': 'Vaata liigat',
      Type: 'Tüüp',
      National: 'Riiklik',
      International: 'Rahvusvaheline',
      Local: 'Kohalik',
      Period: 'Periood',
      League: 'Liiga',
      Country: 'Riik',
      Year: 'Aasta',
      Points: 'Punktid',
      Position: 'Positsioon',
      Team: 'Meeskond',
      'No teams found': 'Meeskondi ei leitud',
      'Team Members': 'Meeskonnaliikmed',
      Sport: 'Spordiala',
      'No clubs found': 'Klubisid ei leitud',
      Experience: 'Kogemus',
      Students: 'Õpilased',
      Author: 'Autor',
      Category: 'Kategooria',
      'My Entries': 'Minu kirjed',
      'My Leagues': 'Minu liigad',
      'My Licences': 'Minu litsentsid',
      'My Teams': 'Minu meeskonnad',
      'Get Licence': 'Hangi litsents',
      demo: 'demo',
      Settings: 'Seaded',
      'Log out': 'Logi välja',
      'Dark view': 'Tume vaade',
      Manager: 'Manager',
      Tournament: 'Turniir',
      Cost: 'Maksumus',
      Duration: 'Kestus',
      'Purchase on': 'Osta',
      'No license found': 'Litsentsi ei leitud',
      Additional: 'Täiendav',
      Status: 'Staatus',
      Date: 'Kuupäev',
      'No entries found': 'Kirjeid ei leitud',
      'My Licenses': 'Minu litsentsid',
      'Add new License': 'Lisa uus litsents',
      'Create Team': 'Loo meeskond',
      Info: 'Teave',
      infoDes: 'Teatud liigades / turniiridel osalemiseks peab mängijal olema vastav aktiivne litsents.',
      'Select League': 'Vali liiga',
      'Select License': 'Vali litsents',
      'Promo code': 'Sooduskood',
      'I agree to the': 'Nõustun',
      'Terms and Conditions': 'tingimustega',
      Price: 'Hind',
      'Confirm Payment': 'Kinnita makse',
      History: 'Ajalugu',
      'Tournated Manager': 'Tournated Manager',
      'Tournated Custom': 'Tournated Custom',
      'Customer support': 'KLIENDITUGI',
      Categories: 'Kategooriad',
      Matches: 'Matšid',
      'Organized by': 'Korraldab',
      Register: 'Registreeru',
      Entries: 'Kirjed',
      Location: 'Asukoht',
      'National Tour': 'Riiklik tuur',
      '4 mins read': '4 min lugemist',
      'Age group': 'Vanuserühm',
      'Skills level': 'Oskuste tase',
      someDes: 'SIA Vertexo © 2020. Kõik õigused kaitstud',
      'Manage in CMS': 'Halda CMS-is',
      'Fact Sheet': 'Faktileht',
      General: 'Üldine',
      Participants: 'Osalejad',
      Groups: 'Grupid',
      Draws: 'Loosid',
      'Live Standings': 'Otseülekande tabelid',
      'Order of play': 'Mängude järjekord',
      Pairings: 'Paarid',
      Standings: 'Tabeliseisud',
      'Final standings': 'Lõplik tabeliseis',
      'List & Results': 'Nimekiri ja tulemused',
      'Entry Fee': 'Osavõtutasu',
      'Entry Deadline': 'Osavõtutähtaeg',
      'Entry deadline': 'Osavõtutähtaeg',
      'Withdrawal deadline': 'Loobumistähtaeg',
      'Start date': 'Alguskuupäev',
      'End date': 'Lõpukuupäev',
      and: 'ja',
      others: 'teised',
      'are confirmed': 'on kinnitatud',
      Description: 'Kirjeldus',
      'Tournament Poster': 'Turniiri plakat',
      'More Info': 'Rohkem infot',
      'Additional materials about tournament': 'Täiendavad materjalid turniiri kohta',
      Regulations: 'Eeskirjad',
      'Tournaments Discord': 'Turniiride Discord',
      discordDes: 'Räägi otse turniirihalduritega, küsi küsimusi ja vestle teiste mängijatega!',
      'Join Channel': 'Liitu kanaliga',
      'Fact sheet': 'Faktileht',
      'Search hotels and more': 'Otsi hotelle ja muud...',
      Destination: 'Sihtkoht',
      'Select Destination': 'Vali sihtkoht',
      'Check in date': 'Sisseregistreerimise kuupäev',
      'Check out date': 'Väljumise kuupäev',
      'Best players on this tournament': 'Selle turniiri parimad mängijad',
      Gallery: 'Galerii',
      'Recent photos': 'Hiljutised fotod',
      'Buy licence': 'Osta litsents',
      'Select All': 'Vali kõik',
      "Don't see your sport? Contact us": 'Ei leia oma spordiala? Võta meiega ühendust',
      'Description is missing': 'Kirjeldus puudub',
      day: 'päev',
      days: 'päeva',
      Organization: 'Organisatsioon',
      Athlete: 'Sportlane',
      'Birth year': 'Sünniaasta',
      Joined: 'Liitus',
      'Select Sport': 'Vali spordiala',
      'Select organization': 'Vali organisatsioon',
      'Select league': 'Vali liiga',
      Overview: 'Ülevaade',
      About: 'Teave',
      'Win rate': 'Võiduprotsent',
      Achievements: 'Saavutused',
      'Upcoming Matches': 'Eelseisvad matšid',
      'Past Matches': 'Möödunud matšid',
      'Upcoming Tournaments': 'Eelseisvad turniirid',
      'Purchase history': 'Ostuajalugu',
      'description and detail': 'Paranda: Hankige osalejatele vajalikud litsentsid / liikmelisused teatud liigades või turniiridel osalemiseks',
      'Purchase datetime': 'Ostu kuupäev ja kellaaeg',
      'Payment Status': 'Makse staatus',
      Licence: 'Litsents',
      Members: 'Liikmed',
      Student: 'Õpilane',
      'Personal page': 'Isiklik leht',
      'My Career': 'Minu karjäär',
      'Latest tournaments': 'Viimased turniirid',
      'Upcoming tournaments': 'Eelseisvad turniirid',
      'No members found': 'Liikmeid ei leitud',
      'No tournaments found': 'Turniire ei leitud',
      'No matches found': 'Matše ei leitud',
      'No rankings found': 'Edetabeleid ei leitud',
      'No past matches found': 'Möödunud matše ei leitud',
      'No upcoming matches found': 'Eelseisvaid matše ei leitud',
      'No achievements found': 'Saavutusi ei leitud',
      Documents: 'Dokumendid',
      Contact: 'Kontakt',
      'No photos added': 'Fotosid ei lisatud',
      'Manage league': 'Halda liigat',
      'No documents added': 'Dokumente ei lisatud',
      Email: 'E-post',
      Phone: 'Telefon',
      Website: 'Veebisait',
      Place: 'Koht',
      'Tournament Name': 'Turniiri nimi',
      'Manage organization': 'Halda organisatsiooni',
      'Terms & Conditions': 'Tingimused',
      LEGAL: 'JURIIDILINE',
      'Privacy policy': 'Privaatsuspoliitika',
      'Cancellation policy': 'Tühistamispoliitika',
      GDPR: 'GDPR',
      'All Rights Reserved': 'Kõik õigused kaitstud',
      'League [Category]': 'Liiga [Kategooria]',
      'Other tournaments': 'Teised turniirid',
      'Points Details': 'Punktide detailid',
      'International rating': 'Rahvusvaheline reiting',
      Day: 'Päev',
      //////////// NEW ///////////////////////////////
      Days: 'Päevad',
      Tennis: 'Tennis',
      'Beach Tennis': 'Rannatennis',
      'Beach Volleyball': 'Rannavõrkpall',
      Chess: 'Male',
      'In progress': 'Töös',
      Registration: 'Registreerimine',
      'Deadline past': 'Tähtpäev möödas',
      Completed: 'Lõpetatud',
      'No points yet': 'Punkte pole veel',
      'is confirmed': 'on kinnitatud',
      Contacts: 'Kontaktid',
      Organizer: 'Korraldaja',
      'Featured athletes': 'Esiletõstetud sportlased',
      'Top-ranked participants of the tournament': 'Turniiri kõrgeima reitinguga osalejad',
      NR: 'NR',
      PLAYER: 'MÄNGIJA',
      CITY: 'LINN',
      RATING: 'REITING',
      CLUB: 'KLUBI',
      TOTAL: 'KOKKU',
      SEGMENT: 'SEGMENT',
      SEED: 'PAREMUSJÄRJESTUS',
      Search: 'Otsi',
      'No participants found': 'Osalejaid ei leitud',
      Total: 'Kokku',
      Seed: 'Paremusjärjestus',
      Rating: 'Reiting',
      Rank: 'Koht',
      'Category:': 'Kategooria:',
      'No category found': 'Kategooriat ei leitud',
      'No Rounds found': 'Voorusid ei leitud',
      White: 'Valge',
      Black: 'Must',
      Name: 'Nimi',
      Pts: 'Punktid',
      Score: 'Tulemus',
      'There is no available pairings!': 'Paare pole saadaval!',
      'Generating PDF...': 'PDF genereerimine...',
      'PDF Generated Successfully!': 'PDF edukalt genereeritud!',
      'PDF Downloaded Successfully!': 'PDF edukalt allalaaditud!',
      'Downloading...': 'Allalaadimine...',
      'Pairing Standings': 'Paaride tabeliseis',
      Opponents: 'Vastased',
      BHC1: 'BHC1',
      BHC: 'BHC',
      BGP: 'BGP',
      WIN: 'VÕIT',
      'There is no available entries!': 'Kirjeid pole saadaval!',
      "I'm a coach/parent": 'Olen treener/vanem',
      'Step 1': 'Samm 1',
      'Select category': 'Vali kategooria',
      Request: 'Päring',
      'Register & Pay Now': 'Registreeru ja maksa kohe',
      'Register & Pay On-Site': 'Registreeru ja maksa kohapeal',
      'Terms And Conditions': 'Tingimused',
      'Latest Winners': 'Viimased võitjad',
      'No winners found': 'Võitjaid ei leitud',
      'No news found': 'Uudiseid ei leitud',
      'No partners found': 'Partnereid ei leitud',
      'Skills Levels': 'Oskuste tasemed',
      'To get register for the tournament you must have active tour licence.': 'Turniirile registreerumiseks peab teil olema kehtiv tuurilitsents.',
      'Contact Details': 'Kontaktiandmed',
      'Our Partners': 'Meie partnerid',
      'View all': 'Vaata kõiki',
      Member: 'Liige',
      'Team Name': 'Meeskonna nimi',
      News: 'Uudised',
      'Men Pro': 'Mehed Pro',
      'Latvia Tennis Union League': 'Läti Tenniseliit',
      Leagues: 'Liigad',
      'No league found': 'Liigat ei leitud',
      'Home Page': 'Avaleht',
      Upcoming: 'Tulemas',
      'This month': 'Sel kuul',
      'Last 7 days': 'Viimased 7 päeva',
      'Last 30 days': 'Viimased 30 päeva',
      Hours: 'Tunnid',
      Minutes: 'Minutid',
      Teams: 'Meeskonnad',
      'No student found': 'Õpilasi ei leitud',
      'Other news': 'Muud uudised',
      'Don’t have an account?': 'Kontot pole?',
      'Sign up': 'Registreeru',
      'or log in': 'või logi sisse',
      'Did you forget your password?': 'Kas unustasid parooli?',
      'Must be a valid email': 'Peab olema kehtiv e-posti aadress',
      'Email is required': 'E-post on vajalik',
      'Password is required': 'Parool on vajalik',
      Password: 'Parool',
      'Remember me': 'Mäleta mind',
      'Log In': 'Logi sisse',
      'Unauthorized , please sign in': 'Pole volitatud, palun logi sisse',
      'Reset your password': 'Lähtesta oma parool',
      'Enter the e-mail address': 'Sisesta e-posti aadress',
      'Enter your e-mail address with which you are registered': 'Sisesta e-posti aadress, millega oled registreeritud',
      'Please check your email for reset password link!': 'Palun kontrolli oma e-posti parooli lähtestamise lingi saamiseks!',
      'Send instructions': 'Saada juhised',
      'My Licenes': 'Minu litsentsid',
      'My leagues': 'Minu liigad',
      'My tournaments': 'Minu turniirid',
      'My matches': 'Minu matšid',
      'My entries': 'Minu kirjed',
      'My rankings': 'Minu edetabelid',
      Stats: 'Statistika',
      'Leagues took part in': 'Osalenud liigad',
      'Active licences': 'Aktiivsed litsentsid',
      Titles: 'Tiitlid',
      'Matches played': 'Mängitud matšid',
      'Matches lost': 'Kaotatud matšid',
      'Games/points played': 'Mängitud mängud/punktid',
      'All sports': 'Kõik spordialad',
      'All organizations': 'Kõik organisatsioonid',
      'All leagues': 'Kõik liigad',
      Registered: 'Registreeritud',
      Withdraw: 'Loobu',
      'Withdraw deadline passed': 'Loobumise tähtaeg möödas',
      'Request Withdrawn': 'Päring tühistatud',
      'Something went wrong!': 'Midagi läks valesti!',
      Withdrawal: 'Loobumine',
      'Entry to withdraw': 'Loobumiseks kirje',
      'Withdrawal Reason': 'Loobumise põhjus',
      'Write your reason here': 'Kirjuta siia oma põhjus',
      Confirm: 'Kinnita',
      'Please specify reason': 'Palun täpsusta põhjust',
      'AUDI - The Beach Tennis Championship': 'AUDI - Rannatennise meistrivõistlused',
      'Get licence': 'Hangi litsents',
      'Additional questions': 'Täiendavad küsimused',
      'Select duration': 'Vali kestus',
      Career: 'Karjäär',
      'Third Party Licenes': 'Kolmanda osapoole litsentsid',
      'User updated successfully': 'Kasutaja edukalt uuendatud',
      'User Account': 'Kasutajakonto',
      'We recommend an image that is 256 x 256 pixels': 'Soovitame pilti mõõtudega 256 x 256 pikslit',
      'Enter Email Address': 'Sisesta e-posti aadress',
      'Enter name': 'Sisesta nimi',
      Surname: 'Perekonnanimi',
      'Enter surname': 'Sisesta perekonnanimi',
      'Contact phone number': 'Kontakttelefoni number',
      'Enter phone number': 'Sisesta telefoninumber',
      'Date of Birth': 'Sünnikuupäev',
      'Date of birth': 'Sünnikuupäev',
      Gender: 'Sugu',
      'Select gender': 'Vali sugu',
      'Representing country': 'Esindatav riik',
      'Select country': 'Vali riik',
      City: 'Linn',
      'Select role(s)': 'Vali roll(id)',
      Address: 'Aadress',
      'Enter address': 'Sisesta aadress',
      'Parent found': 'Vanem leitud',
      'Parent not found': 'Vanemat ei leitud',
      'Removed coach': 'Treener eemaldatud',
      'Failed to removed coach': 'Treeneri eemaldamine ebaõnnestus',
      'Coach created': 'Treener loodud',
      'Failed to create coach': 'Treeneri loomine ebaõnnestus',
      Club: 'Klubi',
      Save: 'Salvesta',
      'Select club': 'Vali klubi',
      Coach: 'Treener',
      'Select a coach': 'Vali treener',
      Parent: 'Vanem',
      'Enter email': 'Sisesta e-post',
      Biography: 'Biograafia',
      'Write a bio': 'Kirjuta biograafia',
      CHECKBOXES: 'MÄRKEKASTID',
      'Give access to coach to purchase licences and manage entries on your behalf': 'Anna treenerile juurdepääs litsentside ostmiseks ja kirjete haldamiseks sinu nimel',
      'Give access to club manager(-es) to purchase licences and manage entries on your behalf': 'Anna klubi juhile juurdepääs litsentside ostmiseks ja kirjete haldamiseks sinu nimel',
      'Give access to parent(-s) to purchase licences and manage entries on your behalf': 'Anna vanemale juurdepääs litsentside ostmiseks ja kirjete haldamiseks sinu nimel',
      Socials: 'Sotsiaalmeedia',
      'Insert link here': 'Sisesta link siia',
      Facebook: 'Facebook',
      Instagram: 'Instagram',
      Linkedin: 'Linkedin',
      Tiktok: 'Tiktok',
      Youtube: 'Youtube',
      'Enter Id': 'Sisesta ID',
      'Password should be 8 chars minimum.': 'Parool peab olema vähemalt 8 tähemärki.',
      'Passwords must match': 'Paroolid peavad kattuma',
      'Password changed successfully': 'Parool edukalt muudetud',
      'New Password': 'Uus parool',
      'Re-Enter': 'Kinnita parool',
      'Name is Required': 'Nimi on kohustuslik',
      'Surname is Required': 'Perekonnanimi on kohustuslik',
      'Re enter password': 'Sisesta parool uuesti',
      'User is already registered': 'Kasutaja on juba registreeritud',
      'Create an account': 'Loo konto',
      'Already have an account?': 'Juba on konto?',
      'Log in': 'Logi sisse',
      'or create account': 'või loo konto',
      "I'm creating an account for a kid": 'Loon konto lapse jaoks',
      'sign-up description': 'Lood konto turniiril osalejale. Kui registreerid last, loo tema profiil. Treeneri ja vanema profiilid on peagi saadaval, võimaldades sul linkida lapse profiili.',
      'Email Address': 'E-posti aadress',
      Continue: 'Jätka',
      'Pick a sport': 'Vali spordiala',
      'Pick a sport that you play': 'Vali spordiala, mida mängid',
      'Gender is required': 'Sugu on kohustuslik',
      'Phone is required': 'Telefon on kohustuslik',
      'Citizenship is required': 'Riik on kohustuslik',
      'Dob is required': 'Sünniaeg on kohustuslik',
      'FIDE ID Not found': 'FIDE ID-d ei leitud',
      'FIDE ID found': 'FIDE ID leitud',
      'Tell us more about you': 'Räägi meile rohkem endast',
      'More information about yourself': 'Rohkem teavet sinu kohta',
      'Enter FIDE ID': 'Sisesta FIDE ID',
      'IPIN ID is correct': 'IPIN ID on õige',
      'Your Licenses': 'Sinu litsentsid',
      'Attach your licenses': 'Lisa oma litsentsid',
      Skip: 'Jäta vahele',
      'User registered successfully': 'Kasutaja edukalt registreeritud',
      'What is your primary role in the world of sports?': 'Mis on sinu peamine roll spordimaailmas?',
      'Register & Go to profile': 'Registreeru ja mine profiilile',
      'PUBLISHED ON': 'AVALDATUD',
      'Federation member': 'Föderatsiooni liige',
      'Club owner': 'Klubi omanik',
      'Club manager': 'Klubi juhataja',
      Referee: 'Kohtunik',
      'Tournament organizer': 'Turniiri korraldaja',
      'Sport fan': 'Spordifänn',
      Other: 'Muu',
      PAY: 'MAKSA',
      '1 Year': '1 aasta',
      '2 Years': '2 aastat',
      '3 Years': '3 aastat',
      '4 Years': '4 aastat',
      'Registration time': 'Registreerimisaeg',
      'In progress / Upcoming': 'Töös / Tulemas',
      'Matches won': 'Võidetud matšid',
      'In progress / upcoming entries': 'Töös / Tulemas kirjed',
      'In progress / upcoming tournaments': 'Töös / Tulemas turniirid',
      'Past tournaments': 'Möödunud turniirid',
      'Past entries': 'Möödunud kirjed',
      'Your password must be different to previous passwords': 'Sinu parool peab erinema varasematest paroolidest',
      'less than a minute': 'vähem kui minut',
      read: 'loe',
      minute: 'minut',
      'Select Licence': 'Vali litsents',
      'Start time': 'Algusaeg',
      'Try again': 'Proovi uuesti',
      maintenanceMessage: 'Me uuendame platvormi, mis võib võtta mõne minuti. Palun proovi varsti uuesti.',
      networkErrorMessage: 'Palun kontrolli oma võrguga ühendust ja proovi uuesti.',
      errorTitle: 'Oih! Midagi läks valesti!',
      'View List': 'Vaata nimekirja',
      Free: 'Tasuta',
      'Primary Sport': 'Peamine spordiala',
      'Role(s)': 'Roll(id)',
      Menu: 'Menüü',
      Calendar: 'Kalender',
      'Manage tournament': 'Halda turniiri',
      'Partner search': 'Partneri otsing',
      'Partner Search': 'Partneri otsing',
      'Searching...': 'Otsing...',
      Apply: 'Kandideeri',
      Delete: 'Kustuta',
      'Create application': 'Loo taotlus',
      'No partner search applications found': 'Partneri otsingu taotlusi ei leitud',
      'Log in to apply': 'Kandideerimiseks logi sisse',
      Player: 'Mängija',
      'Time of registration': 'Registreerimise aeg',
      confirmed: 'kinnitatud',
      declined: 'keeldutud',
      'payment pending': 'makse ootel',
      WITHDRAW: 'LOOBU',
      'No other tournament found': 'Teisi turniire ei leitud',
      welcomeTextCustom:
        'on Tournatedi toetatud, mis on kõikehõlmav spordihaldustarkvara, mis võimaldab spordiorganisatsioonidel tõhusalt hallata, kasvada ja teenida oma tegevust. Lahendus võimaldab organisatsioonidel luua kohandatud veebiplatvorme, automatiseerida ja lihtsustada liigade, klubide, turniiride ja muude sündmuste haldamist, suurendada publiku kaasatust ja töödelda makseid vaevata.',
      welcomeText:
        'viie aasta teadus- ja arendustöö tulemus, koostöös enam kui 200 organisatsiooniga, on uuenduslik, kohandatav, kasutajasõbralik ja taskukohane kõik-ühes lahendus igas suuruses spordiorganisatsioonidele',
      'Did you have an account in one of ': 'Kas sul oli konto ühes ',
      those: 'neist',
      'platforms?': 'platvormidest?',
      'Recover password': 'Taasta parool',
      newUser: 'Olen uus kasutaja. Registreeru',
      'Continue as a guest': 'Jätka külalisena',
      Support: 'Tugi',
      'Welcome to': 'Tere tulemast',
      'If you have an account on one of ': 'Kui sul on konto ühes ',
      these: 'nendest',
      'platforms, log in with your e-mail or create a new profile': 'platvormidest, logi sisse oma e-posti aadressiga või loo uus profiil',
      'Reset password': 'Lähtesta parool',
      'Welcome to the': 'Tere tulemast',
      platform: 'platvormile',
      'Powered by': 'Toetatud',
      'All Categories': 'Kõik kategooriad',
      'Tournament Results': 'Turniiri tulemused',
      versionText: 'Beeta on käivitatud. Palun esitage oma tagasiside',
      here: 'siin',
      'Full view': 'Täisvaade',
      Timeline: 'Ajajoon',
      Grid: 'Võrk',
      "Bird's View": 'Linnulennult vaade',
      'Third Party Licences': 'Kolmanda osapoole litsentsid',
      Friend: 'Sõber',
      'Step 2': 'Samm 2:',
      'Step 3': 'Samm 3:',
      'Your Details': 'Sinu andmed',
      'Partner Details': 'Partneri andmed',
      'Enter partner email': 'Sisesta partneri e-post',
      'Register & Pay': `Registreeru ja maksa`,
      'On-Site': 'Kohapeal',
      Now: 'Nüüd',
    },
  },
  lt: {
    translation: {
      header: {
        discover: 'Atrask',
        enterName: 'Įveskite vardą',
        sportsManager: 'Sporto vadybininkas?',
        learnHow: 'Sužinokite, kaip',
        accelerate: 'mes galime pagreitinti jūsų organizaciją.',
        powering: 'Varant',
        tournaments: 'turnyrus,',
        leagues: 'lygas',
        organizations: 'organizacijas',
        clubs: 'klubus',
        coaches: 'trenerius',
        placeholder: {
          tournaments: 'Įveskite turnyro pavadinimą',
          leagues: 'Įveskite lygos pavadinimą',
          organizations: 'Įveskite organizacijos pavadinimą',
          clubs: 'Įveskite klubo pavadinimą',
          coaches: 'Įveskite trenerio vardą',
        },
      },
      registrationOpen: 'Registracija atidaryta',
      read_more: 'Skaityti daugiau',
      registrationClosed: 'Registracija uždaryta',
      live: 'Tiesiogiai',
      Close: 'Uždaryti',
      bannerHeading: 'Pradėkite valdyti savo turnyrus visiškai nemokamai',
      bannerDescription: 'Nuo dalyvių valdymo iki automatizuoto grupių, burtų ir žaidimų tvarkos generavimo, rezultatų valdymo ir dar daugiau',
      startNow: 'Pradėti dabar',
      featureTournaments: 'Pasiūlyti turnyrai',
      tournamentNotFound: 'Pasiūlytų turnyrų nerasta',
      viewAll: 'Peržiūrėti visus',
      featureLeague: 'Pasiūlytos lygos',
      leaguesNotFound: 'Pasiūlytų lygų nerasta',
      Tournaments: 'Turnyrai',
      NoTournamentsFound: 'Turnyrų nerasta',
      Rankings: 'Reitingai',
      noRankingFound: 'Reitingų nerasta',
      news: 'Naujienos',
      noNewsFound: 'Naujienų nerasta',
      partners: 'Mūsų partneriai',
      Partners: 'Partneriai',
      noPartnersFound: 'Partnerių nerasta',
      worldwide: 'Visame pasaulyje',
      search: 'Ieškoti',
      pricing: 'Kainos',
      loginRegister: 'Prisijungti / Registruotis',
      home: 'Pagrindinis',
      organizations: 'Organizacijos',
      leagues: 'Lygos',
      teams: 'Komandos',
      clubs: 'Klubai',
      coaches: 'Treneriai',
      tennis: 'Tenisas',
      beachTenis: 'Paplūdimio tenisas',
      'Beach Volley': 'Paplūdimio tinklinis',
      'Sport Fishing': 'Sportinė žūklė',
      Volleyball: 'Tinklinis',
      Sports: 'Sportai',
      RESOURSES: 'IŠTEKLIAI',
      Feature: 'Pasiūlyti',
      'Create account': 'Sukurti paskyrą',
      Login: 'Prisijungti',
      Packages: 'Paketai',
      'Package one': 'Paketas vienas',
      'Package two': 'Paketas du',
      'Package three': 'Paketas trys',
      'Package Four': 'Paketas keturi',
      'Customer suppor': 'Klientų palaikymas',
      FAQ: 'DUK',
      Documentation: 'Dokumentacija',
      'Video tutorials': 'Vaizdo pamokos',
      'Submit ticket': 'Pateikti bilietą',
      'Contact us': 'Susisiekite su mumis',
      'Our contacts': 'Mūsų kontaktai',
      Padel: 'Padelis',
      Pickleball: 'Pickleball',
      'FEATURED LEAGUES': 'PASIŪLYTOS LYGOS',
      View: 'Peržiūrėti',
      'No leagues found': 'Lygų nerasta',
      Athletes: 'Sportininkai',
      'Managed by': 'Valdo',
      'View League': 'Peržiūrėti lygą',
      Type: 'Tipas',
      National: 'Nacionalinis',
      International: 'Tarptautinis',
      Local: 'Vietinis',
      Period: 'Laikotarpis',
      League: 'Lyga',
      Country: 'Šalis',
      Year: 'Metai',
      Points: 'Taškai',
      Position: 'Pozicija',
      Team: 'Komanda',
      'No teams found': 'Komandų nerasta',
      'Team Members': 'Komandos nariai',
      Sport: 'Sportas',
      'No clubs found': 'Klubų nerasta',
      Experience: 'Patirtis',
      Students: 'Studentai',
      Author: 'Autorius',
      Category: 'Kategorija',
      'My Entries': 'Mano įrašai',
      'My Leagues': 'Mano lygos',
      'My Licences': 'Mano licencijos',
      'My Teams': 'Mano komandos',
      'Get Licence': 'Gauti licenciją',
      demo: 'demo',
      Settings: 'Nustatymai',
      'Log out': 'Atsijungti',
      'Dark view': 'Tamsi peržiūra',
      Manager: 'Vadybininkas',
      Tournament: 'Turnyras',
      Cost: 'Kaina',
      Duration: 'Trukmė',
      'Purchase on': 'Pirkti',
      'No license found': 'Licencijos nerasta',
      Additional: 'Papildoma',
      Status: 'Būsena',
      Date: 'Data',
      'No entries found': 'Įrašų nerasta',
      'My Licenses': 'Mano licencijos',
      'Add new License': 'Pridėti naują licenciją',
      'Create Team': 'Sukurti komandą',
      Info: 'Informacija',
      infoDes: 'Norint dalyvauti tam tikrose lygose / turuose, žaidėjas turi turėti aktyvią licenciją.',
      'Select League': 'Pasirinkti lygą',
      'Select License': 'Pasirinkti licenciją',
      'Promo code': 'Nuolaidos kodas',
      'I agree to the': 'Sutinku su',
      'Terms and Conditions': 'taisyklių ir sąlygų',
      Price: 'Kaina',
      'Confirm Payment': 'Patvirtinti mokėjimą',
      History: 'Istorija',
      'Tournated Manager': 'Tournated vadybininkas',
      'Tournated Custom': 'Tournated pagal užsakymą',
      'Customer support': 'KLIENTŲ APTARNAVIMAS',
      Categories: 'Kategorijos',
      Matches: 'Varžybos',
      'Organized by': 'Organizavo',
      Register: 'Registruotis',
      Entries: 'Įrašai',
      Location: 'Vieta',
      'National Tour': 'Nacionalinis turas',
      '4 mins read': '4 min skaitymo',
      'Age group': 'Amžiaus grupė',
      'Skills level': 'Įgūdžių lygis',
      someDes: 'SIA Vertexo © 2020. Visos teisės saugomos',
      'Manage in CMS': 'Tvarkyti CMS sistemoje',
      'Fact Sheet': 'Faktų lapas',
      General: 'Bendra informacija',
      Participants: 'Dalyviai',
      Groups: 'Grupės',
      Draws: 'Burtai',
      'Live Standings': 'Tiesioginės vietos',
      'Order of play': 'Žaidimo tvarka',
      Pairings: 'Poros',
      Standings: 'Reitingai',
      'Final standings': 'Galutiniai reitingai',
      'List & Results': 'Sąrašas ir rezultatai',
      'Entry Fee': 'Įėjimo mokestis',
      'Entry Deadline': 'Registracijos terminas',
      'Entry deadline': 'Registracijos terminas',
      'Withdrawal deadline': 'Atsisakymo terminas',
      'Start date': 'Pradžios data',
      'End date': 'Pabaigos data',
      and: 'ir',
      others: 'kiti',
      'are confirmed': 'patvirtinti',
      Description: 'Aprašymas',
      'Tournament Poster': 'Turnyro plakatas',
      'More Info': 'Daugiau informacijos',
      'Additional materials about tournament': 'Papildoma medžiaga apie turnyrą',
      Regulations: 'Reglamentas',
      'Tournaments Discord': 'Turnyrų Discord',
      discordDes: 'Bendraukite tiesiogiai su turnyro administratoriais, užduokite klausimus ir bendraukite su kitais žaidėjais!',
      'Join Channel': 'Prisijunkite prie kanalo',
      'Fact sheet': 'Faktų lapas',
      'Search hotels and more': 'Ieškoti viešbučių ir dar daugiau...',
      Destination: 'Kelionės tikslas',
      'Select Destination': 'Pasirinkite kelionės tikslą',
      'Check in date': 'Atvykimo data',
      'Check out date': 'Išvykimo data',
      'Best players on this tournament': 'Geriausi šio turnyro žaidėjai',
      Gallery: 'Galerija',
      'Recent photos': 'Naujausios nuotraukos',
      'Buy licence': 'Pirkti licenciją',
      'Select All': 'Pasirinkti viską',
      "Don't see your sport? Contact us": 'Nerandate savo sporto šakos? Susisiekite su mumis',
      'Description is missing': 'Aprašymo nėra',
      day: 'diena',
      days: 'dienos',
      Organization: 'Organizacija',
      Athlete: 'Sportininkas',
      'Birth year': 'Gimimo metai',
      Joined: 'Prisijungė',
      'Select Sport': 'Pasirinkti sportą',
      'Select organization': 'Pasirinkti organizaciją',
      'Select league': 'Pasirinkti lygą',
      Overview: 'Apžvalga',
      About: 'Apie',
      'Win rate': 'Laimėjimų procentas',
      Achievements: 'Pasiekimai',
      'Upcoming Matches': 'Būsimos varžybos',
      'Past Matches': 'Praėjusios varžybos',
      'Upcoming Tournaments': 'Būsimi turnyrai',
      'Purchase history': 'Pirkimų istorija',
      'description and detail': 'Pagerinti: Įsigykite licencijas / narystes, reikalingas dalyvavimui tam tikrose lygose ar turnyruose',
      'Purchase datetime': 'Pirkimo data ir laikas',
      'Payment Status': 'Mokėjimo būsena',
      Licence: 'Licencija',
      Members: 'Nariai',
      Student: 'Studentas',
      'Personal page': 'Asmeninis puslapis',
      'My Career': 'Mano karjera',
      'Latest tournaments': 'Naujausi turnyrai',
      'Upcoming tournaments': 'Būsimi turnyrai',
      'No members found': 'Narių nerasta',
      'No tournaments found': 'Turnyrų nerasta',
      'No matches found': 'Varžybų nerasta',
      'No rankings found': 'Reitingų nerasta',
      'No past matches found': 'Praėjusių varžybų nerasta',
      'No upcoming matches found': 'Būsimų varžybų nerasta',
      'No achievements found': 'Pasiekimų nerasta',
      Documents: 'Dokumentai',
      Contact: 'Kontaktai',
      'No photos added': 'Nuotraukų nepridėta',
      'Manage league': 'Tvarkyti lygą',
      'No documents added': 'Dokumentų nepridėta',
      Email: 'El. paštas',
      Phone: 'Telefonas',
      Website: 'Svetainė',
      Place: 'Vieta',
      'Tournament Name': 'Turnyro pavadinimas',
      'Manage organization': 'Tvarkyti organizaciją',
      'Terms & Conditions': 'Taisyklės ir sąlygos',
      LEGAL: 'TEISINIS',
      'Privacy policy': 'Privatumo politika',
      'Cancellation policy': 'Atšaukimo politika',
      GDPR: 'GDPR',
      'All Rights Reserved': 'Visos teisės saugomos',
      'League [Category]': 'Lyga [Kategorija]',
      'Other tournaments': 'Kiti turnyrai',
      'Points Details': 'Taškų detalės',
      'International rating': 'Tarptautinis reitingas',
      Day: 'Diena',
      //////////// NEW ///////////////////////////////
      Days: 'Dienos',
      Tennis: 'Tenisas',
      'Beach Tennis': 'Paplūdimio tenisas',
      'Beach Volleyball': 'Paplūdimio tinklinis',
      Chess: 'Šachmatai',
      'In progress': 'Vyksta',
      Registration: 'Registracija',
      'Deadline past': 'Terminas praėjęs',
      Completed: 'Užbaigtas',
      'No points yet': 'Dar nėra taškų',
      'is confirmed': 'patvirtinta',
      Contacts: 'Kontaktai',
      Organizer: 'Organizatorius',
      'Featured athletes': 'Pasiūlyti sportininkai',
      'Top-ranked participants of the tournament': 'Aukščiausiai įvertinti turnyro dalyviai',
      NR: 'NR',
      PLAYER: 'ŽAIDĖJAS',
      CITY: 'MIESTAS',
      RATING: 'REITINGAS',
      CLUB: 'KLUBAS',
      TOTAL: 'IŠ VISO',
      SEGMENT: 'SEGMENTAS',
      SEED: 'SEED',
      Search: 'Ieškoti',
      'No participants found': 'Dalyvių nerasta',
      Total: 'Iš viso',
      Seed: 'Seed',
      Rating: 'Reitingas',
      Rank: 'Pozicija',
      'Category:': 'Kategorija:',
      'No category found': 'Kategorijos nerasta',
      'No Rounds found': 'Etapų nerasta',
      White: 'Baltas',
      Black: 'Juodas',
      Name: 'Vardas',
      Pts: 'Taškai',
      Score: 'Rezultatas',
      'There is no available pairings!': 'Porų nėra!',
      'Generating PDF...': 'Generuojama PDF...',
      'PDF Generated Successfully!': 'PDF sėkmingai sugeneruotas!',
      'PDF Downloaded Successfully!': 'PDF sėkmingai atsisiųsta!',
      'Downloading...': 'Atsisiunčiama...',
      'Pairing Standings': 'Porų reitingai',
      Opponents: 'Varžovai',
      BHC1: 'BHC1',
      BHC: 'BHC',
      BGP: 'BGP',
      WIN: 'PERGALĖ',
      'There is no available entries!': 'Nėra įrašų!',
      "I'm a coach/parent": 'Esu treneris/tėvas',
      'Step 1': '1 žingsnis',
      'Select category': 'Pasirinkti kategoriją',
      Request: 'Užklausa',
      'Register & Pay Now': 'Registruotis ir mokėti dabar',
      'Register & Pay On-Site': 'Registruotis ir mokėti vietoje',
      'Terms And Conditions': 'Taisyklės ir sąlygos',
      'Latest Winners': 'Naujausi laimėtojai',
      'No winners found': 'Laimėtojų nerasta',
      'No news found': 'Naujienų nerasta',
      'No partners found': 'Partnerių nerasta',
      'Skills Levels': 'Įgūdžių lygiai',
      'To get register for the tournament you must have active tour licence.': 'Norint registruotis turnyrui, turite turėti galiojančią turo licenciją.',
      'Contact Details': 'Kontaktinė informacija',
      'Our Partners': 'Mūsų partneriai',
      'View all': 'Peržiūrėti visus',
      Member: 'Narys',
      'Team Name': 'Komandos pavadinimas',
      News: 'Naujienos',
      'Men Pro': 'Vyrai Pro',
      'Latvia Tennis Union League': 'Latvijos teniso sąjungos lyga',
      Leagues: 'Lygos',
      'No league found': 'Lygos nerasta',
      'Home Page': 'Pagrindinis puslapis',
      Upcoming: 'Artimiausi',
      'This month': 'Šį mėnesį',
      'Last 7 days': 'Paskutines 7 dienas',
      'Last 30 days': 'Paskutines 30 dienų',
      Hours: 'Valandos',
      Minutes: 'Minutės',
      Teams: 'Komandos',
      'No student found': 'Studentų nerasta',
      'Other news': 'Kitos naujienos',
      'Don’t have an account?': 'Neturite paskyros?',
      'Sign up': 'Registruotis',
      'or log in': 'arba prisijungti',
      'Did you forget your password?': 'Pamiršote slaptažodį?',
      'Must be a valid email': 'Turi būti galiojantis el. paštas',
      'Email is required': 'El. paštas privalomas',
      'Password is required': 'Slaptažodis privalomas',
      Password: 'Slaptažodis',
      'Remember me': 'Prisiminti mane',
      'Log In': 'Prisijungti',
      'Unauthorized , please sign in': 'Neautorizuota, prašome prisijungti',
      'Reset your password': 'Atstatyti slaptažodį',
      'Enter the e-mail address': 'Įveskite el. pašto adresą',
      'Enter your e-mail address with which you are registered': 'Įveskite el. pašto adresą, su kuriuo registravotės',
      'Please check your email for reset password link!': 'Patikrinkite savo el. paštą, ar nėra slaptažodžio atstatymo nuorodos!',
      'Send instructions': 'Siųsti instrukcijas',
      'My Licenes': 'Mano licencijos',
      'My leagues': 'Mano lygos',
      'My tournaments': 'Mano turnyrai',
      'My matches': 'Mano varžybos',
      'My entries': 'Mano įrašai',
      'My rankings': 'Mano reitingai',
      Stats: 'Statistika',
      'Leagues took part in': 'Dalyvauta lygose',
      'Active licences': 'Aktyvios licencijos',
      Titles: 'Titulai',
      'Matches played': 'Sužaistos varžybos',
      'Matches lost': 'Pralaimėtos varžybos',
      'Games/points played': 'Sužaisti žaidimai/taškai',
      'All sports': 'Visi sportai',
      'All organizations': 'Visos organizacijos',
      'All leagues': 'Visos lygos',
      Registered: 'Registruotas',
      Withdraw: 'Atšaukti',
      'Withdraw deadline passed': 'Atsisakymo terminas praėjo',
      'Request Withdrawn': 'Užklausa atšaukta',
      'Something went wrong!': 'Kažkas ne taip!',
      Withdrawal: 'Atsisakymas',
      'Entry to withdraw': 'Įrašas atšaukti',
      'Withdrawal Reason': 'Atsisakymo priežastis',
      'Write your reason here': 'Įrašykite savo priežastį čia',
      Confirm: 'Patvirtinti',
      'Please specify reason': 'Prašome nurodyti priežastį',
      'AUDI - The Beach Tennis Championship': 'AUDI - Paplūdimio teniso čempionatas',
      'Get licence': 'Gauti licenciją',
      'Additional questions': 'Papildomi klausimai',
      'Select duration': 'Pasirinkite trukmę',
      Career: 'Karjera',
      'Third Party Licenes': 'Trečiųjų šalių licencijos',
      'User updated successfully': 'Vartotojas sėkmingai atnaujintas',
      'User Account': 'Vartotojo paskyra',
      'We recommend an image that is 256 x 256 pixels': 'Rekomenduojame vaizdą, kurio dydis yra 256 x 256 pikseliai',
      'Enter Email Address': 'Įveskite el. pašto adresą',
      'Enter name': 'Įveskite vardą',
      Surname: 'Pavardė',
      'Enter surname': 'Įveskite pavardę',
      'Contact phone number': 'Kontaktinis telefono numeris',
      'Enter phone number': 'Įveskite telefono numerį',
      'Date of Birth': 'Gimimo data',
      'Date of birth': 'Gimimo data',
      Gender: 'Lytis',
      'Select gender': 'Pasirinkite lytį',
      'Representing country': 'Atstovaujama šalis',
      'Select country': 'Pasirinkite šalį',
      City: 'Miestas',
      'Select role(s)': 'Pasirinkite vaidmenį(-is)',
      Address: 'Adresas',
      'Enter address': 'Įveskite adresą',
      'Parent found': 'Tėvas rastas',
      'Parent not found': 'Tėvo nerasta',
      'Removed coach': 'Treneris pašalintas',
      'Failed to removed coach': 'Nepavyko pašalinti trenerio',
      'Coach created': 'Treneris sukurtas',
      'Failed to create coach': 'Nepavyko sukurti trenerio',
      Club: 'Klubas',
      Save: 'Išsaugoti',
      'Select club': 'Pasirinkite klubą',
      Coach: 'Treneris',
      'Select a coach': 'Pasirinkite trenerį',
      Parent: 'Tėvas',
      'Enter email': 'Įveskite el. paštą',
      Biography: 'Biografija',
      'Write a bio': 'Rašykite biografiją',
      CHECKBOXES: 'VARNELES',
      'Give access to coach to purchase licences and manage entries on your behalf': 'Suteikite treneriui prieigą pirkti licencijas ir valdyti įrašus jūsų vardu',
      'Give access to club manager(-es) to purchase licences and manage entries on your behalf': 'Suteikite klubo vadybininkui(-ams) prieigą pirkti licencijas ir valdyti įrašus jūsų vardu',
      'Give access to parent(-s) to purchase licences and manage entries on your behalf': 'Suteikite tėvui(-ams) prieigą pirkti licencijas ir valdyti įrašus jūsų vardu',
      Socials: 'Socialiniai tinklai',
      'Insert link here': 'Įterpkite nuorodą čia',
      Facebook: 'Facebook',
      Instagram: 'Instagram',
      Linkedin: 'Linkedin',
      Tiktok: 'Tiktok',
      Youtube: 'Youtube',
      'Enter Id': 'Įveskite ID',
      'Password should be 8 chars minimum.': 'Slaptažodis turi būti ne mažiau kaip 8 simboliai.',
      'Passwords must match': 'Slaptažodžiai turi sutapti',
      'Password changed successfully': 'Slaptažodis sėkmingai pakeistas',
      'New Password': 'Naujas slaptažodis',
      'Re-Enter': 'Pakartoti slaptažodį',
      'Name is Required': 'Vardas yra privalomas',
      'Surname is Required': 'Pavardė yra privaloma',
      'Re enter password': 'Pakartotinai įveskite slaptažodį',
      'User is already registered': 'Vartotojas jau užregistruotas',
      'Create an account': 'Sukurti paskyrą',
      'Already have an account?': 'Jau turite paskyrą?',
      'Log in': 'Prisijungti',
      'or create account': 'arba sukurti paskyrą',
      "I'm creating an account for a kid": 'Kuriu paskyrą vaikui',
      'sign-up description':
        'Jūs kuriate turnyro dalyvio paskyrą. Jei registruojate vaiką, prašome sukurti jam/jai profilį. Trenerio ir tėvų profiliai bus pasiekiami netrukus, leidžiant jums susieti su vaiko profiliu.',
      'Email Address': 'El. pašto adresas',
      Continue: 'Tęsti',
      'Pick a sport': 'Pasirinkti sportą',
      'Pick a sport that you play': 'Pasirinkite sportą, kuriuo užsiimate',
      'Gender is required': 'Lytis yra privaloma',
      'Phone is required': 'Telefonas yra privalomas',
      'Citizenship is required': 'Šalis yra privaloma',
      'Dob is required': 'Gimimo data yra privaloma',
      'FIDE ID Not found': 'FIDE ID nerasta',
      'FIDE ID found': 'FIDE ID rasta',
      'Tell us more about you': 'Papasakokite daugiau apie save',
      'More information about yourself': 'Daugiau informacijos apie save',
      'Enter FIDE ID': 'Įveskite FIDE ID',
      'IPIN ID is correct': 'IPIN ID yra teisingas',
      'Your Licenses': 'Jūsų licencijos',
      'Attach your licenses': 'Pridėti jūsų licencijas',
      Skip: 'Praleisti',
      'User registered successfully': 'Vartotojas sėkmingai užregistruotas',
      'What is your primary role in the world of sports?': 'Koks jūsų pagrindinis vaidmuo sporto pasaulyje?',
      'Register & Go to profile': 'Registruotis ir pereiti į profilį',
      'PUBLISHED ON': 'PASKELBTA',
      'Federation member': 'Federacijos narys',
      'Club owner': 'Klubo savininkas',
      'Club manager': 'Klubo vadybininkas',
      Referee: 'Teisėjas',
      'Tournament organizer': 'Turnyro organizatorius',
      'Sport fan': 'Sporto aistruolis',
      Other: 'Kita',
      PAY: 'APMOKĖTI',
      '1 Year': '1 metai',
      '2 Years': '2 metai',
      '3 Years': '3 metai',
      '4 Years': '4 metai',
      'Registration time': 'Registracijos laikas',
      'In progress / Upcoming': 'Vyksta / Artėja',
      'Matches won': 'Laimėtos varžybos',
      'In progress / upcoming entries': 'Vyksta / artėjantys įrašai',
      'In progress / upcoming tournaments': 'Vyksta / artėjantys turnyrai',
      'Past tournaments': 'Praėję turnyrai',
      'Past entries': 'Praėję įrašai',
      'Your password must be different to previous passwords': 'Jūsų slaptažodis turi skirtis nuo ankstesnių slaptažodžių',
      'less than a minute': 'mažiau nei minutė',
      read: 'skaityti',
      minute: 'minutė',
      'Select Licence': 'Pasirinkti licenciją',
      'Start time': 'Pradžios laikas',
      'Try again': 'Pabandykite dar kartą',
      maintenanceMessage: 'Mes šiuo metu atnaujiname platformą, tai gali užtrukti keletą minučių. Prašome pabandyti vėliau.',
      networkErrorMessage: 'Patikrinkite savo tinklo ryšį ir pabandykite dar kartą.',
      errorTitle: 'Oi! Kažkas ne taip!',
      'View List': 'Peržiūrėti sąrašą',
      Free: 'Nemokamai',
      'Primary Sport': 'Pagrindinis sportas',
      'Role(s)': 'Vaidmuo (-ai)',
      Menu: 'Meniu',
      Calendar: 'Kalendorius',
      'Manage tournament': 'Valdyti turnyrą',
      'Partner search': 'Partnerio paieška',
      'Partner Search': 'Partnerio paieška',
      'Searching...': 'Ieškoma...',
      Apply: 'Taikyti',
      Delete: 'Ištrinti',
      'Create application': 'Sukurti paraišką',
      'No partner search applications found': 'Partnerio paieškos paraiškų nerasta',
      'Log in to apply': 'Prisijunkite norėdami taikyti',
      Player: 'Žaidėjas',
      'Time of registration': 'Registracijos laikas',
      confirmed: 'patvirtinta',
      declined: 'atmesta',
      'payment pending': 'mokėjimas laukia',
      WITHDRAW: 'ATŠAUKTI',
      'No other tournament found': 'Kitų turnyrų nerasta',
      welcomeTextCustom:
        'Tournated platforma palaiko, tai yra visapusiška sporto valdymo programinė įranga, kuri leidžia sporto organizacijoms efektyviai valdyti, auginti ir uždirbti iš savo veiklos. Sprendimas leidžia organizacijoms kurti individualias internetines platformas, automatizuoti ir paprastinti lygų, klubų, turnyrų ir kitų renginių valdymą, didinti auditorijos įsitraukimą ir lengvai apdoroti mokėjimus.',
      welcomeText:
        'penkerių metų tyrimų ir plėtros bei bendradarbiavimo su daugiau nei 200 organizacijų rezultatas yra novatoriškas, pritaikomas, vartotojui draugiškas ir įperkamas visapusiškas sprendimas bet kokio dydžio sporto organizacijoms',
      'Did you have an account in one of ': 'Ar turėjote paskyrą vienoje iš ',
      those: 'tų',
      'platforms?': 'platformų?',
      'Recover password': 'Atkurti slaptažodį',
      newUser: 'Esu naujas vartotojas. Registruotis',
      'Continue as a guest': 'Tęsti kaip svečias',
      Support: 'Palaikymas',
      'Welcome to': 'Sveiki atvykę į',
      'If you have an account on one of ': 'Jei turite paskyrą vienoje iš ',
      these: 'šių',
      'platforms, log in with your e-mail or create a new profile': 'platformų, prisijunkite su savo el. paštu arba sukurkite naują profilį',
      'Reset password': 'Atstatyti slaptažodį',
      'Welcome to the': 'Sveiki atvykę į',
      platform: 'platformą',
      'Powered by': 'Palaikoma',
      'All Categories': 'Visos kategorijos',
      'Tournament Results': 'Turnyro rezultatai',
      versionText: 'Beta versija yra gyva. Prašome pateikti savo atsiliepimus',
      here: 'čia',
      'Full view': 'Pilna peržiūra',
      Timeline: 'Laiko juosta',
      Grid: 'Tinklelis',
      "Bird's View": 'Paukščio akimis',
      'Third Party Licences': 'Trečiųjų šalių licencijos',
      Friend: 'Draugas',
      'Step 2': '2 žingsnis:',
      'Step 3': '3 žingsnis:',
      'Your Details': 'Jūsų duomenys',
      'Partner Details': 'Partnerio duomenys',
      'Enter partner email': 'Įveskite partnerio el. paštą',
      'Register & Pay': `Registruotis ir mokėti`,
      'On-Site': 'Vietoje',
      Now: 'Dabar',
    },
  },
  pt: {
    translation: {
      header: {
        discover: 'Descobrir',
        enterName: 'Digite o nome',
        sportsManager: 'Gerente de Esportes?',
        learnHow: 'Saiba como',
        accelerate: 'podemos acelerar sua organização.',
        powering: 'Impulsionando',
        tournaments: 'torneios,',
        leagues: 'ligas',
        organizations: 'organizações',
        clubs: 'clubes',
        coaches: 'treinadores',
        placeholder: {
          tournaments: 'Digite o nome do torneio',
          leagues: 'Digite o nome da liga',
          organizations: 'Digite o nome da organização',
          clubs: 'Digite o nome do clube',
          coaches: 'Digite o nome do treinador',
        },
      },
      registrationOpen: 'Inscrições abertas',
      read_more: 'Leia mais',
      registrationClosed: 'Inscrições fechadas',
      live: 'Ao vivo',
      Close: 'Fechar',
      bannerHeading: 'Comece a gerenciar seus torneios totalmente grátis',
      bannerDescription: 'Desde o gerenciamento de inscrições até a geração automática de grupos, sorteios e ordem de jogos, gestão de resultados e muito mais',
      startNow: 'Comece Agora',
      featureTournaments: 'Torneios em Destaque',
      tournamentNotFound: 'Nenhum torneio em destaque encontrado',
      viewAll: 'Ver todos',
      featureLeague: 'Ligas em Destaque',
      leaguesNotFound: 'Nenhuma liga em destaque encontrada',
      Tournaments: 'Torneios',
      NoTournamentsFound: 'Nenhum torneio encontrado',
      Rankings: 'Rankings',
      noRankingFound: 'Nenhum ranking encontrado',
      news: 'Notícias',
      noNewsFound: 'Nenhuma notícia encontrada',
      partners: 'Nossos Parceiros',
      Partners: 'Parceiros',
      noPartnersFound: 'Nenhum parceiro encontrado',
      worldwide: 'Mundialmente',
      search: 'Buscar',
      pricing: 'Preços',
      loginRegister: 'Entrar / Registrar',
      home: 'Início',
      organizations: 'Organizações',
      leagues: 'Ligas',
      teams: 'Times',
      clubs: 'Clubes',
      coaches: 'Treinadores',
      tennis: 'Tênis',
      beachTenis: 'Beach Tennis',
      'Beach Volley': 'Vôlei de Praia',
      'Sport Fishing': 'Pesca Esportiva',
      Volleyball: 'Voleibol',
      Sports: 'Esportes',
      RESOURSES: 'RECURSOS',
      Feature: 'Destaque',
      'Create account': 'Criar conta',
      Login: 'Entrar',
      Packages: 'Pacotes',
      'Package one': 'Pacote um',
      'Package two': 'Pacote dois',
      'Package three': 'Pacote três',
      'Package Four': 'Pacote Quatro',
      'Customer suppor': 'Suporte ao Cliente',
      FAQ: 'FAQ',
      Documentation: 'Documentação',
      'Video tutorials': 'Tutoriais em vídeo',
      'Submit ticket': 'Enviar solicitação',
      'Contact us': 'Contate-nos',
      'Our contacts': 'Nossos contatos',
      Padel: 'Padel',
      Pickleball: 'Pickleball',
      'FEATURED LEAGUES': 'LIGAS EM DESTAQUE',
      View: 'Ver',
      'No leagues found': 'Nenhuma liga encontrada',
      Athletes: 'Atletas',
      'Managed by': 'Gerenciado por',
      'View League': 'Ver Liga',
      Type: 'Tipo',
      National: 'Nacional',
      International: 'Internacional',
      Local: 'Local',
      Period: 'Período',
      League: 'Liga',
      Country: 'País',
      Year: 'Ano',
      Points: 'Pontos',
      Position: 'Posição',
      Team: 'Time',
      'No teams found': 'Nenhum time encontrado',
      'Team Members': 'Membros do Time',
      Sport: 'Esporte',
      'No clubs found': 'Nenhum clube encontrado',
      Experience: 'Experiência',
      Students: 'Estudantes',
      Author: 'Autor',
      Category: 'Categoria',
      'My Entries': 'Minhas Inscrições',
      'My Leagues': 'Minhas Ligas',
      'My Licences': 'Minhas Licenças',
      'My Teams': 'Meus Times',
      'Get Licence': 'Obter Licença',
      demo: 'demo',
      Settings: 'Configurações',
      'Log out': 'Sair',
      'Dark view': 'Modo escuro',
      Manager: 'Gerente',
      Tournament: 'Torneio',
      Cost: 'Custo',
      Duration: 'Duração',
      'Purchase on': 'Comprar em',
      'No license found': 'Nenhuma licença encontrada',
      Additional: 'Adicional',
      Status: 'Status',
      Date: 'Data',
      'No entries found': 'Nenhuma inscrição encontrada',
      'My Licenses': 'Minhas Licenças',
      'Add new License': 'Adicionar nova Licença',
      'Create Team': 'Criar Time',
      Info: 'Informações',
      infoDes: 'Para participar de determinadas ligas / torneios, o jogador deve ter uma licença ativa correspondente.',
      'Select League': 'Selecionar Liga',
      'Select License': 'Selecionar Licença',
      'Promo code': 'Código promocional',
      'I agree to the': 'Concordo com os',
      'Terms and Conditions': 'Termos e Condições',
      Price: 'Preço',
      'Confirm Payment': 'Confirmar Pagamento',
      History: 'Histórico',
      'Tournated Manager': 'Gerente Tournated',
      'Tournated Custom': 'Tournated Customizado',
      'Customer support': 'SUPORTE AO CLIENTE',
      Categories: 'Categorias',
      Matches: 'Partidas',
      'Organized by': 'Organizado por',
      Register: 'Registrar',
      Entries: 'Inscrições',
      Location: 'Localização',
      'National Tour': 'Tour Nacional',
      '4 mins read': 'Leitura de 4 minutos',
      'Age group': 'Faixa etária',
      'Skills level': 'Nível de habilidades',
      someDes: 'SIA Vertexo © 2020. Todos os Direitos Reservados',
      'Manage in CMS': 'Gerenciar no CMS',
      'Fact Sheet': 'Ficha Técnica',
      General: 'Geral',
      Participants: 'Participantes',
      Groups: 'Grupos',
      Draws: 'Sorteios',
      'Live Standings': 'Classificação ao vivo',
      'Order of play': 'Ordem dos jogos',
      Pairings: 'Emparelhamentos',
      Standings: 'Classificações',
      'Final standings': 'Classificação final',
      'List & Results': 'Lista e Resultados',
      'Entry Fee': 'Taxa de Inscrição',
      'Entry Deadline': 'Prazo de Inscrição',
      'Entry deadline': 'Prazo de inscrição',
      'Withdrawal deadline': 'Prazo de retirada',
      'Start date': 'Data de início',
      'End date': 'Data de término',
      and: 'e',
      others: 'outros',
      'are confirmed': 'estão confirmados',
      Description: 'Descrição',
      'Tournament Poster': 'Cartaz do Torneio',
      'More Info': 'Mais Informações',
      'Additional materials about tournament': 'Materiais adicionais sobre o torneio',
      Regulations: 'Regulamentos',
      'Tournaments Discord': 'Discord dos Torneios',
      discordDes: 'Converse diretamente com os administradores do torneio, faça perguntas e fale com outros jogadores!',
      'Join Channel': 'Entrar no Canal',
      'Fact sheet': 'Ficha técnica',
      'Search hotels and more': 'Buscar hotéis e mais...',
      Destination: 'Destino',
      'Select Destination': 'Selecionar Destino',
      'Check in date': 'Data de Check-in',
      'Check out date': 'Data de Check-out',
      'Best players on this tournament': 'Melhores jogadores deste torneio',
      Gallery: 'Galeria',
      'Recent photos': 'Fotos recentes',
      'Buy licence': 'Comprar licença',
      'Select All': 'Selecionar Tudo',
      "Don't see your sport? Contact us": 'Não vê seu esporte? Contate-nos',
      'Description is missing': 'Descrição está ausente',
      day: 'dia',
      days: 'dias',
      Organization: 'Organização',
      Athlete: 'Atleta',
      'Birth year': 'Ano de nascimento',
      Joined: 'Ingressou',
      'Select Sport': 'Selecionar Esporte',
      'Select organization': 'Selecionar organização',
      'Select league': 'Selecionar liga',
      Overview: 'Visão geral',
      About: 'Sobre',
      'Win rate': 'Taxa de vitória',
      Achievements: 'Conquistas',
      'Upcoming Matches': 'Próximas Partidas',
      'Past Matches': 'Partidas Passadas',
      'Upcoming Tournaments': 'Próximos Torneios',
      'Purchase history': 'Histórico de compras',
      'description and detail': 'Melhorar: Adquira licenças / associações necessárias para participar de ligas ou torneios específicos',
      'Purchase datetime': 'Data e hora da compra',
      'Payment Status': 'Status do Pagamento',
      Licence: 'Licença',
      Members: 'Membros',
      Student: 'Estudante',
      'Personal page': 'Página pessoal',
      'My Career': 'Minha Carreira',
      'Latest tournaments': 'Últimos torneios',
      'Upcoming tournaments': 'Próximos torneios',
      'No members found': 'Nenhum membro encontrado',
      'No tournaments found': 'Nenhum torneio encontrado',
      'No matches found': 'Nenhuma partida encontrada',
      'No rankings found': 'Nenhum ranking encontrado',
      'No past matches found': 'Nenhuma partida passada encontrada',
      'No upcoming matches found': 'Nenhuma partida futura encontrada',
      'No achievements found': 'Nenhuma conquista encontrada',
      Documents: 'Documentos',
      Contact: 'Contato',
      'No photos added': 'Nenhuma foto adicionada',
      'Manage league': 'Gerenciar liga',
      'No documents added': 'Nenhum documento adicionado',
      Email: 'E-mail',
      Phone: 'Telefone',
      Website: 'Site',
      Place: 'Local',
      'Tournament Name': 'Nome do Torneio',
      'Manage organization': 'Gerenciar organização',
      'Terms & Conditions': 'Termos e Condições',
      LEGAL: 'LEGAL',
      'Privacy policy': 'Política de Privacidade',
      'Cancellation policy': 'Política de Cancelamento',
      GDPR: 'GDPR',
      'All Rights Reserved': 'Todos os Direitos Reservados',
      'League [Category]': 'Liga [Categoria]',
      'Other tournaments': 'Outros torneios',
      'Points Details': 'Detalhes dos Pontos',
      'International rating': 'Classificação internacional',
      Day: 'Dia',
      //////////// NEW ///////////////////////////////
      Days: 'Dias',
      Tennis: 'Tênis',
      'Beach Tennis': 'Beach Tennis',
      'Beach Volleyball': 'Vôlei de Praia',
      Chess: 'Xadrez',
      'In progress': 'Em andamento',
      Registration: 'Inscrição',
      'Deadline past': 'Prazo expirado',
      Completed: 'Concluído',
      'No points yet': 'Sem pontos ainda',
      'is confirmed': 'está confirmado',
      Contacts: 'Contatos',
      Organizer: 'Organizador',
      'Featured athletes': 'Atletas em destaque',
      'Top-ranked participants of the tournament': 'Participantes mais bem classificados do torneio',
      NR: 'NR',
      PLAYER: 'JOGADOR',
      CITY: 'CIDADE',
      RATING: 'CLASSIFICAÇÃO',
      CLUB: 'CLUBE',
      TOTAL: 'TOTAL',
      SEGMENT: 'SEGMENTO',
      SEED: 'Semente',
      Search: 'Buscar',
      'No participants found': 'Nenhum participante encontrado',
      Total: 'Total',
      Seed: 'Semente',
      Rating: 'Classificação',
      Rank: 'Posição',
      'Category:': 'Categoria:',
      'No category found': 'Nenhuma categoria encontrada',
      'No Rounds found': 'Nenhuma rodada encontrada',
      White: 'Branco',
      Black: 'Preto',
      Name: 'Nome',
      Pts: 'Pts',
      Score: 'Placar',
      'There is no available pairings!': 'Não há emparelhamentos disponíveis!',
      'Generating PDF...': 'Gerando PDF...',
      'PDF Generated Successfully!': 'PDF gerado com sucesso!',
      'PDF Downloaded Successfully!': 'PDF baixado com sucesso!',
      'Downloading...': 'Baixando...',
      'Pairing Standings': 'Classificação dos emparelhamentos',
      Opponents: 'Oponentes',
      BHC1: 'BHC1',
      BHC: 'BHC',
      BGP: 'BGP',
      WIN: 'VITÓRIA',
      'There is no available entries!': 'Não há inscrições disponíveis!',
      "I'm a coach/parent": 'Sou treinador/pai',
      'Step 1': 'Passo 1',
      'Select category': 'Selecionar categoria',
      Request: 'Solicitar',
      'Register & Pay Now': 'Registrar & Pagar Agora',
      'Register & Pay On-Site': 'Registrar & Pagar no Local',
      'Terms And Conditions': 'Termos e Condições',
      'Latest Winners': 'Últimos Vencedores',
      'No winners found': 'Nenhum vencedor encontrado',
      'No news found': 'Nenhuma notícia encontrada',
      'No partners found': 'Nenhum parceiro encontrado',
      'Skills Levels': 'Níveis de Habilidade',
      'To get register for the tournament you must have active tour licence.': 'Para se registrar no torneio, você deve ter uma licença ativa.',
      'Contact Details': 'Detalhes do Contato',
      'Our Partners': 'Nossos Parceiros',
      'View all': 'Ver todos',
      Member: 'Membro',
      'Team Name': 'Nome do Time',
      News: 'Notícias',
      'Men Pro': 'Homens Pro',
      'Latvia Tennis Union League': 'Liga da União Letã de Tênis',
      Leagues: 'Ligas',
      'No league found': 'Nenhuma liga encontrada',
      'Home Page': 'Página Inicial',
      Upcoming: 'Próximos',
      'This month': 'Este mês',
      'Last 7 days': 'Últimos 7 dias',
      'Last 30 days': 'Últimos 30 dias',
      Hours: 'Horas',
      Minutes: 'Minutos',
      Teams: 'Times',
      'No student found': 'Nenhum estudante encontrado',
      'Other news': 'Outras notícias',
      'Don’t have an account?': 'Não tem uma conta?',
      'Sign up': 'Cadastre-se',
      'or log in': 'ou faça login',
      'Did you forget your password?': 'Esqueceu sua senha?',
      'Must be a valid email': 'Deve ser um e-mail válido',
      'Email is required': 'E-mail é obrigatório',
      'Password is required': 'Senha é obrigatória',
      Password: 'Senha',
      'Remember me': 'Lembrar-me',
      'Log In': 'Entrar',
      'Unauthorized , please sign in': 'Não autorizado, por favor faça login',
      'Reset your password': 'Redefina sua senha',
      'Enter the e-mail address': 'Digite o endereço de e-mail',
      'Enter your e-mail address with which you are registered': 'Digite o e-mail com o qual você está registrado',
      'Please check your email for reset password link!': 'Verifique seu e-mail para o link de redefinição de senha!',
      'Send instructions': 'Enviar instruções',
      'My Licenes': 'Minhas Licenças',
      'My leagues': 'Minhas ligas',
      'My tournaments': 'Meus torneios',
      'My matches': 'Minhas partidas',
      'My entries': 'Minhas inscrições',
      'My rankings': 'Meus rankings',
      Stats: 'Estatísticas',
      'Leagues took part in': 'Ligas que participou',
      'Active licences': 'Licenças ativas',
      Titles: 'Títulos',
      'Matches played': 'Partidas jogadas',
      'Matches lost': 'Partidas perdidas',
      'Games/points played': 'Jogos/pontos jogados',
      'All sports': 'Todos os esportes',
      'All organizations': 'Todas as organizações',
      'All leagues': 'Todas as ligas',
      Registered: 'Registrado',
      Withdraw: 'Retirar',
      'Withdraw deadline passed': 'Prazo de retirada expirado',
      'Request Withdrawn': 'Solicitação Retirada',
      'Something went wrong!': 'Algo deu errado!',
      Withdrawal: 'Retirada',
      'Entry to withdraw': 'Inscrição para retirar',
      'Withdrawal Reason': 'Motivo da Retirada',
      'Write your reason here': 'Escreva seu motivo aqui',
      Confirm: 'Confirmar',
      'Please specify reason': 'Por favor, especifique o motivo',
      'AUDI - The Beach Tennis Championship': 'AUDI - Campeonato de Beach Tennis',
      'Get licence': 'Obter licença',
      'Additional questions': 'Perguntas adicionais',
      'Select duration': 'Selecione a duração',
      Career: 'Carreira',
      'Third Party Licenes': 'Licenças de Terceiros',
      'User updated successfully': 'Usuário atualizado com sucesso',
      'User Account': 'Conta do Usuário',
      'We recommend an image that is 256 x 256 pixels': 'Recomendamos uma imagem de 256 x 256 pixels',
      'Enter Email Address': 'Digite o endereço de e-mail',
      'Enter name': 'Digite o nome',
      Surname: 'Sobrenome',
      'Enter surname': 'Digite o sobrenome',
      'Contact phone number': 'Número de telefone de contato',
      'Enter phone number': 'Digite o número de telefone',
      'Date of Birth': 'Data de Nascimento',
      'Date of birth': 'Data de nascimento',
      Gender: 'Gênero',
      'Select gender': 'Selecione o gênero',
      'Representing country': 'País representado',
      'Select country': 'Selecione o país',
      City: 'Cidade',
      'Select role(s)': 'Selecione o(s) papel(is)',
      Address: 'Endereço',
      'Enter address': 'Digite o endereço',
      'Parent found': 'Pai encontrado',
      'Parent not found': 'Pai não encontrado',
      'Removed coach': 'Treinador removido',
      'Failed to removed coach': 'Falha ao remover o treinador',
      'Coach created': 'Treinador criado',
      'Failed to create coach': 'Falha ao criar o treinador',
      Club: 'Clube',
      Save: 'Salvar',
      'Select club': 'Selecionar clube',
      Coach: 'Treinador',
      'Select a coach': 'Selecionar um treinador',
      Parent: 'Pai',
      'Enter email': 'Digite o e-mail',
      Biography: 'Biografia',
      'Write a bio': 'Escreva uma biografia',
      CHECKBOXES: 'CAIXAS DE SELEÇÃO',
      'Give access to coach to purchase licences and manage entries on your behalf': 'Dê acesso ao treinador para comprar licenças e gerenciar inscrições em seu nome',
      'Give access to club manager(-es) to purchase licences and manage entries on your behalf': 'Dê acesso ao gerente do clube para comprar licenças e gerenciar inscrições em seu nome',
      'Give access to parent(-s) to purchase licences and manage entries on your behalf': 'Dê acesso aos pais para comprar licenças e gerenciar inscrições em seu nome',
      Socials: 'Redes Sociais',
      'Insert link here': 'Insira o link aqui',
      Facebook: 'Facebook',
      Instagram: 'Instagram',
      Linkedin: 'Linkedin',
      Tiktok: 'Tiktok',
      Youtube: 'Youtube',
      'Enter Id': 'Digite o ID',
      'Password should be 8 chars minimum.': 'A senha deve ter no mínimo 8 caracteres.',
      'Passwords must match': 'As senhas devem coincidir',
      'Password changed successfully': 'Senha alterada com sucesso',
      'New Password': 'Nova Senha',
      'Re-Enter': 'Confirmar senha',
      'Name is Required': 'Nome é obrigatório',
      'Surname is Required': 'Sobrenome é obrigatório',
      'Re enter password': 'Digite a senha novamente',
      'User is already registered': 'Usuário já registrado',
      'Create an account': 'Criar uma conta',
      'Already have an account?': 'Já tem uma conta?',
      'Log in': 'Entrar',
      'or create account': 'ou criar conta',
      "I'm creating an account for a kid": 'Estou criando uma conta para uma criança',
      'sign-up description':
        'Você está criando uma conta para um participante de torneio. Se você está registrando uma criança, por favor, crie um perfil para ela. Perfis de treinador e pais estarão disponíveis em breve, permitindo que você vincule ao perfil da criança.',
      'Email Address': 'Endereço de E-mail',
      Continue: 'Continuar',
      'Pick a sport': 'Escolha um esporte',
      'Pick a sport that you play': 'Escolha um esporte que você joga',
      'Gender is required': 'Gênero é obrigatório',
      'Phone is required': 'Telefone é obrigatório',
      'Citizenship is required': 'País é obrigatório',
      'Dob is required': 'Data de nascimento é obrigatória',
      'FIDE ID Not found': 'ID FIDE não encontrado',
      'FIDE ID found': 'ID FIDE encontrado',
      'Tell us more about you': 'Conte-nos mais sobre você',
      'More information about yourself': 'Mais informações sobre você',
      'Enter FIDE ID': 'Digite o ID FIDE',
      'IPIN ID is correct': 'ID IPIN está correto',
      'Your Licenses': 'Suas Licenças',
      'Attach your licenses': 'Anexe suas licenças',
      Skip: 'Pular',
      'User registered successfully': 'Usuário registrado com sucesso',
      'What is your primary role in the world of sports?': 'Qual é o seu papel principal no mundo dos esportes?',
      'Register & Go to profile': 'Registrar & Ir para o perfil',
      'PUBLISHED ON': 'PUBLICADO EM',
      'Federation member': 'Membro da federação',
      'Club owner': 'Dono do clube',
      'Club manager': 'Gerente do clube',
      Referee: 'Árbitro',
      'Tournament organizer': 'Organizador do torneio',
      'Sport fan': 'Fã de esportes',
      Other: 'Outro',
      PAY: 'PAGAR',
      '1 Year': '1 Ano',
      '2 Years': '2 Anos',
      '3 Years': '3 Anos',
      '4 Years': '4 Anos',
      'Registration time': 'Hora de Registro',
      'In progress / Upcoming': 'Em andamento / Próximos',
      'Matches won': 'Partidas vencidas',
      'In progress / upcoming entries': 'Entradas em andamento / próximas',
      'In progress / upcoming tournaments': 'Torneios em andamento / próximos',
      'Past tournaments': 'Torneios passados',
      'Past entries': 'Entradas passadas',
      'Your password must be different to previous passwords': 'Sua senha deve ser diferente das senhas anteriores',
      'less than a minute': 'menos de um minuto',
      read: 'ler',
      minute: 'minuto',
      'Select Licence': 'Selecionar Licença',
      'Start time': 'Hora de Início',
      'Try again': 'Tente novamente',
      maintenanceMessage: 'Estamos atualizando a plataforma, o que pode levar alguns minutos. Por favor, tente novamente em breve.',
      networkErrorMessage: 'Verifique sua conexão de rede e tente novamente.',
      errorTitle: 'Ops! Algo deu errado!',
      'View List': 'Ver Lista',
      Free: 'Gratuito',
      'Primary Sport': 'Esporte Principal',
      'Role(s)': 'Função(ões)',
      Menu: 'Menu',
      Calendar: 'Calendário',
      'Manage tournament': 'Gerenciar torneio',
      'Partner search': 'Busca de Parceiro',
      'Partner Search': 'Busca de Parceiro',
      'Searching...': 'Procurando...',
      Apply: 'Aplicar',
      Delete: 'Excluir',
      'Create application': 'Criar solicitação',
      'No partner search applications found': 'Nenhuma solicitação de busca de parceiro encontrada',
      'Log in to apply': 'Entre para aplicar',
      Player: 'Jogador',
      'Time of registration': 'Hora de registro',
      confirmed: 'confirmado',
      declined: 'recusado',
      'payment pending': 'pagamento pendente',
      WITHDRAW: 'RETIRAR',
      'No other tournament found': 'Nenhum outro torneio encontrado',
      welcomeTextCustom:
        'é impulsionado pela Tournated, um software de gestão esportiva tudo-em-um que capacita organizações esportivas a gerenciar, crescer e monetizar suas operações de forma eficiente. A solução permite que as organizações criem plataformas online personalizadas, automatizem e simplifiquem a gestão de ligas, clubes, torneios e outros eventos, aumentem o engajamento do público e processem pagamentos com facilidade.',
      welcomeText:
        'o resultado de cinco anos de P&D e colaboração com mais de 200 organizações, é uma solução inovadora, personalizável, amigável e acessível para organizações esportivas de qualquer tamanho',
      'Did you have an account in one of ': 'Você tinha uma conta em uma das ',
      those: 'dessas',
      'platforms?': 'plataformas?',
      'Recover password': 'Recuperar senha',
      newUser: 'Sou um novo usuário. Registrar',
      'Continue as a guest': 'Continuar como convidado',
      Support: 'Suporte',
      'Welcome to': 'Bem-vindo ao',
      'If you have an account on one of ': 'Se você tem uma conta em uma das ',
      these: 'dessas',
      'platforms, log in with your e-mail or create a new profile': 'plataformas, faça login com seu e-mail ou crie um novo perfil',
      'Reset password': 'Redefinir senha',
      'Welcome to the': 'Bem-vindo à',
      platform: 'plataforma',
      'Powered by': 'Impulsionado por',
      'All Categories': 'Todas as Categorias',
      'Tournament Results': 'Resultados do Torneio',
      versionText: 'Beta está ao vivo. Por favor, envie seu feedback',
      here: 'aqui',
      'Full view': 'Visualização completa',
      Timeline: 'Linha do tempo',
      Grid: 'Grade',
      "Bird's View": 'Vista de pássaro',
      'Third Party Licences': 'Licenças de Terceiros',
      Friend: 'Amigo',
      'Step 2': 'Passo 2:',
      'Step 3': 'Passo 3:',
      'Your Details': 'Seus Detalhes',
      'Partner Details': 'Detalhes do Parceiro',
      'Enter partner email': 'Digite o e-mail do parceiro',
      'Register & Pay': `Registrar & Pagar`,
      'On-Site': 'No Local',
      Now: 'Agora',
    },
  },
  hi: {
    translation: {
      header: {
        discover: 'खोजें',
        enterName: 'नाम दर्ज करें',
        sportsManager: 'स्पोर्ट्स मैनेजर?',
        learnHow: 'कैसे जानें',
        accelerate: 'हम आपके संगठन को कैसे तेज़ी से आगे बढ़ा सकते हैं।',
        powering: 'शक्ति दे रहे हैं',
        tournaments: 'टूर्नामेंट,',
        leagues: 'लीग,',
        organizations: 'संगठन,',
        clubs: 'क्लब,',
        coaches: 'कोच,',
        placeholder: {
          tournaments: 'टूर्नामेंट का नाम दर्ज करें',
          leagues: 'लीग का नाम दर्ज करें',
          organizations: 'संगठन का नाम दर्ज करें',
          clubs: 'क्लब का नाम दर्ज करें',
          coaches: 'कोच का नाम दर्ज करें',
        },
      },
      registrationOpen: 'पंजीकरण खुला है',
      read_more: 'और पढ़ें',
      registrationClosed: 'पंजीकरण बंद है',
      live: 'लाइव',
      Close: 'बंद करें',
      bannerHeading: 'अपने टूर्नामेंट का प्रबंधन बिल्कुल मुफ्त में शुरू करें',
      bannerDescription: 'प्रवेश प्रबंधन से लेकर समूहों, ड्रॉ और खेल के क्रम की स्वचालित पीढ़ी, परिणाम प्रबंधन और अधिक तक',
      startNow: 'अभी शुरू करें',
      featureTournaments: 'विशेष टूर्नामेंट',
      tournamentNotFound: 'कोई विशेष टूर्नामेंट नहीं मिला',
      viewAll: 'सभी देखें',
      featureLeague: 'विशेष लीग',
      leaguesNotFound: 'कोई विशेष लीग नहीं मिली',
      Tournaments: 'टूर्नामेंट',
      NoTournamentsFound: 'कोई टूर्नामेंट नहीं मिला',
      Rankings: 'रैंकिंग',
      noRankingFound: 'कोई रैंकिंग नहीं मिली',
      news: 'समाचार',
      noNewsFound: 'कोई समाचार नहीं मिला',
      partners: 'हमारे साझेदार',
      Partners: 'साझेदार',
      noPartnersFound: 'कोई साझेदार नहीं मिला',
      worldwide: 'विश्वव्यापी',
      search: 'खोजें',
      pricing: 'मूल्य निर्धारण',
      loginRegister: 'लॉगिन / पंजीकरण',
      home: 'होम',
      organizations: 'संगठन',
      leagues: 'लीग',
      teams: 'टीमें',
      clubs: 'क्लब',
      coaches: 'कोच',
      tennis: 'टेनिस',
      beachTenis: 'बीच टेनिस',
      'Beach Volley': 'बीच वॉली',
      'Sport Fishing': 'स्पोर्ट फिशिंग',
      Volleyball: 'वॉलीबॉल',
      Sports: 'खेल',
      RESOURSES: 'संसाधन',
      Feature: 'विशेषता',
      'Create account': 'खाता बनाएं',
      Login: 'लॉगिन करें',
      Packages: 'पैकेज',
      'Package one': 'पैकेज एक',
      'Package two': 'पैकेज दो',
      'Package three': 'पैकेज तीन',
      'Package Four': 'पैकेज चार',
      'Customer suppor': 'ग्राहक समर्थन',
      FAQ: 'सामान्य प्रश्न',
      Documentation: 'दस्तावेज़ीकरण',
      'Video tutorials': 'वीडियो ट्यूटोरियल',
      'Submit ticket': 'टिकट जमा करें',
      'Contact us': 'संपर्क करें',
      'Our contacts': 'हमारे संपर्क',
      Padel: 'पैडेल',
      Pickleball: 'पिकलबॉल',
      'FEATURED LEAGUES': 'विशेष लीग',
      View: 'देखें',
      'No leagues found': 'कोई लीग नहीं मिली',
      Athletes: 'एथलीट',
      'Managed by': 'प्रबंधित द्वारा',
      'View League': 'लीग देखें',
      Type: 'प्रकार',
      National: 'राष्ट्रीय',
      International: 'अंतरराष्ट्रीय',
      Local: 'स्थानीय',
      Period: 'अवधि',
      League: 'लीग',
      Country: 'देश',
      Year: 'वर्ष',
      Points: 'अंक',
      Position: 'स्थिति',
      Team: 'टीम',
      'No teams found': 'कोई टीमें नहीं मिलीं',
      'Team Members': 'टीम सदस्य',
      Sport: 'खेल',
      'No clubs found': 'कोई क्लब नहीं मिला',
      Experience: 'अनुभव',
      Students: 'छात्र',
      Author: 'लेखक',
      Category: 'श्रेणी',
      'My Entries': 'मेरे प्रविष्टियां',
      'My Leagues': 'मेरे लीग',
      'My Licences': 'मेरे लाइसेंस',
      'My Teams': 'मेरी टीमें',
      'Get Licence': 'लाइसेंस प्राप्त करें',
      demo: 'डेमो',
      Settings: 'सेटिंग्स',
      'Log out': 'लॉग आउट',
      'Dark view': 'डार्क दृश्य',
      Manager: 'प्रबंधक',
      Tournament: 'टूर्नामेंट',
      Cost: 'लागत',
      Duration: 'अवधि',
      'Purchase on': 'खरीदें',
      'No license found': 'कोई लाइसेंस नहीं मिला',
      Additional: 'अतिरिक्त',
      Status: 'स्थिति',
      Date: 'तारीख',
      'No entries found': 'कोई प्रविष्टियां नहीं मिलीं',
      'My Licenses': 'मेरे लाइसेंस',
      'Add new License': 'नया लाइसेंस जोड़ें',
      'Create Team': 'टीम बनाएं',
      Info: 'जानकारी',
      infoDes: 'कुछ लीग/टूर में भाग लेने के लिए खिलाड़ी को संबंधित सक्रिय लाइसेंस की आवश्यकता होती है।',
      'Select League': 'लीग चुनें',
      'Select License': 'लाइसेंस चुनें',
      'Promo code': 'प्रोमो कोड',
      'I agree to the': 'मैं सहमत हूँ',
      'Terms and Conditions': 'नियम और शर्तें',
      Price: 'मूल्य',
      'Confirm Payment': 'भुगतान की पुष्टि करें',
      History: 'इतिहास',
      'Tournated Manager': 'Tournated प्रबंधक',
      'Tournated Custom': 'Tournated कस्टम',
      'Customer support': 'ग्राहक समर्थन',
      Categories: 'श्रेणियाँ',
      Matches: 'मैच',
      'Organized by': 'आयोजित द्वारा',
      Register: 'पंजीकरण करें',
      Entries: 'प्रविष्टियां',
      Location: 'स्थान',
      'National Tour': 'राष्ट्रीय टूर',
      '4 mins read': '4 मिनट का पढ़ने का समय',
      'Age group': 'आयु समूह',
      'Skills level': 'कौशल स्तर',
      someDes: 'SIA Vertexo © 2020. सर्वाधिकार सुरक्षित',
      'Manage in CMS': 'CMS में प्रबंधित करें',
      'Fact Sheet': 'तथ्य पत्रक',
      General: 'सामान्य',
      Participants: 'प्रतिभागी',
      Groups: 'समूह',
      Draws: 'ड्रॉ',
      'Live Standings': 'लाइव स्थिति',
      'Order of play': 'खेल का क्रम',
      Pairings: 'जोड़ी',
      Standings: 'स्थिति',
      'Final standings': 'अंतिम स्थिति',
      'List & Results': 'सूची और परिणाम',
      'Entry Fee': 'प्रवेश शुल्क',
      'Entry Deadline': 'प्रवेश समय सीमा',
      'Entry deadline': 'प्रवेश समय सीमा',
      'Withdrawal deadline': 'निकासी समय सीमा',
      'Start date': 'प्रारंभ तिथि',
      'End date': 'समाप्ति तिथि',
      and: 'और',
      others: 'अन्य',
      'are confirmed': 'की पुष्टि हो गई है',
      Description: 'विवरण',
      'Tournament Poster': 'टूर्नामेंट पोस्टर',
      'More Info': 'अधिक जानकारी',
      'Additional materials about tournament': 'टूर्नामेंट के बारे में अतिरिक्त सामग्री',
      Regulations: 'विनियम',
      'Tournaments Discord': 'टूर्नामेंट डिस्कॉर्ड',
      discordDes: 'टूर्नामेंट के व्यवस्थापकों से सीधे बात करें, सवाल पूछें और अन्य खिलाड़ियों से बात करें!',
      'Join Channel': 'चैनल से जुड़ें',
      'Fact sheet': 'तथ्य पत्रक',
      'Search hotels and more': 'होटल और अधिक खोजें...',
      Destination: 'गंतव्य',
      'Select Destination': 'गंतव्य चुनें',
      'Check in date': 'चेक इन तिथि',
      'Check out date': 'चेक आउट तिथि',
      'Best players on this tournament': 'इस टूर्नामेंट के सर्वश्रेष्ठ खिलाड़ी',
      Gallery: 'गैलरी',
      'Recent photos': 'हाल की तस्वीरें',
      'Buy licence': 'लाइसेंस खरीदें',
      'Select All': 'सभी का चयन करें',
      "Don't see your sport? Contact us": 'क्या आप अपना खेल नहीं देख रहे हैं? हमसे संपर्क करें',
      'Description is missing': 'विवरण गायब है',
      day: 'दिन',
      days: 'दिन',
      Organization: 'संगठन',
      Athlete: 'एथलीट',
      'Birth year': 'जन्म वर्ष',
      Joined: 'शामिल हुआ',
      'Select Sport': 'खेल चुनें',
      'Select organization': 'संगठन चुनें',
      'Select league': 'लीग चुनें',
      Overview: 'अवलोकन',
      About: 'के बारे में',
      'Win rate': 'जीत दर',
      Achievements: 'उपलब्धियां',
      'Upcoming Matches': 'आगामी मैच',
      'Past Matches': 'पिछले मैच',
      'Upcoming Tournaments': 'आगामी टूर्नामेंट',
      'Purchase history': 'खरीद इतिहास',
      'description and detail': 'सुधारें: विशिष्ट लीग या टूर्नामेंट में भाग लेने के लिए आवश्यक लाइसेंस/सदस्यता प्राप्त करें',
      'Purchase datetime': 'खरीद तिथि और समय',
      'Payment Status': 'भुगतान की स्थिति',
      Licence: 'लाइसेंस',
      Members: 'सदस्य',
      Student: 'छात्र',
      'Personal page': 'व्यक्तिगत पृष्ठ',
      'My Career': 'मेरा करियर',
      'Latest tournaments': 'नवीनतम टूर्नामेंट',
      'Upcoming tournaments': 'आगामी टूर्नामेंट',
      'No members found': 'कोई सदस्य नहीं मिला',
      'No tournaments found': 'कोई टूर्नामेंट नहीं मिला',
      'No matches found': 'कोई मैच नहीं मिला',
      'No rankings found': 'कोई रैंकिंग नहीं मिली',
      'No past matches found': 'कोई पिछले मैच नहीं मिला',
      'No upcoming matches found': 'कोई आगामी मैच नहीं मिला',
      'No achievements found': 'कोई उपलब्धि नहीं मिली',
      Documents: 'दस्तावेज़',
      Contact: 'संपर्क',
      'No photos added': 'कोई तस्वीरें नहीं जोड़ी गईं',
      'Manage league': 'लीग प्रबंधित करें',
      'No documents added': 'कोई दस्तावेज़ नहीं जोड़े गए',
      Email: 'ईमेल',
      Phone: 'फोन',
      Website: 'वेबसाइट',
      Place: 'स्थान',
      'Tournament Name': 'टूर्नामेंट नाम',
      'Manage organization': 'संगठन प्रबंधित करें',
      'Terms & Conditions': 'नियम और शर्तें',
      LEGAL: 'कानूनी',
      'Privacy policy': 'गोपनीयता नीति',
      'Cancellation policy': 'रद्द करने की नीति',
      GDPR: 'जी.डी.पी.आर.',
      'All Rights Reserved': 'सर्वाधिकार सुरक्षित',
      'League [Category]': 'लीग [श्रेणी]',
      'Other tournaments': 'अन्य टूर्नामेंट',
      'Points Details': 'अंक विवरण',
      'International rating': 'अंतरराष्ट्रीय रेटिंग',
      Day: 'दिन',
      Days: 'दिन',
      Tennis: 'टेनिस',
      'Beach Tennis': 'बीच टेनिस',
      'Beach Volleyball': 'बीच वॉलीबॉल',
      Chess: 'शतरंज',
      'In progress': 'प्रगति में',
      Registration: 'पंजीकरण',
      'Deadline past': 'समय सीमा समाप्त',
      Completed: 'पूरा हुआ',
      'No points yet': 'अभी तक कोई अंक नहीं',
      'is confirmed': 'की पुष्टि हो गई है',
      Contacts: 'संपर्क',
      Organizer: 'आयोजक',
      'Featured athletes': 'विशेष एथलीट',
      'Top-ranked participants of the tournament': 'टूर्नामेंट के शीर्ष रैंक वाले प्रतिभागी',
      NR: 'एनआर',
      PLAYER: 'खिलाड़ी',
      CITY: 'शहर',
      RATING: 'रेटिंग',
      CLUB: 'क्लब',
      TOTAL: 'कुल',
      SEGMENT: 'सेगमेंट',
      SEED: 'बीज',
      Search: 'खोजें',
      'No participants found': 'कोई प्रतिभागी नहीं मिला',
      Total: 'कुल',
      Seed: 'बीज',
      Rating: 'रेटिंग',
      Rank: 'रैंक',
      'Category:': 'श्रेणी:',
      'No category found': 'कोई श्रेणी नहीं मिली',
      'No Rounds found': 'कोई राउंड नहीं मिला',
      White: 'सफेद',
      Black: 'काला',
      Name: 'नाम',
      Pts: 'अंक',
      Score: 'स्कोर',
      'There is no available pairings!': 'कोई उपलब्ध जोड़ी नहीं है!',
      'Generating PDF...': 'पीडीएफ जनरेट हो रहा है...',
      'PDF Generated Successfully!': 'पीडीएफ सफलतापूर्वक जनरेट हो गया!',
      'PDF Downloaded Successfully!': 'पीडीएफ सफलतापूर्वक डाउनलोड हो गया!',
      'Downloading...': 'डाउनलोड हो रहा है...',
      'Pairing Standings': 'जोड़ी स्थिति',
      Opponents: 'प्रतिद्वंदी',
      BHC1: 'बीएचसी1',
      BHC: 'बीएचसी',
      BGP: 'बीजीपी',
      WIN: 'जीत',
      'There is no available entries!': 'कोई उपलब्ध प्रविष्टियां नहीं हैं!',
      "I'm a coach/parent": 'मैं कोच/अभिभावक हूं',
      'Step 1': 'चरण 1',
      'Select category': 'श्रेणी चुनें',
      Request: 'अनुरोध',
      'Register & Pay Now': 'पंजीकरण करें और अभी भुगतान करें',
      'Register & Pay On-Site': 'पंजीकरण करें और ऑन-साइट भुगतान करें',
      'Terms And Conditions': 'नियम और शर्तें',
      'Latest Winners': 'नवीनतम विजेता',
      'No winners found': 'कोई विजेता नहीं मिला',
      'No news found': 'कोई समाचार नहीं मिला',
      'No partners found': 'कोई साझेदार नहीं मिला',
      'Skills Levels': 'कौशल स्तर',
      'To get register for the tournament you must have active tour licence.': 'टूर्नामेंट के लिए पंजीकरण करने के लिए आपके पास सक्रिय टूर लाइसेंस होना चाहिए।',
      'Contact Details': 'संपर्क विवरण',
      'Our Partners': 'हमारे साझेदार',
      'View all': 'सभी देखें',
      Member: 'सदस्य',
      'Team Name': 'टीम नाम',
      News: 'समाचार',
      'Men Pro': 'पुरुष प्रो',
      'Latvia Tennis Union League': 'लातविया टेनिस यूनियन लीग',
      Leagues: 'लीग',
      'No league found': 'कोई लीग नहीं मिली',
      'Home Page': 'होम पेज',
      Upcoming: 'आगामी',
      'This month': 'इस महीने',
      'Last 7 days': 'पिछले 7 दिन',
      'Last 30 days': 'पिछले 30 दिन',
      Hours: 'घंटे',
      Minutes: 'मिनट',
      Teams: 'टीमें',
      'No student found': 'कोई छात्र नहीं मिला',
      'Other news': 'अन्य समाचार',
      'Don’t have an account?': 'क्या आपका खाता नहीं है?',
      'Sign up': 'साइन अप करें',
      'or log in': 'या लॉगिन करें',
      'Did you forget your password?': 'क्या आपने अपना पासवर्ड भूल गए?',
      'Must be a valid email': 'एक मान्य ईमेल होना चाहिए',
      'Email is required': 'ईमेल आवश्यक है',
      'Password is required': 'पासवर्ड आवश्यक है',
      Password: 'पासवर्ड',
      'Remember me': 'मुझे याद रखें',
      'Log In': 'लॉग इन करें',
      'Unauthorized , please sign in': 'अनधिकृत, कृपया साइन इन करें',
      'Reset your password': 'अपना पासवर्ड रीसेट करें',
      'Enter the e-mail address': 'ईमेल पता दर्ज करें',
      'Enter your e-mail address with which you are registered': 'वह ईमेल पता दर्ज करें जिसके साथ आप पंजीकृत हैं',
      'Please check your email for reset password link!': 'कृपया अपना ईमेल रीसेट पासवर्ड लिंक के लिए चेक करें!',
      'Send instructions': 'निर्देश भेजें',
      'My Licenes': 'मेरे लाइसेंस',
      'My leagues': 'मेरे लीग',
      'My tournaments': 'मेरे टूर्नामेंट',
      'My matches': 'मेरे मैच',
      'My entries': 'मेरी प्रविष्टियां',
      'My rankings': 'मेरी रैंकिंग',
      Stats: 'आंकड़े',
      'Leagues took part in': 'लीग जिनमें भाग लिया',
      'Active licences': 'सक्रिय लाइसेंस',
      Titles: 'शीर्षक',
      'Matches played': 'खेली गई मैच',
      'Matches lost': 'हारी गई मैच',
      'Games/points played': 'खेली गई गेम/अंक',
      'All sports': 'सभी खेल',
      'All organizations': 'सभी संगठन',
      'All leagues': 'सभी लीग',
      Registered: 'पंजीकृत',
      Withdraw: 'निकासी',
      'Withdraw deadline passed': 'निकासी की समय सीमा समाप्त हो गई',
      'Request Withdrawn': 'अनुरोध वापस लिया गया',
      'Something went wrong!': 'कुछ गलत हो गया!',
      Withdrawal: 'निकासी',
      'Entry to withdraw': 'निकासी के लिए प्रविष्टि',
      'Withdrawal Reason': 'निकासी का कारण',
      'Write your reason here': 'यहां अपना कारण लिखें',
      Confirm: 'पुष्टि करें',
      'Please specify reason': 'कृपया कारण निर्दिष्ट करें',
      'AUDI - The Beach Tennis Championship': 'ऑडी - बीच टेनिस चैंपियनशिप',
      'Get licence': 'लाइसेंस प्राप्त करें',
      'Additional questions': 'अतिरिक्त प्रश्न',
      'Select duration': 'अवधि चुनें',
      Career: 'करियर',
      'Third Party Licenes': 'तृतीय पक्ष लाइसेंस',
      'User updated successfully': 'उपयोगकर्ता सफलतापूर्वक अपडेट किया गया',
      'User Account': 'उपयोगकर्ता खाता',
      'We recommend an image that is 256 x 256 pixels': 'हम एक 256 x 256 पिक्सल की छवि की अनुशंसा करते हैं',
      'Enter Email Address': 'ईमेल पता दर्ज करें',
      'Enter name': 'नाम दर्ज करें',
      Surname: 'उपनाम',
      'Enter surname': 'उपनाम दर्ज करें',
      'Contact phone number': 'संपर्क फोन नंबर',
      'Enter phone number': 'फोन नंबर दर्ज करें',
      'Date of Birth': 'जन्म तिथि',
      'Date of birth': 'जन्म तिथि',
      Gender: 'लिंग',
      'Select gender': 'लिंग चुनें',
      'Representing country': 'प्रतिनिधि देश',
      'Select country': 'देश चुनें',
      City: 'शहर',
      'Select role(s)': 'भूमिका(एं) चुनें',
      Address: 'पता',
      'Enter address': 'पता दर्ज करें',
      'Parent found': 'अभिभावक मिला',
      'Parent not found': 'अभिभावक नहीं मिला',
      'Removed coach': 'कोच को हटा दिया गया',
      'Failed to removed coach': 'कोच को हटाने में विफल',
      'Coach created': 'कोच बनाया गया',
      'Failed to create coach': 'कोच बनाने में विफल',
      Club: 'क्लब',
      Save: 'सहेजें',
      'Select club': 'क्लब चुनें',
      Coach: 'कोच',
      'Select a coach': 'कोच चुनें',
      Parent: 'अभिभावक',
      'Enter email': 'ईमेल दर्ज करें',
      Biography: 'जीवनी',
      'Write a bio': 'एक जीवनी लिखें',
      CHECKBOXES: 'चेकबॉक्स',
      'Give access to coach to purchase licences and manage entries on your behalf': 'कोच को लाइसेंस खरीदने और आपकी ओर से प्रविष्टियां प्रबंधित करने का अधिकार दें',
      'Give access to club manager(-es) to purchase licences and manage entries on your behalf': 'क्लब प्रबंधक(-यों) को लाइसेंस खरीदने और आपकी ओर से प्रविष्टियां प्रबंधित करने का अधिकार दें',
      'Give access to parent(-s) to purchase licences and manage entries on your behalf': 'माता-पिता(-यों) को लाइसेंस खरीदने और आपकी ओर से प्रविष्टियां प्रबंधित करने का अधिकार दें',
      Socials: 'सोशल्स',
      'Insert link here': 'यहां लिंक डालें',
      Facebook: 'फेसबुक',
      Instagram: 'इंस्टाग्राम',
      Linkedin: 'लिंक्डइन',
      Tiktok: 'टिकटॉक',
      Youtube: 'यूट्यूब',
      'Enter Id': 'आईडी दर्ज करें',
      'Password should be 8 chars minimum.': 'पासवर्ड न्यूनतम 8 अक्षर का होना चाहिए।',
      'Passwords must match': 'पासवर्ड मेल खाना चाहिए',
      'Password changed successfully': 'पासवर्ड सफलतापूर्वक बदल गया',
      'New Password': 'नया पासवर्ड',
      'Re-Enter': 'पासवर्ड की पुष्टि करें',
      'Name is Required': 'नाम आवश्यक है',
      'Surname is Required': 'उपनाम आवश्यक है',
      'Re enter password': 'पासवर्ड फिर से दर्ज करें',
      'User is already registered': 'उपयोगकर्ता पहले से पंजीकृत है',
      'Create an account': 'खाता बनाएं',
      'Already have an account?': 'क्या आपके पास पहले से एक खाता है?',
      'Log in': 'लॉग इन करें',
      'or create account': 'या खाता बनाएं',
      "I'm creating an account for a kid": 'मैं एक बच्चे के लिए खाता बना रहा हूँ',
      'sign-up description':
        'आप एक टूर्नामेंट प्रतिभागी के लिए खाता बना रहे हैं। यदि आप एक बच्चे को पंजीकृत कर रहे हैं, तो कृपया उसके लिए एक प्रोफ़ाइल बनाएं। जल्द ही कोच और अभिभावक प्रोफाइल भी उपलब्ध होंगे, जिससे आप बच्चे के प्रोफ़ाइल से जुड़ सकेंगे।',
      'Email Address': 'ईमेल पता',
      Continue: 'जारी रखें',
      'Pick a sport': 'एक खेल चुनें',
      'Pick a sport that you play': 'वह खेल चुनें जिसे आप खेलते हैं',
      'Gender is required': 'लिंग आवश्यक है',
      'Phone is required': 'फोन आवश्यक है',
      'Citizenship is required': 'देश आवश्यक है',
      'Dob is required': 'जन्म तिथि आवश्यक है',
      'FIDE ID Not found': 'FIDE आईडी नहीं मिली',
      'FIDE ID found': 'FIDE आईडी मिली',
      'Tell us more about you': 'हमें अपने बारे में अधिक बताएं',
      'More information about yourself': 'अपने बारे में अधिक जानकारी',
      'Enter FIDE ID': 'FIDE आईडी दर्ज करें',
      'IPIN ID is correct': 'IPIN आईडी सही है',
      'Your Licenses': 'आपके लाइसेंस',
      'Attach your licenses': 'अपने लाइसेंस संलग्न करें',
      Skip: 'छोड़ें',
      'User registered successfully': 'उपयोगकर्ता सफलतापूर्वक पंजीकृत हो गया',
      'What is your primary role in the world of sports?': 'खेल की दुनिया में आपकी मुख्य भूमिका क्या है?',
      'Register & Go to profile': 'पंजीकरण करें और प्रोफ़ाइल पर जाएं',
      'PUBLISHED ON': 'प्रकाशित किया गया',
      'Federation member': 'संघ सदस्य',
      'Club owner': 'क्लब मालिक',
      'Club manager': 'क्लब प्रबंधक',
      Referee: 'रेफरी',
      'Tournament organizer': 'टूर्नामेंट आयोजक',
      'Sport fan': 'खेल प्रेमी',
      Other: 'अन्य',
      PAY: 'भुगतान करें',
      '1 Year': '1 वर्ष',
      '2 Years': '2 वर्ष',
      '3 Years': '3 वर्ष',
      '4 Years': '4 वर्ष',
      'Registration time': 'पंजीकरण समय',
      'In progress / Upcoming': 'प्रगति में / आगामी',
      'Matches won': 'जीते गए मैच',
      'In progress / upcoming entries': 'प्रगति में / आगामी प्रविष्टियां',
      'In progress / upcoming tournaments': 'प्रगति में / आगामी टूर्नामेंट',
      'Past tournaments': 'पिछले टूर्नामेंट',
      'Past entries': 'पिछली प्रविष्टियां',
      'Your password must be different to previous passwords': 'आपका पासवर्ड पिछले पासवर्ड से अलग होना चाहिए',
      'less than a minute': 'एक मिनट से कम',
      read: 'पढ़ें',
      minute: 'मिनट',
      'Select Licence': 'लाइसेंस चुनें',
      'Start time': 'प्रारंभ समय',
      'Try again': 'फिर से प्रयास करें',
      maintenanceMessage: 'हम वर्तमान में प्लेटफ़ॉर्म को अपडेट कर रहे हैं, इसमें कुछ मिनट लग सकते हैं। कृपया थोड़ी देर बाद पुनः प्रयास करें।',
      networkErrorMessage: 'कृपया अपने नेटवर्क कनेक्शन की जाँच करें और पुनः प्रयास करें।',
      errorTitle: 'ओह! कुछ गड़बड़ है!',
      'View List': 'सूची देखें',
      Free: 'मुफ्त',
      'Primary Sport': 'प्राथमिक खेल',
      'Role(s)': 'भूमिका(एं)',
      Menu: 'मेन्यू',
      Calendar: 'कैलेंडर',
      'Manage tournament': 'टूर्नामेंट प्रबंधित करें',
      'Partner search': 'साझेदार खोज',
      'Partner Search': 'साझेदार खोज',
      'Searching...': 'खोज जारी है...',
      Apply: 'लागू करें',
      Delete: 'हटाएं',
      'Create application': 'आवेदन बनाएं',
      'No partner search applications found': 'कोई साझेदार खोज आवेदन नहीं मिला',
      'Log in to apply': 'लागू करने के लिए लॉगिन करें',
      Player: 'खिलाड़ी',
      'Time of registration': 'पंजीकरण का समय',
      confirmed: 'पुष्टि की गई',
      declined: 'अस्वीकृत',
      'payment pending': 'भुगतान लंबित',
      WITHDRAW: 'निकासी करें',
      'No other tournament found': 'कोई अन्य टूर्नामेंट नहीं मिला',
      welcomeTextCustom:
        'Tournated द्वारा संचालित, एक समग्र खेल प्रबंधन सॉफ़्टवेयर जो खेल संगठनों को कुशलतापूर्वक प्रबंधित करने, बढ़ाने और उनके संचालन का मुद्रीकरण करने के लिए सक्षम बनाता है। यह समाधान संगठनों को कस्टम ऑनलाइन प्लेटफ़ॉर्म बनाने, लीग, क्लब, टूर्नामेंट और अन्य आयोजनों का प्रबंधन स्वचालित और सरल बनाने, दर्शकों की भागीदारी बढ़ाने, और भुगतान को आसानी से संसाधित करने की अनुमति देता है।',
      welcomeText:
        'पांच वर्षों के अनुसंधान और विकास और 200 से अधिक संगठनों के सहयोग का परिणाम है, यह किसी भी आकार के खेल संगठनों के लिए एक अभिनव, अनुकूलन योग्य, उपयोगकर्ता-मित्रतापूर्ण, और किफायती समग्र समाधान है।',
      'Did you have an account in one of ': 'क्या आपका इनमें से किसी एक में खाता था ',
      those: 'उन',
      'platforms?': 'प्लेटफार्मों पर?',
      'Recover password': 'पासवर्ड पुनर्प्राप्त करें',
      newUser: 'मैं एक नया उपयोगकर्ता हूं। पंजीकरण करें',
      'Continue as a guest': 'अतिथि के रूप में जारी रखें',
      Support: 'समर्थन',
      'Welcome to': 'स्वागत है',
      'If you have an account on one of ': 'अगर आपका इनमें से किसी एक पर खाता है ',
      these: 'इन',
      'platforms, log in with your e-mail or create a new profile': 'प्लेटफार्मों पर, अपने ईमेल से लॉगिन करें या एक नया प्रोफाइल बनाएं',
      'Reset password': 'पासवर्ड रीसेट करें',
      'Welcome to the': 'स्वागत है',
      platform: 'प्लेटफार्म',
      'Powered by': 'संचालित द्वारा',
      'All Categories': 'सभी श्रेणियाँ',
      'Tournament Results': 'टूर्नामेंट परिणाम',
      versionText: 'बीटा लाइव है। कृपया अपनी प्रतिक्रिया सबमिट करें',
      here: 'यहाँ',
      'Full view': 'पूरा दृश्य',
      Timeline: 'समयरेखा',
      Grid: 'ग्रिड',
      "Bird's View": 'पक्षी की दृष्टि',
      'Third Party Licences': 'तृतीय पक्ष लाइसेंस',
      Friend: 'मित्र',
      'Step 2': 'चरण 2:',
      'Step 3': 'चरण 3:',
      'Your Details': 'आपका विवरण',
      'Partner Details': 'साझेदार विवरण',
      'Enter partner email': 'साझेदार का ईमेल दर्ज करें',
      'Register & Pay': 'पंजीकरण करें और भुगतान करें',
      'On-Site': 'ऑन-साइट',
      Now: 'अभी',
    },
  },
  hu: {
    translation: {
      header: {
        discover: 'Fedezd fel',
        enterName: 'Írja be a nevet',
        sportsManager: 'Sportmenedzser?',
        learnHow: 'Tanulja meg hogyan',
        accelerate: 'felgyorsíthatjuk a szervezeted.',
        powering: 'Meghajtjuk',
        tournaments: 'versenyek,',
        leagues: 'ligák,',
        organizations: 'szervezetek,',
        clubs: 'klubok,',
        coaches: 'edzők,',
        placeholder: {
          tournaments: 'Írja be a verseny nevét',
          leagues: 'Írja be a liga nevét',
          organizations: 'Írja be a szervezet nevét',
          clubs: 'Írja be a klub nevét',
          coaches: 'Írja be az edző nevét',
        },
      },
      registrationOpen: 'Regisztráció nyitva',
      read_more: 'Tovább',
      registrationClosed: 'Regisztráció lezárva',
      live: 'Élő',
      Close: 'Bezárás',
      bannerHeading: 'Kezdje el ingyenesen kezelni a versenyeit',
      bannerDescription: 'A nevezések kezelésétől a csoportok, sorsolások és játékrendek automatikus generálásáig, eredmények kezeléséig és még sok másig',
      startNow: 'Kezd el most',
      featureTournaments: 'Kiemelt versenyek',
      tournamentNotFound: 'Nincs kiemelt verseny',
      viewAll: 'Összes megtekintése',
      featureLeague: 'Kiemelt ligák',
      leaguesNotFound: 'Nincs kiemelt liga',
      Tournaments: 'Versenyek',
      NoTournamentsFound: 'Nem található verseny',
      Rankings: 'Rangsorok',
      noRankingFound: 'Nem található rangsor',
      news: 'Hírek',
      noNewsFound: 'Nem található hír',
      partners: 'Partnereink',
      Partners: 'Partnerek',
      noPartnersFound: 'Nem található partner',
      worldwide: 'Világszerte',
      search: 'Keresés',
      pricing: 'Árazás',
      loginRegister: 'Bejelentkezés / Regisztráció',
      home: 'Főoldal',
      organizations: 'Szervezetek',
      leagues: 'Ligák',
      teams: 'Csapatok',
      clubs: 'Klubok',
      coaches: 'Edzők',
      tennis: 'Tenisz',
      beachTenis: 'Strandtenisz',
      'Beach Volley': 'Strandröplabda',
      'Sport Fishing': 'Sporthorgászat',
      Volleyball: 'Röplabda',
      Sports: 'Sportok',
      RESOURSES: 'FORRÁSOK',
      Feature: 'Kiemelt',
      'Create account': 'Fiók létrehozása',
      Login: 'Bejelentkezés',
      Packages: 'Csomagok',
      'Package one': 'Egyes csomag',
      'Package two': 'Kettes csomag',
      'Package three': 'Hármas csomag',
      'Package Four': 'Négyes csomag',
      'Customer suppor': 'Ügyféltámogatás',
      FAQ: 'GYIK',
      Documentation: 'Dokumentáció',
      'Video tutorials': 'Videós oktatóanyagok',
      'Submit ticket': 'Jegy beküldése',
      'Contact us': 'Kapcsolat',
      'Our contacts': 'Elérhetőségeink',
      Padel: 'Padel',
      Pickleball: 'Pickleball',
      'FEATURED LEAGUES': 'KIEMELT LIGÁK',
      View: 'Megtekintés',
      'No leagues found': 'Nem található liga',
      Athletes: 'Sportolók',
      'Managed by': 'Kezelés által',
      'View League': 'Liga megtekintése',
      Type: 'Típus',
      National: 'Nemzeti',
      International: 'Nemzetközi',
      Local: 'Helyi',
      Period: 'Időszak',
      League: 'Liga',
      Country: 'Ország',
      Year: 'Év',
      Points: 'Pontok',
      Position: 'Pozíció',
      Team: 'Csapat',
      'No teams found': 'Nem található csapat',
      'Team Members': 'Csapattagok',
      Sport: 'Sport',
      'No clubs found': 'Nem található klub',
      Experience: 'Tapasztalat',
      Students: 'Diákok',
      Author: 'Szerző',
      Category: 'Kategória',
      'My Entries': 'Nevezéseim',
      'My Leagues': 'Ligáim',
      'My Licences': 'Licenceim',
      'My Teams': 'Csapataim',
      'Get Licence': 'Licence szerzése',
      demo: 'demo',
      Settings: 'Beállítások',
      'Log out': 'Kijelentkezés',
      'Dark view': 'Sötét nézet',
      Manager: 'Menedzser',
      Tournament: 'Verseny',
      Cost: 'Költség',
      Duration: 'Időtartam',
      'Purchase on': 'Vásárlás',
      'No license found': 'Nem található licenc',
      Additional: 'Továbbiak',
      Status: 'Állapot',
      Date: 'Dátum',
      'No entries found': 'Nem található nevezés',
      'My Licenses': 'Licenceim',
      'Add new License': 'Új licenc hozzáadása',
      'Create Team': 'Csapat létrehozása',
      Info: 'Információ',
      infoDes: 'A részvételhez bizonyos ligákban / túrákon aktív licenccel kell rendelkezni.',
      'Select League': 'Válasszon ligát',
      'Select License': 'Válasszon licencet',
      'Promo code': 'Promóciós kód',
      'I agree to the': 'Elfogadom a',
      'Terms and Conditions': 'feltételeket',
      Price: 'Ár',
      'Confirm Payment': 'Fizetés megerősítése',
      History: 'Előzmények',
      'Tournated Manager': 'Tournated Menedzser',
      'Tournated Custom': 'Tournated Egyedi',
      'Customer support': 'Ügyféltámogatás',
      Categories: 'Kategóriák',
      Matches: 'Mérkőzések',
      'Organized by': 'Szervezés által',
      Register: 'Regisztráció',
      Entries: 'Nevezések',
      Location: 'Helyszín',
      'National Tour': 'Nemzeti túra',
      '4 mins read': '4 perc olvasás',
      'Age group': 'Korosztály',
      'Skills level': 'Képesség szintje',
      someDes: 'SIA Vertexo © 2020. Minden jog fenntartva',
      'Manage in CMS': 'Kezelés CMS-ben',
      'Fact Sheet': 'Ténylap',
      General: 'Általános',
      Participants: 'Résztvevők',
      Groups: 'Csoportok',
      Draws: 'Sorsolások',
      'Live Standings': 'Élő állások',
      'Order of play': 'Játékrend',
      Pairings: 'Párosítások',
      Standings: 'Állások',
      'Final standings': 'Végső állások',
      'List & Results': 'Lista és eredmények',
      'Entry Fee': 'Nevezési díj',
      'Entry Deadline': 'Nevezési határidő',
      'Entry deadline': 'Nevezési határidő',
      'Withdrawal deadline': 'Visszalépési határidő',
      'Start date': 'Kezdés dátuma',
      'End date': 'Befejezés dátuma',
      and: 'és',
      others: 'mások',
      'are confirmed': 'megerősítve',
      Description: 'Leírás',
      'Tournament Poster': 'Versenyposzter',
      'More Info': 'További információ',
      'Additional materials about tournament': 'További anyagok a versenyről',
      Regulations: 'Szabályok',
      'Tournaments Discord': 'Versenyek Discord',
      discordDes: 'Közvetlenül beszéljen a verseny adminokkal, tegyen fel kérdéseket és beszéljen más játékosokkal!',
      'Join Channel': 'Csatlakozzon a csatornához',
      'Fact sheet': 'Ténylap',
      'Search hotels and more': 'Keressen szállodákat és még többet...',
      Destination: 'Úti cél',
      'Select Destination': 'Válasszon úti célt',
      'Check in date': 'Bejelentkezés dátuma',
      'Check out date': 'Kijelentkezés dátuma',
      'Best players on this tournament': 'A legjobb játékosok ezen a versenyen',
      Gallery: 'Galéria',
      'Recent photos': 'Legutóbbi fotók',
      'Buy licence': 'Licenc vásárlása',
      'Select All': 'Mind kiválasztása',
      "Don't see your sport? Contact us": 'Nem látja a sportját? Lépjen kapcsolatba velünk',
      'Description is missing': 'Leírás hiányzik',
      day: 'nap',
      days: 'napok',
      Organization: 'Szervezet',
      Athlete: 'Sportoló',
      'Birth year': 'Születési év',
      Joined: 'Csatlakozott',
      'Select Sport': 'Válasszon sportot',
      'Select organization': 'Válasszon szervezetet',
      'Select league': 'Válasszon ligát',
      Overview: 'Áttekintés',
      About: 'Rólunk',
      'Win rate': 'Győzelmi arány',
      Achievements: 'Eredmények',
      'Upcoming Matches': 'Közelgő mérkőzések',
      'Past Matches': 'Korábbi mérkőzések',
      'Upcoming Tournaments': 'Közelgő versenyek',
      'Purchase history': 'Vásárlási előzmények',
      'description and detail': 'Fejlesztés: Szerezzen licencet/memberséget, amely szükséges bizonyos ligákban vagy versenyeken való részvételhez',
      'Purchase datetime': 'Vásárlás dátuma és ideje',
      'Payment Status': 'Fizetési állapot',
      Licence: 'Licenc',
      Members: 'Tagok',
      Student: 'Diák',
      'Personal page': 'Személyes oldal',
      'My Career': 'Karrierem',
      'Latest tournaments': 'Legújabb versenyek',
      'Upcoming tournaments': 'Közelgő versenyek',
      'No members found': 'Nem található tag',
      'No tournaments found': 'Nem található verseny',
      'No matches found': 'Nem található mérkőzés',
      'No rankings found': 'Nem található rangsor',
      'No past matches found': 'Nem található korábbi mérkőzés',
      'No upcoming matches found': 'Nem található közelgő mérkőzés',
      'No achievements found': 'Nem található eredmény',
      Documents: 'Dokumentumok',
      Contact: 'Kapcsolat',
      'No photos added': 'Nem adtak hozzá fotókat',
      'Manage league': 'Liga kezelése',
      'No documents added': 'Nem adtak hozzá dokumentumokat',
      Email: 'E-mail',
      Phone: 'Telefon',
      Website: 'Weboldal',
      Place: 'Hely',
      'Tournament Name': 'Verseny neve',
      'Manage organization': 'Szervezet kezelése',
      'Terms & Conditions': 'Felhasználási feltételek',
      LEGAL: 'JOGI',
      'Privacy policy': 'Adatvédelmi nyilatkozat',
      'Cancellation policy': 'Lemondási feltételek',
      GDPR: 'GDPR',
      'All Rights Reserved': 'Minden jog fenntartva',
      'League [Category]': 'Liga [Kategória]',
      'Other tournaments': 'Egyéb versenyek',
      'Points Details': 'Pontok részletei',
      'International rating': 'Nemzetközi értékelés',
      Day: 'Nap',
      Days: 'Napok',
      Tennis: 'Tenisz',
      'Beach Tennis': 'Strandtenisz',
      'Beach Volleyball': 'Strandröplabda',
      Chess: 'Sakk',
      'In progress': 'Folyamatban',
      Registration: 'Regisztráció',
      'Deadline past': 'Határidő lejárt',
      Completed: 'Befejezve',
      'No points yet': 'Még nincs pont',
      'is confirmed': 'megerősítve',
      Contacts: 'Kapcsolatok',
      Organizer: 'Szervező',
      'Featured athletes': 'Kiemelt sportolók',
      'Top-ranked participants of the tournament': 'A verseny legjobban rangsorolt résztvevői',
      NR: 'NR',
      PLAYER: 'JÁTÉKOS',
      CITY: 'VÁROS',
      RATING: 'ÉRTÉKELÉS',
      CLUB: 'KLUB',
      TOTAL: 'ÖSSZESEN',
      SEGMENT: 'SZEGMENS',
      SEED: 'SEED',
      Search: 'Keresés',
      'No participants found': 'Nem található résztvevő',
      Total: 'Összesen',
      Seed: 'Seed',
      Rating: 'Értékelés',
      Rank: 'Rang',
      'Category:': 'Kategória:',
      'No category found': 'Nem található kategória',
      'No Rounds found': 'Nem található forduló',
      White: 'Fehér',
      Black: 'Fekete',
      Name: 'Név',
      Pts: 'Pontok',
      Score: 'Pontszám',
      'There is no available pairings!': 'Nincs elérhető párosítás!',
      'Generating PDF...': 'PDF generálása...',
      'PDF Generated Successfully!': 'PDF sikeresen generálva!',
      'PDF Downloaded Successfully!': 'PDF sikeresen letöltve!',
      'Downloading...': 'Letöltés...',
      'Pairing Standings': 'Párosítási állások',
      Opponents: 'Ellenfelek',
      BHC1: 'BHC1',
      BHC: 'BHC',
      BGP: 'BGP',
      WIN: 'GYŐZELEM',
      'There is no available entries!': 'Nincs elérhető nevezés!',
      "I'm a coach/parent": 'Edző/szülő vagyok',
      'Step 1': '1. lépés',
      'Select category': 'Válasszon kategóriát',
      Request: 'Kérés',
      'Register & Pay Now': 'Regisztráció és fizetés most',
      'Register & Pay On-Site': 'Regisztráció és fizetés helyszínen',
      'Terms And Conditions': 'Feltételek és kondíciók',
      'Latest Winners': 'Legutóbbi nyertesek',
      'No winners found': 'Nem található nyertes',
      'No news found': 'Nem található hír',
      'No partners found': 'Nem található partner',
      'Skills Levels': 'Képességi szintek',
      'To get register for the tournament you must have active tour licence.': 'A versenyre való regisztrációhoz aktív túra licenccel kell rendelkeznie.',
      'Contact Details': 'Kapcsolati adatok',
      'Our Partners': 'Partnereink',
      'View all': 'Összes megtekintése',
      Member: 'Tag',
      'Team Name': 'Csapat neve',
      News: 'Hírek',
      'Men Pro': 'Férfi Pro',
      'Latvia Tennis Union League': 'Lettország Tenisz Unió Liga',
      Leagues: 'Ligák',
      'No league found': 'Nem található liga',
      'Home Page': 'Főoldal',
      Upcoming: 'Közelgő',
      'This month': 'Ebben a hónapban',
      'Last 7 days': 'Az elmúlt 7 napban',
      'Last 30 days': 'Az elmúlt 30 napban',
      Hours: 'Órák',
      Minutes: 'Percek',
      Teams: 'Csapatok',
      'No student found': 'Nem található diák',
      'Other news': 'További hírek',
      'Don’t have an account?': 'Nincs fiókja?',
      'Sign up': 'Regisztráljon',
      'or log in': 'vagy jelentkezzen be',
      'Did you forget your password?': 'Elfelejtette a jelszavát?',
      'Must be a valid email': 'Érvényes e-mail cím szükséges',
      'Email is required': 'E-mail szükséges',
      'Password is required': 'Jelszó szükséges',
      Password: 'Jelszó',
      'Remember me': 'Emlékezz rám',
      'Log In': 'Bejelentkezés',
      'Unauthorized , please sign in': 'Jogosulatlan, kérjük jelentkezzen be',
      'Reset your password': 'Jelszó visszaállítása',
      'Enter the e-mail address': 'Adja meg az e-mail címet',
      'Enter your e-mail address with which you are registered': 'Adja meg az e-mail címet, amellyel regisztrált',
      'Please check your email for reset password link!': 'Kérjük, ellenőrizze az e-mailjét a jelszó-visszaállítási linkért!',
      'Send instructions': 'Utasítások küldése',
      'My Licenes': 'Licenceim',
      'My leagues': 'Ligáim',
      'My tournaments': 'Versenyeim',
      'My matches': 'Mérkőzéseim',
      'My entries': 'Nevezéseim',
      'My rankings': 'Rangsoraim',
      Stats: 'Statisztikák',
      'Leagues took part in': 'Részt vett ligák',
      'Active licences': 'Aktív licencek',
      Titles: 'Címek',
      'Matches played': 'Lejátszott mérkőzések',
      'Matches lost': 'Elvesztett mérkőzések',
      'Games/points played': 'Lejátszott játékok/pontok',
      'All sports': 'Minden sport',
      'All organizations': 'Minden szervezet',
      'All leagues': 'Minden liga',
      Registered: 'Regisztrált',
      Withdraw: 'Visszalépés',
      'Withdraw deadline passed': 'Visszalépési határidő lejárt',
      'Request Withdrawn': 'Kérés visszavonva',
      'Something went wrong!': 'Valami hiba történt!',
      Withdrawal: 'Visszalépés',
      'Entry to withdraw': 'Visszalépendő nevezés',
      'Withdrawal Reason': 'Visszalépés oka',
      'Write your reason here': 'Írja be itt az okot',
      Confirm: 'Megerősítés',
      'Please specify reason': 'Kérjük, adja meg az okot',
      'AUDI - The Beach Tennis Championship': 'AUDI - A Strandtenisz Bajnokság',
      'Get licence': 'Licenc szerzése',
      'Additional questions': 'További kérdések',
      'Select duration': 'Válasszon időtartamot',
      Career: 'Karrier',
      'Third Party Licenes': 'Harmadik fél licencei',
      'User updated successfully': 'Felhasználó sikeresen frissítve',
      'User Account': 'Felhasználói fiók',
      'We recommend an image that is 256 x 256 pixels': '256 x 256 pixel méretű képet ajánlunk',
      'Enter Email Address': 'Írja be az e-mail címet',
      'Enter name': 'Írja be a nevet',
      Surname: 'Vezetéknév',
      'Enter surname': 'Írja be a vezetéknevet',
      'Contact phone number': 'Kapcsolati telefonszám',
      'Enter phone number': 'Írja be a telefonszámot',
      'Date of Birth': 'Születési dátum',
      'Date of birth': 'Születési dátum',
      Gender: 'Nem',
      'Select gender': 'Válasszon nemet',
      'Representing country': 'Képviselt ország',
      'Select country': 'Válasszon országot',
      City: 'Város',
      'Select role(s)': 'Válasszon szerepet/szerepeket',
      Address: 'Cím',
      'Enter address': 'Írja be a címet',
      'Parent found': 'Szülő megtalálva',
      'Parent not found': 'Szülő nem található',
      'Removed coach': 'Edző eltávolítva',
      'Failed to removed coach': 'Az edző eltávolítása nem sikerült',
      'Coach created': 'Edző létrehozva',
      'Failed to create coach': 'Az edző létrehozása nem sikerült',
      Club: 'Klub',
      Save: 'Mentés',
      'Select club': 'Válasszon klubot',
      Coach: 'Edző',
      'Select a coach': 'Válasszon edzőt',
      Parent: 'Szülő',
      'Enter email': 'Írja be az e-mailt',
      Biography: 'Életrajz',
      'Write a bio': 'Írjon életrajzot',
      CHECKBOXES: 'JELÖLŐNÉGYZETEK',
      'Give access to coach to purchase licences and manage entries on your behalf': 'Adjon hozzáférést az edzőnek a licencek vásárlásához és nevezések kezeléséhez az Ön nevében',
      'Give access to club manager(-es) to purchase licences and manage entries on your behalf':
        'Adjon hozzáférést a klubmenedzser(ek)nek a licencek vásárlásához és nevezések kezeléséhez az Ön nevében',
      'Give access to parent(-s) to purchase licences and manage entries on your behalf': 'Adjon hozzáférést a szülő(k)nek a licencek vásárlásához és nevezések kezeléséhez az Ön nevében',
      Socials: 'Közösségi oldalak',
      'Insert link here': 'Illesszen be linket ide',
      Facebook: 'Facebook',
      Instagram: 'Instagram',
      Linkedin: 'LinkedIn',
      Tiktok: 'TikTok',
      Youtube: 'YouTube',
      'Enter Id': 'Írja be az azonosítót',
      'Password should be 8 chars minimum.': 'A jelszónak legalább 8 karakter hosszúnak kell lennie.',
      'Passwords must match': 'A jelszavaknak egyezniük kell',
      'Password changed successfully': 'Jelszó sikeresen megváltoztatva',
      'New Password': 'Új jelszó',
      'Re-Enter': 'Jelszó megerősítése',
      'Name is Required': 'Név szükséges',
      'Surname is Required': 'Vezetéknév szükséges',
      'Re enter password': 'Írja be újra a jelszót',
      'User is already registered': 'A felhasználó már regisztrálva van',
      'Create an account': 'Fiók létrehozása',
      'Already have an account?': 'Van már fiókja?',
      'Log in': 'Bejelentkezés',
      'or create account': 'vagy hozzon létre egy fiókot',
      "I'm creating an account for a kid": 'Egy gyermeknek hozok létre fiókot',
      'sign-up description':
        'Versenyrésztvevő számára hoz létre fiókot. Ha egy gyermeket regisztrál, kérjük, hozzon létre neki egy profilt. Hamarosan elérhetők lesznek edző és szülő profilok is, így kapcsolódhat a gyermek profiljához.',
      'Email Address': 'E-mail cím',
      Continue: 'Folytatás',
      'Pick a sport': 'Válasszon egy sportot',
      'Pick a sport that you play': 'Válassza ki azt a sportot, amelyet játszik',
      'Gender is required': 'Nem szükséges',
      'Phone is required': 'Telefon szükséges',
      'Citizenship is required': 'Állampolgárság szükséges',
      'Dob is required': 'Születési dátum szükséges',
      'FIDE ID Not found': 'FIDE azonosító nem található',
      'FIDE ID found': 'FIDE azonosító megtalálva',
      'Tell us more about you': 'Meséljen többet magáról',
      'More information about yourself': 'További információ magáról',
      'Enter FIDE ID': 'Írja be a FIDE azonosítót',
      'IPIN ID is correct': 'Az IPIN azonosító helyes',
      'Your Licenses': 'Az Ön licencei',
      'Attach your licenses': 'Csatolja a licenceit',
      Skip: 'Kihagyás',
      'User registered successfully': 'Felhasználó sikeresen regisztrálva',
      'What is your primary role in the world of sports?': 'Mi az elsődleges szerepe a sport világában?',
      'Register & Go to profile': 'Regisztráljon és lépjen a profilra',
      'PUBLISHED ON': 'MEGJELENT',
      'Federation member': 'Szövetségi tag',
      'Club owner': 'Klub tulajdonos',
      'Club manager': 'Klubmenedzser',
      Referee: 'Játékvezető',
      'Tournament organizer': 'Versenyszervező',
      'Sport fan': 'Sport rajongó',
      Other: 'Egyéb',
      PAY: 'FIZETÉS',
      '1 Year': '1 év',
      '2 Years': '2 év',
      '3 Years': '3 év',
      '4 Years': '4 év',
      'Registration time': 'Regisztráció ideje',
      'In progress / Upcoming': 'Folyamatban / Közelgő',
      'Matches won': 'Megnyert mérkőzések',
      'In progress / upcoming entries': 'Folyamatban / közelgő nevezések',
      'In progress / upcoming tournaments': 'Folyamatban / közelgő versenyek',
      'Past tournaments': 'Múltbeli versenyek',
      'Past entries': 'Múltbeli nevezések',
      'Your password must be different to previous passwords': 'Az új jelszónak különböznie kell az előzőektől',
      'less than a minute': 'kevesebb mint egy perc',
      read: 'olvasás',
      minute: 'perc',
      'Select Licence': 'Válasszon licencet',
      'Start time': 'Kezdési idő',
      'Try again': 'Próbálja újra',
      maintenanceMessage: 'Jelenleg frissítjük a platformot, ami néhány percet igénybe vehet. Kérjük, próbálja meg később.',
      networkErrorMessage: 'Kérjük, ellenőrizze a hálózati kapcsolatot, és próbálja meg újra.',
      errorTitle: 'Hoppá! Valami nincs rendben!',
      'View List': 'Lista megtekintése',
      Free: 'Ingyenes',
      'Primary Sport': 'Elsődleges sport',
      'Role(s)': 'Szerep(ek)',
      Menu: 'Menü',
      Calendar: 'Naptár',
      'Manage tournament': 'Verseny kezelése',
      'Partner search': 'Partner keresése',
      'Partner Search': 'Partner keresése',
      'Searching...': 'Keresés...',
      Apply: 'Alkalmaz',
      Delete: 'Törlés',
      'Create application': 'Alkalmazás létrehozása',
      'No partner search applications found': 'Nem található partner keresési alkalmazás',
      'Log in to apply': 'Jelentkezzen be a jelentkezéshez',
      Player: 'Játékos',
      'Time of registration': 'Regisztráció ideje',
      confirmed: 'megerősítve',
      declined: 'elutasítva',
      'payment pending': 'fizetés függőben',
      WITHDRAW: 'Visszalépés',
      'No other tournament found': 'Nem található más verseny',
      welcomeTextCustom:
        'Tournated által működtetve, egy mindent-egyben sportkezelő szoftver, amely lehetővé teszi a sport szervezetek számára, hogy hatékonyan kezeljék, növekedjenek és bevételre tegyenek szert. A megoldás lehetővé teszi a szervezetek számára, hogy egyedi online platformokat hozzanak létre, automatizálják és egyszerűsítsék a ligák, klubok, versenyek és egyéb események kezelését, növeljék a közönség részvételét, és könnyedén feldolgozzák a fizetéseket.',
      welcomeText:
        'öt éves kutatás és fejlesztés, valamint több mint 200 szervezettel való együttműködés eredménye, egy innovatív, testreszabható, felhasználóbarát és megfizethető, mindent-egyben megoldás bármilyen méretű sport szervezetek számára.',
      'Did you have an account in one of ': 'Van fiókja az egyik ',
      those: 'azok közül a',
      'platforms?': 'platformok közül?',
      'Recover password': 'Jelszó visszaállítása',
      newUser: 'Új felhasználó vagyok. Regisztráció',
      'Continue as a guest': 'Folytatás vendégként',
      Support: 'Támogatás',
      'Welcome to': 'Üdvözöljük a',
      'If you have an account on one of ': 'Ha van fiókja az egyik ',
      these: 'ezek közül a',
      'platforms, log in with your e-mail or create a new profile': 'platformok közül, jelentkezzen be e-maillel, vagy hozzon létre új profilt',
      'Reset password': 'Jelszó visszaállítása',
      'Welcome to the': 'Üdvözöljük a',
      platform: 'platformon',
      'Powered by': 'Működtetve általa:',
      'All Categories': 'Minden kategória',
      'Tournament Results': 'Verseny eredmények',
      versionText: 'A béta élő. Kérjük, küldje el visszajelzését',
      here: 'itt',
      'Full view': 'Teljes nézet',
      Timeline: 'Idővonal',
      Grid: 'Rács',
      "Bird's View": 'Madártávlat',
      'Third Party Licences': 'Harmadik fél licencek',
      Friend: 'Barát',
      'Step 2': '2. lépés:',
      'Step 3': '3. lépés:',
      'Your Details': 'Az Ön adatai',
      'Partner Details': 'Partner adatai',
      'Enter partner email': 'Írja be a partner e-mail címét',
      'Register & Pay': 'Regisztráljon és fizessen',
      'On-Site': 'Helyszínen',
      Now: 'Most',
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
