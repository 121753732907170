import { gql } from "@apollo/client";

const LOGIN = gql`
  mutation user($email: String!, $password: String!) {
    login(query: { email: $email, password: $password }) {
      email
      accessToken
      name
      surname
      role
      user_sports{
        id
        sport {
          id
          title
        }
      }
      gender
      dob
      id
      nation
      avatar
      phone
      street
      city
      facebook
      instagram
      website
      gtw_points
      preferred_language
      user_club_id{
        id
        clubName
      }
      coach_access
      club_access
      parent_access
      user_parents{
        id
        parent {
          id
          name
          email
        }
      }
      user_coach {
        id
        user {
          id
        }
        coaches {
          id
          user {
            id
            email
          }
        }
      }
      about
      createdAt
      user_address
      linkedIn
      youtube
      tiktok
      citizenship
      ipinId
      fideId
      ltsU10Id
      duprId
      pdlId
      teId
      atpId
      wtaId
      additionalUserRole
      plans {
        id
        plan
      }
      stripe_payments{
        productId
        status
      }
    }
  }
`;

export default LOGIN;
