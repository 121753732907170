import { gql } from "@apollo/client";
const ALLTOURNAMENTS = gql`
  query {
    allTournamentNames(filter: {}) {
      id
      title
      tour
      address
      startDate
      endDate
      entryDeadline
      createdAt
      country
      city
      coverPhoto
      closeRegistration
      paymentMethod
      league {
        id
        league {
          id
          title
          enableLicense
          sports {
            id
          }
          abbreviation
        }
      }
      tournamentCategory {
        id
        category {
          name
        }
        fee
      }
      organizer {
        organizationName
        organizationEmail
        isOrganization
        member {
          name
          surname
          id
        }
      }
    }
  }
`;
export default ALLTOURNAMENTS;
