import { gql } from "@apollo/client";
const ALLORGANIZATIONS = gql`
query {
    federations(
      page: 1
      limit: 100
      filter: { }
    ) {
      id
      user{
          email
      }
      leagues{
          league{
              title
              tournaments{
                  id
              }
          }
      }
    }
  }
  `;
export default ALLORGANIZATIONS;