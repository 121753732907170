import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Box, Divider, Typography, Checkbox, Stack, InputAdornment, IconButton, Alert, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_USER_BY_EMAIL } from 'src/graphql/queries';
import { LoginSocialIcons } from 'src/constants';
import { useRegisterContext } from 'src/contexts/RegisterContext';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import CustomFormInput from 'src/components/CustomComponents/CustomFormInput';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  passwordContainer: {
    position: 'relative',
  },
  passwordToggleBtn: {
    position: 'absolute',
    top: '29px',
    right: '18px',
    cursor: 'pointer',
    border: 'none',
    background: 'transparent',
  },
  submitBtn: {
    background: '#0A2540',
    borderRadius: '4px',
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  socialContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
  },
  loginActionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    width: '100%',
  },
}));

const messages = {
  la: 'Lai pārvaldītu sava bērna profilu, vispirms aizpildiet viņa profilu, pēc tam dodieties uz Iestatījumi -> Karjera un ievadiet savu e-pastu vecāku laukā. Tas ļaus jums pārvaldīt sava bērna profilu, neizrakstoties no sava konta.',
  en: 'To manage your child’s profile, first complete their profile, then navigate to Settings -> Career and enter your email in the parent field. This will enable you to manage their profile without needing to log out of your account.',
  esp: 'Para gestionar el perfil de su hijo, primero complete su perfil, luego vaya a Configuración -> Carrera e ingrese su correo electrónico en el campo de padres. Esto le permitirá gestionar el perfil de su hijo sin cerrar sesión en su cuenta.',
  ru: 'Чтобы управлять профилем вашего ребенка, сначала заполните его профиль, затем перейдите в Настройки -> Карьера и введите свой адрес электронной почты в поле для родителей. Это позволит вам управлять профилем вашего ребенка, не выходя из своей учетной записи.',
  ka: 'თქვენი შვილის პროფილის სამართავად, ჯერ შეავსეთ მისი პროფილი, შემდეგ გადადით პარამეტრებში -> კარიერა და შეიყვანეთ თქვენი ელ.ფოსტა მშობლის ველში. ეს მოგცემთ საშუალებას მართოთ თქვენი შვილის პროფილი თქვენი ანგარიშიდან გამოსვლის გარეშე.',
};

const BasicDetails: FC<any> = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isReVisible, setIsReVisible] = useState(false);
  const [selectedRole, setSelectedRole] = useState<any>(false);

  const { registerObject, updateRegisterObject } = useRegisterContext();
  const [isKidAccount, setIsKidAccount] = useState(registerObject?.isKid);
  const handleCheckboxChange = (event) => {
    setIsKidAccount(event.target.checked);

    updateRegisterObject({
      ...registerObject,
      ...{ isKid: event.target.checked },
    });
  };
  const [userError, setUserError] = useState<any>('');
  const [searchUser, { data: userData, loading: userLoading, error: emailError }] = useLazyQuery(GET_USER_BY_EMAIL);
  function togglePasswordVisibility() {
    setIsPasswordVisible((prevState) => !prevState);
  }
  function toggleReVisibility() {
    setIsReVisible((prevState) => !prevState);
  }
  const removeSchema = (data, role) => {
    if (role !== true) {
      delete data.nation;
      delete data.gender;
      delete data.phone;
      delete data.dob;
    }
    return data;
  };
  const yupObject = {
    email: yup
      .string()
      .required(t('Email is Required'))
      .email()
      .test('email-error', 'User is already registered', function (value) {
        return userError == '';
      }),
    name: yup.string().required(t('Name is Required')),
    password: yup.string().required(t('Password is Required')).min(8, t('Password should be 8 chars minimum.')),
    surname: yup.string().required(t('Surname is Required')),
    reEnter: yup
      .string()
      .required(t('Re enter password'))
      .oneOf([yup.ref('password')], t('Passwords must match')),
  };

  const validation = removeSchema(yupObject, selectedRole);
  const debouncedFilter = useCallback(
    debounce(async (email) => {
      try {
        const res = await searchUser({
          variables: {
            query: {
              email: email,
            },
          },
        });
        if (res?.data) {
          setUserError(t('User is already registered'));
        } else {
          setUserError('');
        }
      } catch (error) {
        setUserError('');
      }
    }, 500),
    []
  );
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handlePlayer = (e) => {
    if (isValidEmail(e.target.value)) {
      debouncedFilter(e.target.value);
    }
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', width: '100%' }}>
      <Box
        className={classes.loginContainer}
        sx={{
          overflow: 'scroll',
          height: '100%',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Box className={classes.socialContainer}>
          <Box
            sx={{
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
              gap: '14px',
            }}
          >
            <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              <Typography
                variant="font21"
                sx={{
                  lineHeight: '15.6px',
                  color: 'rgba(10, 37, 64, 0.35)',
                }}
              >
                {t('Already have an account?')}
              </Typography>
              <Typography
                variant="font18"
                sx={{
                  textDecoration: 'underline',
                  lineHeight: '15.6px',
                  color: '#0A2540',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  props.setModal('login');
                }}
              >
                {t('Log in')}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '8px', opacity: 0.6 }}>{LoginSocialIcons?.map((item) => <img src={item?.path} style={{ width: '52px', height: '52px' }} />)}</Box>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Divider>
              <Typography
                variant="font21"
                sx={{
                  color: 'rgba(10, 37, 64, 0.35)',
                  lineHeight: '15.6px',
                }}
              >
                {t('or create account')}
              </Typography>
            </Divider>
          </Box>
        </Box>
        <Stack>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox id="asParent" size="small" name="isKid" checked={isKidAccount} onChange={handleCheckboxChange} />
            <label htmlFor="asParent">
              <Typography variant="font21" sx={{ color: '#0A2540', cursor: 'pointer' }}>
                {t("I'm creating an account for a kid")}
              </Typography>
            </label>
          </Box>
          <Collapse in={isKidAccount}>
            <Alert severity="info" sx={{ mb: '4px' }}>
              {messages[i18n.language]}
            </Alert>
          </Collapse>
        </Stack>
        <Box className={classes.loginActionContainer}>
          <Formik
            initialValues={{
              email: registerObject?.email,
              name: registerObject?.name,
              surname: registerObject?.surname,
              password: registerObject?.password,
              reEnter: registerObject?.password,
              isKid: registerObject?.isKid,
              submit: null,
            }}
            validationSchema={yup.object().shape(validation)}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }): Promise<void> => {
              const variables = {
                email: values.email,
                name: values.name,
                password: values.password,
                surname: values.surname,
              };
              try {
                updateRegisterObject({ ...registerObject, ...variables });
                props.handleClick(1);
              } catch (error) {}
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, setFieldTouched, isSubmitting, touched, values }): JSX.Element => (
              <form noValidate onSubmit={handleSubmit} {...props}>
                <Stack gap="10px">
                  <CustomFormInput
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    name="name"
                    label={t('Name')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                  />
                  <CustomFormInput
                    error={Boolean(touched.surname && errors.surname)}
                    helperText={touched.surname && errors.surname}
                    label={t('Surname')}
                    name="surname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.surname}
                  />
                  <CustomFormInput
                    error={Boolean((touched.email && errors.email) || userError !== '')}
                    helperText={(touched.email && errors.email) || userError !== ''}
                    label={t('Email Address')}
                    name="email"
                    onBlur={handleBlur}
                    onChange={(event) => {
                      handleChange(event);
                      handlePlayer(event);
                    }}
                    type="email"
                    value={values.email}
                  />
                  <CustomFormInput
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    label={t('Password')}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={isPasswordVisible ? 'text' : 'password'}
                    value={values.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton sx={{ marginRight: 0, zIndex: 1 }} aria-label="toggle password visibility" onClick={togglePasswordVisibility} edge="end">
                            {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomFormInput
                    error={Boolean(touched.reEnter && errors.reEnter)}
                    helperText={touched.reEnter && errors.reEnter}
                    label={t('Re-Enter')}
                    name="reEnter"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={isReVisible ? 'text' : 'password'}
                    value={values.reEnter}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton sx={{ marginRight: 0, zIndex: 1 }} aria-label="toggle password visibility" onClick={toggleReVisibility} edge="end">
                            {isReVisible ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomButton variant="primary" size="medium" disabled={isSubmitting} type="submit" className={classes.submitBtn} sx={{ mt: '8px' }}>
                    {t('Continue')}
                  </CustomButton>
                </Stack>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};

export default BasicDetails;
