import { gql } from "@apollo/client";
const GROUPMATCHES = gql`
query($filter: ListGroupMatchInput) {
    groupMatches(
      filter: $filter
    ) {
      id
      date
      courts{
         name
    }
      entry1{
          id
           registrationRequest{
              users{
                  user{
                      email
                  }
              }
          }
      }
      entry2{
          id
          registrationRequest{
              users{
                  user{
                      email
                  }
              }
          }
      }
    }
  }
  `;

export default GROUPMATCHES;