import React, { ReactElement, ReactNode } from 'react';
import { Box, Fade, Typography } from '@material-ui/core';
import { SxProps } from '@material-ui/system';

type Props = {
  children: ReactNode;
  title: string;
  titleRightSideElement?: ReactElement;
  underTitleElement?: ReactElement;
  gap?: number;
  sx?: SxProps;
};
const CustomSection = ({ children, title, titleRightSideElement, underTitleElement, sx, gap = 15 }: Props) => (
  <Fade in={true} timeout={700}>
    <Box display="flex" flexDirection="column" gap={`${gap}px`} sx={{ mt: '15px', ...sx }} width="100%">
      <Box display="flex" height="40px" alignItems="center" justifyContent="space-between" gap="32px">
        <Typography color="#0A2540" variant="heading17">
          {title}
        </Typography>
        {titleRightSideElement && <Box flex="1">{titleRightSideElement}</Box>}
      </Box>
      {underTitleElement}
      {children}
    </Box>
  </Fade>
);

export default CustomSection;
