import { gql } from '@apollo/client';

const TOURNAMENT_DOCUMENTS = gql`
  query ($filter: ListTournamentDocumentInput) {
    tournamentDocuments(filter: $filter) {
      id
      name
      document
      section
    }
  }
`;

export default TOURNAMENT_DOCUMENTS;
