import { gql } from "@apollo/client";

const RESET = gql`
  mutation forgetPassword($email: String, $password: String) {
    updateUserPassword(
      updateUserpassword: { email: $email, password: $password }
    ) {
      id
      name
      password
      email
    }
  }
`;

export default RESET;
