import { gql } from "@apollo/client";
const GROUPSDATA = gql`
query getGroups($filter:ListGroupInput){
  allGroups(filter: $filter) {
      id
      name
      tournament {
        id
        title
      }
      matches {
        isDelete
        score {
          round
          score
          roundFormat
          entry {
            id
          }
        }
        id
        court {
          name
          id
        }
        date
        time
        round
        winner {
          id
        }
        status
        matchStatus
        entry1 {
          id
          registrationRequest {
            users {
              user {
                id
                email
                name
                surname
                nation
              }
            }
          }
        }
        entry2 {
          id
          registrationRequest {
            users {
              user {
                id
                email
                name
                surname
                nation
              }
            }
          }
        }
      }
      displayPlayOrder
      displayMatches
      participantsAddScore
      hide
      comment
      isDelete
      groupType
      entries {
        id
        registrationRequest {
          id
          users {
            id
            user {
              id
              email
              name
              surname
              nation
            }
          }
        }
      }
    }
  }
  `;
export default GROUPSDATA;