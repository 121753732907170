import { gql } from "@apollo/client";

const LANDING_DATA = gql`
  query {
    alltournament(page: 1, limit: 10, filter: {}) {
      title
      tour
      organizer
      address
      startDate
      endDate
      id
      country
      city
    }
    leagues(page: 1, limit: 10, filter: {}) {
        title
        type
        status
        sports{
          id
        }
        email
        website
      }
  }
`;

export default LANDING_DATA;
