import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, useMediaQuery } from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import NavSection from '../NavSection';
import {
  ArticleOutlined,
  ChevronLeft,
  ChevronRight,
  CorporateFareOutlined,
  EmojiEventsOutlined,
  EqualizerOutlined,
  GroupsOutlined,
  HomeOutlined,
  SportsOutlined,
  StarOutlined,
} from '@material-ui/icons';
import { getPlatformId } from 'src/constants';
import { NAVBAR_HEIGHT } from './DashboardNavbar';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import CustomPaperCard from '../CustomComponents/CustomPaperCard';
import useLocalStorageState from '../../utils/useLocalStorageState';
import { Theme } from '@material-ui/core/styles';
export const sections = [
  {
    title: 'home',
    path: '/',
    icon: <HomeOutlined sx={{ fontSize: '20px' }} />,
  },
  {
    title: 'organizations',
    path: '/organizations',
    icon: <CorporateFareOutlined sx={{ fontSize: '20px' }} />,
  },
  {
    title: 'leagues',
    path: '/leagues',
    icon: <EqualizerOutlined sx={{ fontSize: '20px' }} />,
  },
  {
    title: 'Tournaments',
    path: '/tournaments',
    icon: <EmojiEventsOutlined sx={{ fontSize: '20px' }} />,
  },
  {
    title: 'Rankings',
    path: '/rankings',
    icon: <StarOutlined sx={{ fontSize: '20px' }} />,
  },
  // {
  //   title: "Athletes",
  //   path: "/athletes",
  //   icon: "/images/athletes.svg",
  // },
  // {
  //   title: t("teams"),
  //   path: "/teams",
  //   icon: "/images/updated/Icon/teams.svg",
  // },
  {
    title: 'clubs',
    path: '/clubs',
    icon: <GroupsOutlined sx={{ fontSize: '20px' }} />,
  },
  {
    title: 'coaches',
    path: '/coaches',
    icon: <SportsOutlined sx={{ fontSize: '20px' }} />,
  },

  {
    title: 'news',
    path: '/news',
    icon: <ArticleOutlined sx={{ fontSize: '20px' }} />,
    // subItems:[
    //   {
    //     title: "News",
    //     path: "/news",
    //     icon: "/images/updated/Icon/News.svg",
    //   },
    // ]
  },
  // {
  //   title: "User Settings",
  //   path: "/userSettings",
  //   icon: "/images/updated/profilesetting.svg",
  // },
];

export const filteredSections = (platform: number) => {
  const platformId = platform.toString();

  if (platformId == '1' || platformId == '3') {
    return sections;
  } else {
    return sections?.filter((item) => item?.path != '/organizations');
  }
};
const DashboardSidebar = () => {
  const auth = useAuth();
  const platformId = getPlatformId();

  const location = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useLocalStorageState({ key: 'sidebarOpen', defaultValue: true });
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const handleOpenSideBar = () => {
    setOpen((curr) => !curr);
  };

  return (
    <CustomPaperCard
      sx={{
        backgroundColor: '#fff',
        zIndex: 9,
        overflowY: 'initial',
        borderRight: '1px solid #EFEFF0',
        position: 'inherit',
        minHeight: '100%',
        overflow: 'visible',
      }}
    >
      <CustomPaperCard
        sx={{
          position: 'relative',
          background: '#ffff',
          height: { xs: NAVBAR_HEIGHT.sm + 'px', md: NAVBAR_HEIGHT.lg + 'px' },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          overflow: 'visible',
          padding: 0,
          paddingInline: '10px',
          borderRight: 'none',
          borderLeft: 'none',
          borderTop: 'none',
        }}
      >
        <CustomButton
          variant="outline"
          size="medium"
          shape="circle"
          sx={{
            position: 'absolute',
            right: '0',
            bottom: '1px',
            transform: 'translate(50%, 50%)',
            maxWidth: '28px',
            maxHeight: '28px',
            minWidth: '28px',
            minHeight: '28px',
            borderRadius: '8px',
            zIndex: 1,
          }}
          onClick={handleOpenSideBar}
        >
          {!open ? <ChevronRight fontSize="small" /> : <ChevronLeft fontSize="small" />}
        </CustomButton>
        <CustomButton size="auto" variant="text" onClick={() => navigate('/')}>
          <img
            alt="platform logo"
            src={Number(platformId) === 1 ? (open ? '/images/tournatedLogoBig.svg' : '/images/tournatedLogoSmall.svg') : (auth?.platformData?.lightLogo ?? process.env.REACT_APP_LOGO_ICON)}
            width={open ? '180px' : '40px'}
            style={{ objectFit: 'contain', objectPosition: 'left', minHeight: isDesktop ? '25px' : '20px', maxHeight: isDesktop ? '25px' : '20px' }}
          />
        </CustomButton>
      </CustomPaperCard>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          px: '10px',
          gap: '10px',
          pt: '12px',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {filteredSections(Number(platformId))?.map((section) => <NavSection key={section.title} openSideBarState={open} pathname={location.pathname} {...section} />)}
      </Box>
    </CustomPaperCard>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
