import { gql } from "@apollo/client";

const CLUB_BY_ID = gql`
  query club($id: Int!) {
    club(id: $id) {
      country
      id
      clubName
      logo
      country
      city
      address
      contactEmail
      howToFindUs
      contactPhone
      instagramLink
      facebookLink
      twitterLink
      tikTok
      linkedin
      website
      teams {
        id
        title 
        country
        members {
          id
          member {
            id
            name
            surname
            email
            role
            nation
            avatar
          }
        }
        sport{
          id 
          title
          icon
        }
      }
      members {
        id
        user {
          id
          name
          surname
          email
          role
          nation
          avatar
        }
      }
      federation {
        id
      }
      platform {
        id
      }
    }
  }
`;

export default CLUB_BY_ID;
